import { all, takeEvery, takeLatest, call } from "redux-saga/effects";
import API from "./../services/Api";

import {
  createElectionSaga,
  editElectionSaga,
  deleteElectionSaga,
  listElectionSaga,
  createAMASaga,
  Top2ProjectElectionsSaga,
  AllPlatformUserSaga,
  AllPlatformProjectSaga,
  ChangeUserStatusByAdminSaga,
  ChangeProjectStatusByAdminSaga,
  GetAdminReportSaga,
  DeleteReportByAdminSaga,
  UpdatemanageBannerByAdminSaga,
  GetbannerSaga,
  AdminMinBalanceSetSaga,
  SpotlightUserSaga,
  SingleElectionDetailSaga,
} from "./admin_saga";
import {
  usersignIn,
  userCreation,
  EditProfileInputSaga,
  EditProfileImageSaga,
  MyTopFundedProjectSaga,
  MyParticipatedElections,
  ReportAProjectOrUser,
  GlobalSearchSaga,
  SingleUserDetailSaga,

  ///onboarding phase 2
  userSignUpSaga,
  requestPasswordResetSaga,
  userLoginSaga,
  ResendVerificationMail,
  VerifyEmailSaga,
  SetNewPasswordSaga,
  googleAccessTokenSaga,
  LoginGoogleAccessTokenSaga,
  ClearUserDetailsSaga,
} from "./user_saga";

import { getCategories, getGenre } from "./category_saga";

import {
  logOut,
  CheckMinimalBalanceOfUser,
  GetUserWalletBalanceSaga,
  GetExchangeRateCreateToBusdSaga,
  GetCreatorProjectCountForHomePageSaga,
} from "./app_saga";
import {
  GetPublicCompetetionSaga,
  GetPublicCompetetionSagaPlus1,
  GetPublicCompetetionSagaForAma,
  ElectionGifSaga,
} from "./competetion_saga";
import {
  createProjectSaga,
  listAllProjectsSaga,
  EditProjectSaga,
  DeleteProjectSaga,
  EditProjectDocumentSaga,
  EditBannerProjectSaga,
  GetLiveProject,
  PublishProjectSaga,
  JoinElectionSaga,
  VoteProjectSaga,
  Top10ProjectsSags,
  DonateProjectSaga,
  PublishToDraftSaga,
  SearchPageProjectsSagaCalled,
  SingleProjectDetailPageSaga,
  ProjectSpotlightSaga,
  Top50ProjectsSaga,
  GetDonationReceivedSaga,
  GetTotalDonationSaga,
  SearchResultSaga,
} from "./project_saga";

import {
  CreatorDetailsSaga,
  VoteCheckerSaga,
  VoteCreatorSaga,
  Top50CreatorSaga,
} from "./creator_saga";
import {
  PowerVotesOwnedByUserSaga,
  ShowAllPlatformVotes,
  ConversionFromUTESaga,
  BuyPowerVoteUsingEth,
  VoteUsingPowerVoteSaga,
  BuyPowerVoteUsingTokenSaga,
  DonateProjectUsingEth,
  DonateProjectUsingToken,
  DonationTableSaga,
  DonationTableUserSaga,
  DonationTablePowerVoteSaga,
  ConversionFromETUSaga,
  ConversionFromBTUSaga,
  DonationOfUserLoggedInSaga,
  PowerVoteBTCSagaCalled,
  RewardSaga,
  VotingHistoryUserSaga,
  DonateBitcoinSaga,
  StripeCheckoutSaga,
} from "./powerVote_saga";
import {
  GetAllNotificationSaga,
  GetAllNotificationCountSaga,
  SingleNotificationReadSaga,
  AllNotificationReadSaga,
  requestNewsLetterSaga,
} from "./notification_saga";

const api = API.create();

export default function* root() {
  yield all([
    takeLatest("signInSagaCalled", usersignIn, api),
    takeLatest("creatingNewUserSagaCalled", userCreation, api),
    takeLatest("getCategoriesSagaCalled", getCategories, api),
    takeLatest("getGenreSagaCalled", getGenre, api),
    takeLatest("createElectionSagaCalled", createElectionSaga, api),
    takeLatest("editElectionSagaCalled", editElectionSaga, api),
    takeLatest("deleteElectionSagaCalled", deleteElectionSaga, api),
    takeLatest("listElectionSagaCalled", listElectionSaga, api),
    takeLatest("listAllProjectsSagaCalled", listAllProjectsSaga, api),
    takeLatest("createProjectSagaCalled", createProjectSaga, api),
    takeLatest("editProjectSagaCalled", EditProjectSaga, api),
    takeLatest("deleteProjectSagaCalled", DeleteProjectSaga, api),
    takeLatest("editDocumentProjectSagaCalled", EditProjectDocumentSaga, api),
    takeLatest("editBannerProjectSagaCalled", EditBannerProjectSaga, api),
    takeLatest("getAllLiveProjectSagaCalled", GetLiveProject, api),
    takeLatest("publishProjectSagaCalled", PublishProjectSaga, api),
    takeLatest("joinElectionSagaCalled", JoinElectionSaga, api),
    takeLatest("voteProjectSagaCalled", VoteProjectSaga, api),
    takeLatest("createAMASagaCalled", createAMASaga, api),
    takeLatest("editProfileInputSagaCalled", EditProfileInputSaga, api),
    takeLatest("editProfileImageSagaCalled", EditProfileImageSaga, api),
    takeLatest("getPublicCompetetionSagaCalled", GetPublicCompetetionSaga, api),
    takeLatest(
      "getPublicCompettionPlus1SagaCalled",
      GetPublicCompetetionSagaPlus1,
      api
    ),
    takeLatest("getElectionsGifSagaCalled", ElectionGifSaga, api),
    takeLatest("getTop10ProjectSagaCalled", Top10ProjectsSags, api),
    takeLatest("donateProjectSagaCalled", DonateProjectSaga, api),
    takeLatest("getMyTopFundedProject", MyTopFundedProjectSaga, api),
    takeLatest("publishToDraftProjectSagaCalled", PublishToDraftSaga, api),
    takeLatest("top2projectsElectionSagaCalled", Top2ProjectElectionsSaga, api),
    takeLatest(
      "searchPageProjectsSagaCalled",
      SearchPageProjectsSagaCalled,
      api
    ),
    takeLatest("getAdminProjects", AllPlatformProjectSaga, api),
    takeLatest("getAdminUsers", AllPlatformUserSaga, api),
    takeLatest("myParticipatedElections", MyParticipatedElections, api),
    takeLatest(
      "chaneUserStatusByAdminSagaCalled",
      ChangeUserStatusByAdminSaga,
      api
    ),
    takeLatest(
      "changeProjectStatusByAdminSagaCalled",
      ChangeProjectStatusByAdminSaga,
      api
    ),
    takeLatest("getReportTableForAdminSagaCalled", GetAdminReportSaga, api),
    takeLatest("deleteReportByAdminSagaCalled", DeleteReportByAdminSaga, api),
    takeLatest("reportingAProjectOrUserSagaCalled", ReportAProjectOrUser, api),
    takeLatest(
      "updateMangebannerByAdminSagaCalled",
      UpdatemanageBannerByAdminSaga,
      api
    ),
    takeLatest("getbannerSagaCalled", GetbannerSaga, api),
    takeLatest("getGlobalSearchSagaCalled", GlobalSearchSaga, api),
    takeLatest("getAllCreatorDetailsSagaCalled", CreatorDetailsSaga, api),
    takeLatest("adminBalanceSetSagaCalled", AdminMinBalanceSetSaga, api),
    takeLatest("singleUserDetailFetchSagaCalled", SingleUserDetailSaga, api),
    takeLatest(
      "singleProjectDetailSagaCalled",
      SingleProjectDetailPageSaga,
      api
    ),
    takeLatest("projectToSpotligtChangeSagaCalled", ProjectSpotlightSaga, api),
    takeLatest("userSpotlightSagaCalled", SpotlightUserSaga, api),
    takeLatest("singleElectionDetailSagaCalled", SingleElectionDetailSaga, api),
    takeLatest(
      "getAmaElectionsForCompPage",
      GetPublicCompetetionSagaForAma,
      api
    ),
    takeLatest(
      "checkMinimalBalanceOfUserSagaCalled",
      CheckMinimalBalanceOfUser,
      api
    ),
    takeLatest("getUserWalletBalanceSagaCalled", GetUserWalletBalanceSaga, api),
    takeLatest(
      "getExchangeRateCreateToBusdSagaCalled",
      GetExchangeRateCreateToBusdSaga,
      api
    ),
    takeLatest(
      "getCreatorProjectCountForHomePageSagaCalled",
      GetCreatorProjectCountForHomePageSaga,
      api
    ),
    takeLatest(
      "allPowerVotesOwnedByUserSagaCalled",
      PowerVotesOwnedByUserSaga,
      api
    ),
    takeLatest(
      "showAllPlatformPowerVotesSagaCalled",
      ShowAllPlatformVotes,
      api
    ),
    takeLatest("buyPowerVotesUsingEthSagaCalled", BuyPowerVoteUsingEth, api),
    takeLatest("conversionFromUTESagaCalled", ConversionFromUTESaga, api),
    takeLatest("voteUsingPowerVoteSagaCalled", VoteUsingPowerVoteSaga, api),
    takeLatest(
      "buyPowerVoteUsingTokenSagaCalled",
      BuyPowerVoteUsingTokenSaga,
      api
    ),
    takeLatest("donateProjectUsingEthSagaCalled", DonateProjectUsingEth, api),
    takeLatest(
      "donateProjectUsingTokenSagaCalled",
      DonateProjectUsingToken,
      api
    ),
    takeLatest("donationTableSagaCalled", DonationTableSaga, api),
    takeLatest("donationUserTableSagaCalled", DonationTableUserSaga, api),
    takeLatest(
      "donationTablePowerVoteSagaCalled",
      DonationTablePowerVoteSaga,
      api
    ),

    //onbarding phase 2 ///////////////////////////
    takeLatest("onboardingSignUpCalled", userSignUpSaga, api),
    takeLatest("forgotPasswordSagaCalled", requestPasswordResetSaga, api),
    takeLatest("signInUsingEmailSagaCalled", userLoginSaga, api),
    takeLatest("resendVerificationEmail", ResendVerificationMail, api),
    takeLatest("verifyEmailSagaCalled", VerifyEmailSaga, api),
    takeLatest("setNewPasswordSagaCalled", SetNewPasswordSaga, api),
    takeLatest("googleSignUpSagaCalled", googleAccessTokenSaga, api),
    takeLatest("googleLoginSagaCalled", LoginGoogleAccessTokenSaga, api),
    takeLatest("top50ProjectsSagaCalled", Top50ProjectsSaga, api),
    takeLatest("getUSDFromEthSagaCalled", ConversionFromETUSaga, api),
    takeLatest("getUSDFromBNBSagaCalled", ConversionFromBTUSaga, api),
    takeLatest(
      "getTotalDonationReceivedSagaCalled",
      GetDonationReceivedSaga,
      api
    ),
    takeLatest("getTotalDonationSagaCalled", GetTotalDonationSaga, api),
    takeLatest("getDonationWhenUserLoaggedIn", DonationOfUserLoggedInSaga, api),
    takeLatest("getAllNotificationSagaCalled", GetAllNotificationSaga, api),
    takeLatest(
      "getNotificationCountSagaCalled",
      GetAllNotificationCountSaga,
      api
    ),
    takeLatest(
      "singleNotficationReadSagaCalled",
      SingleNotificationReadSaga,
      api
    ),
    takeLatest("allNotificationReadSagaCalled", AllNotificationReadSaga, api),
    takeLatest("logoutSagaCalled", logOut, api),
    takeLatest("clearUserDetailsSaga", ClearUserDetailsSaga, api),
    takeLatest("powerVoteBTCSagaCalled", PowerVoteBTCSagaCalled, api),
    // takeLatest("searchResultCalled", SearchResultSaga, api),
    takeLatest("searchPageSagaCalled", SearchResultSaga, api),
    takeLatest("requestNewsLetterSaga", requestNewsLetterSaga, api),
    takeLatest("rewardSagaCalled", RewardSaga, api),
    takeLatest("votingHistorySagaCalled", VotingHistoryUserSaga, api),
    takeLatest("donateBitcoinSagaCalled", DonateBitcoinSaga, api),
    takeLatest("stripeCheckoutSagaCalled", StripeCheckoutSaga, api),
    takeLatest("getTop50CreatorSagaCalled", Top50CreatorSaga, api),
    takeLatest("votescreatorSagaCalled", VoteCreatorSaga, api),
    takeLatest("checkvoteSagaCalled", VoteCheckerSaga, api),
  ]);
}
