import React, { useEffect } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { navigateToForm } from "src/store/user_redux";
import { useNavigate } from "react-router-dom";
import { disconnect } from "src/WalletProvider/Web3";
import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as CloseIcon } from "@images/close-icon.svg";
import { connect } from "react-redux";
import { clearCheckWalletMinimalBalBackendError } from "@store/app_redux";
import "@components/template-popup/TemplatePopupStyle.scss";
const TemplatePopup = ({
  show,
  setShowModal,
  message,
  status,
  callFromProject,
  minBalCheck,
  clearBackendErrorOfMinBalRule,
  bitcoin,
  bitcoinDonate,
  userDetails,
}) => {
  const navigate = useNavigate();

  return (
    <Modal
      className="TemplatePopup"
      show={show}
      onHide={() => {
        setShowModal(false);
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon
          className="closebtn"
          onClick={() => {
            callFromProject && navigate("/myprojects");
            setShowModal(false);
          }}
        />
        <Container>
          <Row>
            {status !== undefined && status === "success" ? (
              <Col lg={12}>
                <div className="heading-text">Success</div>
              </Col>
            ) : status !== undefined && status !== " success" ? (
              <Col lg={12}>
                <div className="heading-text">Oh snap!</div>
              </Col>
            ) : null}
          </Row>
          <Row>
            <Col lg={12}>
              <div className="message-text">{message}</div>
            </Col>
            <Col lg={12} sm={12} xs={12}>
              {message !== "Connect your wallet to continue" &&
              bitcoin !== true ? (
                <div className="btn-div">
                  <CustomButton
                    filled
                    name="Okay"
                    type="button"
                    primary
                    classname={"submit-btn"}
                    onClick={() => {
                      // disconnect()
                      // navigate(-1)
                      if (minBalCheck !== undefined && minBalCheck === true) {
                        clearBackendErrorOfMinBalRule();
                      }

                      setShowModal(false);
                    }}
                  />
                </div>
              ) : bitcoin === true && bitcoinDonate === true ? (
                <div className="btn-div">
                  <CustomButton
                    filled
                    name={"My Donations"}
                    type="button"
                    primary
                    classname={"submit-btn2"}
                    onClick={() => {
                      // disconnect()
                      // navigate(-1)
                      if (minBalCheck !== undefined && minBalCheck === true) {
                        clearBackendErrorOfMinBalRule();
                      }
                      // if (bitcoinDonate === true) {
                      navigate("/mydonations");
                      // } else {
                      //   navigate("/mypowervote");
                      // }

                      setShowModal(false);
                    }}
                  />
                </div>
              ) : bitcoin === true && userDetails !== undefined ? (
                <div className="btn-div">
                  <CustomButton
                    filled
                    name={"My PowerVotes"}
                    type="button"
                    primary
                    classname={"submit-btn2"}
                    onClick={() => {
                      // disconnect()
                      // navigate(-1)
                      if (minBalCheck !== undefined && minBalCheck === true) {
                        clearBackendErrorOfMinBalRule();
                      }
                      // if (bitcoinDonate === true) {
                      //   navigate("/mydonations");
                      // } else {
                      navigate("/mypowervote");
                      // }

                      setShowModal(false);
                    }}
                  />
                </div>
              ) : (
                userDetails !== undefined && (
                  <div className="btn-div">
                    <CustomButton
                      filled
                      name="Connect your wallet"
                      type="button"
                      primary
                      classname={"connect-btn"}
                      onClick={() => {
                        // disconnect()
                        // navigate(-1)
                        // if (minBalCheck !== undefined && minBalCheck === true) {
                        //   clearBackendErrorOfMinBalRule();
                        // }
                        navigate("/myprofile");
                        setShowModal(false);
                      }}
                    />
                  </div>
                )
              )}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
const mapStateToProps = ({ user = {} }) => {
  const userDetails = _.get(user, "userInfo", undefined);
  return {
    userDetails,
  };
};
const mapDispatchToProps = (dispatch) => ({
  clearBackendErrorOfMinBalRule: () =>
    dispatch(clearCheckWalletMinimalBalBackendError()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(TemplatePopup));
