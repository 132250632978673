import React, { useState, useEffect } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import CustomButton from "@components/custom-button/CustomButton";
import InputField from "@components/input-field/InputField";
import CurrencyDropdown from "@components/currency-dropdown/CurrencyDropdown";
import { ReactComponent as CloseIcon } from "@images/close-icon.svg";
import ReactReadMoreReadLess from "react-read-more-read-less";
import "@components/donate-popup/DonatePopupStyle.scss";
import { connect } from "react-redux";
import { web3 } from "src/WalletProvider/Web3";
import { useNavigate } from "react-router-dom";
import provider from "@utilities/web3Contract";
import { CONTRACT_ABI, BNB_CONTRACT } from "src/utilities/contractDetails";
import {
  clearReduxOnMount,
  clearDonationUsingEth,
  clearDonationUsingERC20,
} from "@store/powerVote_redux";
import Loader from "@components/loader/Loader";
import { clearConversionFromUTS , clearStripeCheckout } from "@store/powerVote_redux";
import { clearDonateBTCPopup } from "@store/powerVote_redux";

const DonatePopup = ({
  show,
  setShowModal,
  setShowSuccess,
  donateProjectUsingEthAchieved,
  donateProjectUsingEthAsync,
  usdValueEth,
  userDetails,
  data,
  setItem,
  clearErrorState,
  donateProjectUsingTokenAsync,
  donateProjectUsingEthError,
  donateProjectUsingTokenAchieved,
  donateProjectUsingTokenError,
  // transactionCancelPopup,
  setTrue,
  clearUSDToEth,
  openCheckMinBal,
  donateProjectEthLoading,
  donateProjectTokenLoading,
  convertedBTULoading,
  convertedBTU,
  convertedETU,
  convertedETULoading,
  conversionFromETUAsync,
  conversionFromBTUAsync,
  network,
  donateBTCLoading,
  donateBTCSuccess,
  donateBTCError,
  donateBTCAsync,
  bitOpen,
  clearBTCPowerVote,
  stripeCheckoutAsync,
  stripeCheckLoading,
  stripeCheckResult,
  clearStripeCheckOutBackend,
}) => {
  const [Amount, setAmount] = useState("");
  const [readMore, setReadMore] = useState(false);
  const [currencyUSD, setCurrencyUSD] = useState("");
  const [chooseCurrency, setChosenCurrency] = useState("");
  const [showFrontendError, setShowFrontendError] = useState("");
  const [showLoader, setShowLoader] = useState(false);
  const [userAddress, setUserAddress] = useState("");
  const [loggedIn, setLoggedIn] = useState(false);
  const targetNetworkIdEth = "0x5";
  const targetNetworkIdBnb = "0x61";
  const navigate = useNavigate();
  // const toggleReadMore = () => {
  //   setReadMore(!readMore);
  // };
  let BN = web3.utils.BN;
  useEffect(() => {
    clearClose();
  }, []);

  const clearClose = () => {
    clearUSDToEth();
    clearErrorState();
    setChosenCurrency("");
    setShowLoader(false);
    setShowFrontendError("");
    setAmount("");
  };
  useEffect(() => {
    clearClose();
    clearBTCPowerVote();
  }, [data]);

  useEffect(() => {
    if (userDetails !== undefined && userDetails.id) {
      setLoggedIn(true);
    }
  }, [userDetails]);

  // useEffect(() => {
  //   if (userDetails !== undefined && userDetails.walletAddress !== undefined) {
  //     handleAllTypeOfDonation();
  //   }
  //   // else {
  //   //   handleAllTypeOfDonation();
  //   // }
  //   console.log("awalys user , addreess", userAddress);
  // }, [userAddress]);

  window.ethereum !== undefined &&
    window.ethereum.on("accountsChanged", (accounts) => {
    
      setUserAddress(accounts[0]);
    });

  const handleAllTypeOfDonation = async () => {
    try {
      if (window.ethereum) {
        let data = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        setUserAddress(data[0]);
        return data[0];
      } else {
        alert(" Please install metamask to continue");
      }
    } catch (e) {}
  };
  const handlePay = async () => {
    let user = await handleAllTypeOfDonation();

    await handleDonationButton(user);
  };
  const handleBitCoin = () => {
    if (chooseCurrency === "btc" && Amount !== "") {
      donateBTCAsync({
        amount: Amount,
        projectId: data.id,
        loggedIn: userDetails !== undefined && userDetails.id ? true : false,
        openPopup: () => {
          bitOpen(true);
          setAmount("");
          setChosenCurrency("");
          setShowModal(false);
        },
      });
    }
  };

  const donateProjectUsingEth = async (userWallet) => {
    // console.log("user adddd", userAddress);
    setShowLoader(true);
    const targetNetworkId = "0x5";
    // console.log("web3 provider", provider.web3ForContract);
    // if (checkNetwork(targetNetworkId) === true) {
    // console.log("process.env", process.env.REACT_APP_ADDRESS);
    try {
      let contract = await new provider.web3ForContract.eth.Contract(
        CONTRACT_ABI,
        `${process.env.REACT_APP_ADDRESS}` // Contract address
      );

      // console.log("METHOD CALL .....");
      // console.log("Amount", web3.utils.toWei(Amount, "ether"));
      if (data !== undefined) {
        let deployingContract = await contract.methods
          .donateETH(
            web3.utils.toWei(Amount, "ether"), // Amount
            data.id, // Project id
            `${userWallet}`, // Sender address
            `${data.user.walletAddress}` // Creator address
          )
          .send({
            from: `${userWallet}`,
            value: web3.utils.toWei(Amount, "ether"),
            //gasPrice: "20000000000",
          });

        // console.log("Result -> ", deployingContract);
        if (deployingContract.status === true) {
          await donateProjectUsingEthAsync({
            amount: web3.utils.toWei(Amount, "ether"),
            fromAddress: `${userWallet}`,
            timeStamp: deployingContract.events.DonateETH.returnValues.eventid,
            transactionHash: deployingContract.transactionHash,
            toAddress: `${data.user.walletAddress}`,
            projectId: data.id,
            loggedIn,
            openSuccess: () => {
              setShowSuccess(true);
              clearUSDToEth();
              setAmount("");
              setChosenCurrency("");
              setShowModal(false);
            },
            //tokenAddress: "0xD87Ba7A50B2E7E660f678A895E4B72E7CB4CCd9C",
          });
        } else {
          await donateProjectUsingEthAsync({
            amount: web3.utils.toWei(Amount, "ether"),
            fromAddress: `${userWallet}`,
            // timeStamp: deployingContract.events.DonateETH.returnValues.eventid,
            transactionHash: deployingContract.transactionHash,
            toAddress: `${data.user.walletAddress}`,
            projectId: data.id,
            loggedIn,
            openSuccess: () => {
              setShowSuccess(true);
              setShowFrontendError("");
              setAmount("");
              clearUSDToEth();
              setChosenCurrency("");
              setShowModal(false);
            },
            //tokenAddress: "0xD87Ba7A50B2E7E660f678A895E4B72E7CB4CCd9C",
          });
        }
        setShowLoader(false);
      } else {
        setShowLoader(false);
        setShowFrontendError("Some error occured! Try again after some time");
        clearClose();
      }
    } catch (e) {
      // console.log(Object.keys(err));
      setShowLoader(false);
      clearClose();
      console.log(e);
      // console.log(e);
      // setHideFromUser(false);
      // if (e.receipt !== undefined && e.receipt.transactionHash !== undefined) {
      // setShowFrontendError()
      // metamaskError(e.receipt.transactionHash);
      // buyFailPopup(true);
      // }
      if (e.code === 4100) {
        // metamaskError(e.message);
        // buyFailPopup(true);

        setAmount("");
        clearUSDToEth();
        setChosenCurrency("");
        setShowFrontendError("Please login to metamask wallet");
      } else if (e.code === 4001) {
        // setShowFrontendError("Transaction has been cancelled"
        setTrue(true);
        setShowFrontendError("");
        setAmount("");
        clearUSDToEth();
        setShowModal(false);
      } else {
        setTrue(true);
        setShowModal(false);
      }
      // console.log("error when failed transaction", e);

      // setShowModal(false);

      // donateProjectUsingEthAsync({
      //   amount: Amount,
      //   fromAddress: `${userDetails.walletAddress}`,
      //   // timeStamp: deployingContract.events.DonateETH.returnValues.eventid,
      //   transactionHash: deployingContract.transactionHash,
      //   toAddress: `${data.user.walletAddress}`,
      //   openSuccess: () => {
      //     // setShowSuccess(true);
      //     setShowModal(false);
      //   },
      //tokenAddress: "0xD87Ba7A50B2E7E660f678A895E4B72E7CB4CCd9C",
      // });
      // alert("Error -> ", err);
    }
    // } else {
    //   setShowLoader(false);
    //   setShowFrontendError(
    //     "Please connect with goerli test network to continue !"
    //   );
    // }
  };

  // checks if current chain matches with the one we need
  // and returns true/false
  const checkNetwork = async (targetNetworkId) => {
    if (window.ethereum || localStorage.getItem("walletConnect")) {
      const currentChainId = await window.ethereum.request({
        method: "eth_chainId",
      });
      // console.log("currenchain id", currentChainId);
      // console.log("target network id", targetNetworkId);
      // return true if network id is the same
      if (currentChainId === targetNetworkId) {
        // console.log("inside true");
        return true;
      }
      // return false is network id is different
      else {
        // console.log("inside false");
        return false;
      }
    }
  };
  const donateProjectUsingBNB = async (userWallet) => {
    setShowLoader(true);

    console.log("bnb", process.env.REACT_APP_BNB_CONTRACT);
    // if (checkNetwork(targetNetworkId) === true) {
    try {
      let contract = await new provider.web3BNB.eth.Contract(
        BNB_CONTRACT,
        `${process.env.REACT_APP_BNB_CONTRACT}` // Contract address
        // "`${process.env.REACT_APP_BNB_CONTRACT}`"
      );

      // console.log("METHOD CALL .....");
      // console.log("Amount", web3.utils.toWei(Amount, "ether"));
      if (data !== undefined) {
        let deployingContract = await contract.methods
          .donateBNB(
            `${web3.utils.toWei(Amount, "ether")}`, // Amount
            data.id, // Project id
            `${userWallet}`, // Sender address
            `${data.user.walletAddress}`
          )
          .send({
            from: `${userWallet}`,
            value: web3.utils.toWei(Amount, "ether"),
            //gasPrice: "20000000000",
          });
        // console.log("Result -> ", deployingContract);
        if (deployingContract.status === true) {
          await donateProjectUsingEthAsync({
            amount: web3.utils.toWei(Amount, "ether"),
            fromAddress: `${userWallet}`,
            timeStamp: deployingContract.events.DonateBNB.returnValues.eventid,
            transactionHash: deployingContract.transactionHash,
            toAddress: `${data.user.walletAddress}`,
            projectId: data.id,
            loggedIn,
            binanceChain: true,
            openSuccess: () => {
              setShowSuccess(true);
              clearUSDToEth();
              setAmount("");
              setChosenCurrency("");
              setShowModal(false);
            },
            //tokenAddress: "0xD87Ba7A50B2E7E660f678A895E4B72E7CB4CCd9C",
          });
        } else {
          await donateProjectUsingEthAsync({
            amount: web3.utils.toWei(Amount, "ether"),
            fromAddress: `${userWallet}`,
            // timeStamp: deployingContract.events.DonateETH.returnValues.eventid,
            transactionHash: deployingContract.transactionHash,
            toAddress: `${data.user.walletAddress}`,
            projectId: data.id,
            loggedIn,
            binanceChain: true,
            openSuccess: () => {
              setShowSuccess(true);
              setShowFrontendError("");
              setAmount("");
              clearUSDToEth();
              setChosenCurrency("");
              setShowModal(false);
            },
            //tokenAddress: "0xD87Ba7A50B2E7E660f678A895E4B72E7CB4CCd9C",
          });
        }
        setShowLoader(false);
      } else {
        setShowLoader(false);
        setShowFrontendError("Some error occured! Try again after some time");
      }
    } catch (e) {
      // console.log(Object.keys(err));
      setShowLoader(false);
      clearClose();

      if (e.code === 4100) {
        // metamaskError(e.message);
        // buyFailPopup(true);

        setAmount("");
        clearUSDToEth();
        setChosenCurrency("");
        setShowFrontendError("Please login to metamask wallet");
      } else if (e.code === 4001) {
        // setShowFrontendError("Transaction has been cancelled"
        setTrue(true);
        setShowFrontendError("");
        setAmount("");
        clearUSDToEth();
        setShowModal(false);
      } else {
        setTrue(true);
        setShowModal(false);
      }
      // console.log("error when failed transaction", e);

      // setShowModal(false);

      // donateProjectUsingEthAsync({
      //   amount: Amount,
      //   fromAddress: `${userDetails.walletAddress}`,
      //   // timeStamp: deployingContract.events.DonateETH.returnValues.eventid,
      //   transactionHash: deployingContract.transactionHash,
      //   toAddress: `${data.user.walletAddress}`,
      //   openSuccess: () => {
      //     // setShowSuccess(true);
      //     setShowModal(false);
      //   },
      //tokenAddress: "0xD87Ba7A50B2E7E660f678A895E4B72E7CB4CCd9C",
      // });
      // alert("Error -> ", err);
    }
    // } else {
    //   setShowLoader(false);
    //   setShowFrontendError(
    //     "Please connect with binanace test network to continue !"
    //   );
    // }
  };
  const handleDonationButton = async (userAdd) => {
    if (userAdd !== "") {
      if (chooseCurrency === "eth" && Amount !== "") {
        // if (Amount !== "") {
        if ((await checkNetwork(targetNetworkIdEth)) === true) {
          let res = await handleAllTypeOfDonation();
          await donateProjectUsingEth(res);
        } else {
          setShowFrontendError(
            "Please connect with goerli test network to continue"
          );
        }
        // }
      } else if (chooseCurrency === "usdc" && Amount !== "") {
        if ((await checkNetwork(targetNetworkIdEth)) === true) {
          let res = await handleAllTypeOfDonation();
          await donateProjectUsingToken(res);
        } else {
          setShowFrontendError(
            "Please connect with goerli test network to continue"
          );
        }
        // donateProjectUsingTokenAsync();
      } else if (chooseCurrency === "bnb" && Amount !== "") {
        if ((await checkNetwork(targetNetworkIdBnb)) === true) {
          console.log("inside");
          let res = await handleAllTypeOfDonation();
          await donateProjectUsingBNB(res);
        } else {
          setShowFrontendError(
            "Please connect with binance test network to continue"
          );
        }
      } else if (chooseCurrency === "busd" && Amount !== "") {
        if ((await checkNetwork(targetNetworkIdBnb)) === true) {
          console.log("inside");
          let res = await handleAllTypeOfDonation();
          await donateProjectUsingTokenBUSD(res);
        } else {
          setShowFrontendError(
            "Please connect with binance test network to continue"
          );
        }
      } else {
        setShowFrontendError("All fields are mandatory to continue");
      }
    } else {
      if (chooseCurrency === "eth" || chooseCurrency === "usdc") {
        network("goerli");
      } else {
        network("binance");
      }
      openCheckMinBal(true);
      clearClose();
      setShowModal(false);
    }
  };
  useEffect(() => {
    if (Amount !== "" && chooseCurrency !== "") {
      setShowFrontendError("");
    }
  }, [Amount, chooseCurrency]);
  const donateProjectUsingToken = async (userWallet) => {
    setShowLoader(true);
    // const targetNetworkId = "0x5";
    // if (checkNetwork(targetNetworkId) === true) {
    try {
      let contract = await new provider.web3ForContract.eth.Contract(
        //USDCABI,
        CONTRACT_ABI,
        `${process.env.REACT_APP_ERC20}` // USDC_ADDRESS
      );
      console.log(contract.methods);
      let allowance = await contract.methods
        .allowance(
          userWallet, // User wallet address
          `${process.env.REACT_APP_ADDRESS}` // CREATE Contract address
        )
        .call();
      console.log("Allowance -> ", allowance);
      if (Number(allowance) < Number(web3.utils.toWei(Amount, "mwei"))) {
        let approval = await contract.methods
          .approve(
            `${process.env.REACT_APP_ADDRESS}`,
            web3.utils.toWei(Amount, "mwei")
          )
          .send({
            from: `${userWallet}`, //User wallet address
          });
        console.log("Approval -> ", approval);
      }
      let createcontract = await new provider.web3ForContract.eth.Contract(
        CONTRACT_ABI,
        `${process.env.REACT_APP_ADDRESS}`
      );
      if (data !== undefined) {
        console.log(
          "my details to contract",
          web3.utils.toWei(Amount, "mwei"), // Amount
          data.id, // Project id
          userWallet, // Sender
          `${process.env.REACT_APP_ERC20}`,
          data.user.walletAddress
        );
        let deployingContract = await createcontract.methods
          .donateERC20(
            web3.utils.toWei(Amount, "mwei"), // Amount
            data.id, // Project id
            userWallet, // Sender
            `${process.env.REACT_APP_ERC20}`,
            data.user.walletAddress // Creator address
          )
          .send({
            from: `${userWallet}`, //User wallet address
          });

        console.log("Result -> ", deployingContract);
        if (deployingContract.status === true) {
          await donateProjectUsingTokenAsync({
            amount: web3.utils.toWei(Amount, "mwei"),
            fromAddress: `${userWallet}`,
            timeStamp:
              deployingContract.events.DonateERC20.returnValues.eventid,
            transactionHash: deployingContract.transactionHash,
            toAddress: `${data.user.walletAddress}`,
            tokenName: "USDC",
            loggedIn,
            projectId: data.id,
            openSuccess: () => {
              setShowSuccess(true);
              setShowFrontendError("");
              setAmount("");
              setChosenCurrency("");
              setShowModal(false);
            },
            //tokenAddress: "0xD87Ba7A50B2E7E660f678A895E4B72E7CB4CCd9C",
          });
        } else {
          await donateProjectUsingTokenAsync({
            amount: web3.utils.toWei(Amount, "mwei"),
            fromAddress: `${userWallet}`,
            // timeStamp: deployingContract.events.DonateERC20.returnValues.eventid,
            transactionHash: deployingContract.transactionHash,
            toAddress: `${data.user.walletAddress}`,
            tokenName: "USDC",
            projectId: data.id,
            loggedIn,
            openSuccess: () => {
              setShowSuccess(true);
              setShowFrontendError("");
              setAmount("");
              setChosenCurrency("");
              setShowModal(false);
            },
            //tokenAddress: "0xD87Ba7A50B2E7E660f678A895E4B72E7CB4CCd9C",
          });
        }
        setShowLoader(false);
      } else {
        setShowLoader(false);
        setShowFrontendError("Some error occured! Try again after some time");
      }
    } catch (e) {
      console.log(e);
      setShowLoader(false);
      clearClose();
      console.log(e);
      // console.log(e);
      // setHideFromUser(false);
      // if (e.receipt !== undefined && e.receipt.transactionHash !== undefined) {
      // setShowFrontendError()
      // metamaskError(e.receipt.transactionHash);
      // buyFailPopup(true);
      // }
      if (e.code === 4100) {
        // metamaskError(e.message);
        // buyFailPopup(true);
        setAmount("");
        clearUSDToEth();
        setShowFrontendError("Please login to metamask wallet");
      } else if (e.code === 4001) {
        setTrue(true);
        setShowFrontendError("");
        setAmount("");
        clearUSDToEth();
        setChosenCurrency("");
        setShowModal(false);
      } else {
        setTrue(true);
        setShowModal(false);
      }
    }
    // }
    //  else {
    //   setShowLoader(false);
    //   setShowFrontendError(
    //     "Please connect with goerli test network to continue !"
    //   );
    // }
  };

  const donateProjectUsingTokenBUSD = async (userWallet) => {
    // setShowLoader(true);
    // console.log("busd called");
    try {
      let contract = await new provider.web3ForContract.eth.Contract(
        //BEP20ABI,
        BNB_CONTRACT,
        `${process.env.REACT_APP_BEP20}` // BEP20_ADDRESS
      );
      console.log(contract.methods, userAddress);
      let allowance = await contract.methods
        .allowance(
          userWallet, // User wallet address
          // CREATE Contract address
          `${process.env.REACT_APP_BNB_CONTRACT}`
        )
        .call();
      console.log("Allowance -> ", allowance);
      if (Number(allowance) < Number(web3.utils.toWei(Amount))) {
        let approval = await contract.methods
          .approve(
            //   `
            // ${process.env.REACT_APP_ADDRESS}
            // `
            `${process.env.REACT_APP_BNB_CONTRACT}`,
            web3.utils.toWei(Amount)
          )
          .send({
            from: `${userWallet}`, //User wallet address
          });
        console.log("Approval -> ", approval);
      }
      let createcontract = await new provider.web3ForContract.eth.Contract(
        BNB_CONTRACT,
        `${process.env.REACT_APP_BNB_CONTRACT}`
      );
      if (data !== undefined) {
        console.log(
          "my details to contract",
          web3.utils.toWei(Amount), // Amount
          data.id, // Project id
          userWallet, // Sender
          `${process.env.REACT_APP_BEP20}`,
          data.user.walletAddress
        );
        let deployingContract = await createcontract.methods
          .donateBEP20(
            web3.utils.toWei(Amount), // Amount
            data.id, // Project id
            userWallet, // Sender
            `${process.env.REACT_APP_BEP20}`,
            data.user.walletAddress // Creator address
          )
          .send({
            from: `${userWallet}`, //User wallet address
          });

        console.log("Result -> ", deployingContract);
        if (deployingContract.status === true) {
          await donateProjectUsingTokenAsync({
            amount: web3.utils.toWei(Amount),
            fromAddress: `${userWallet}`,
            timeStamp:
              deployingContract.events.DonateBEP20.returnValues.eventid,
            transactionHash: deployingContract.transactionHash,
            toAddress: `${data.user.walletAddress}`,
            tokenName: "BUSD",
            projectId: data.id,
            tokenAddress: `${process.env.REACT_APP_BEP20}`,
            binanceChain: true,
            loggedIn,
            openSuccess: () => {
              setShowSuccess(true);
              setShowFrontendError("");
              setAmount("");
              setChosenCurrency("");
              setShowModal(false);
            },
            //tokenAddress: "0xD87Ba7A50B2E7E660f678A895E4B72E7CB4CCd9C",
          });
        } else {
          await donateProjectUsingTokenAsync({
            amount: web3.utils.toWei(Amount),
            fromAddress: `${userWallet}`,
            // timeStamp: deployingContract.events.DonateERC20.returnValues.eventid,
            transactionHash: deployingContract.transactionHash,
            toAddress: `${data.user.walletAddress}`,
            tokenName: "BUSD",
            projectId: data.id,
            tokenAddress: `${process.env.REACT_APP_BEP20}`,
            binanceChain: true,
            loggedIn,
            openSuccess: () => {
              setShowSuccess(true);
              setShowFrontendError("");
              setAmount("");
              setChosenCurrency("");
              setShowModal(false);
            },
            //tokenAddress: "0xD87Ba7A50B2E7E660f678A895E4B72E7CB4CCd9C",
          });
        }
        setShowLoader(false);
      } else {
        setShowLoader(false);
        setShowFrontendError("Some error occured! Try again after some time");
      }
    } catch (e) {
      console.log(e);
      setShowLoader(false);
      clearClose();
      console.log(e);
      // console.log(e);
      // setHideFromUser(false);
      // if (e.receipt !== undefined && e.receipt.transactionHash !== undefined) {
      // setShowFrontendError()
      // metamaskError(e.receipt.transactionHash);
      // buyFailPopup(true);
      // }
      if (e.code === 4100) {
        // metamaskError(e.message);
        // buyFailPopup(true);
        setAmount("");
        clearUSDToEth();
        setShowFrontendError("Please login to metamask wallet");
      } else if (e.code === 4001) {
        setTrue(true);
        setShowFrontendError("");
        setAmount("");
        clearUSDToEth();
        setChosenCurrency("");
        setShowModal(false);
      } else {
        setTrue(true);
        setShowModal(false);
      }
    }
  };

  window.ethereum &&
    ethereum.on("chainChanged", (chainId) => {
      if (
        (chooseCurrency === "bnb" || chooseCurrency === "busd") &&
        checkNetwork(chainId) === true &&
        Amount !== ""
      ) {
        setShowFrontendError("");
        clearClose();
      } else if (
        (chooseCurrency === "usdc" || chooseCurrency === "eth") &&
        Amount !== "" &&
        checkNetwork(chainId) === true
      ) {
        setShowFrontendError("");
        clearClose();
      }
      window.location.reload();
    });

 

  const handleStripeCheckOut = ()=>{
    if(Amount !== "" && chooseCurrency === "fiat"){
      stripeCheckoutAsync({amountInDollars : Amount})
    }
  }
  useEffect(()=>{
    if(stripeCheckResult !== undefined){
      clearStripeCheckOutBackend();
    }
  } , [chooseCurrency])

  return (
    <Modal
      className="DonatePopup"
      show={show}
      onHide={() => {
        clearClose();
        setAmount("");
        setShowModal(false);
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        {(donateProjectEthLoading ||
          donateProjectTokenLoading ||
          convertedBTULoading ||
          convertedETULoading ||

          donateBTCLoading || stripeCheckLoading) && <Loader />}
        <CloseIcon
          className="closebtn"
          onClick={() => {
            clearClose();
            setAmount("");
            clearStripeCheckOutBackend();
            clearBTCPowerVote();
            setShowModal(false);
          }}
        />
        {showLoader && (
          <Loader
            message={
              "Transaction is in progress. It takes  2-10 minutes to complete."
            }
          />
        )}
        <Container>
          <Row>
            <Col lg={12}>
              <div className="detail">
                {data !== undefined && (
                  <div className="project-name">{data.name}</div>
                )}
                {data !== undefined && data.category !== undefined && (
                  <div className="category-name">{data.category.name}</div>
                )}
                {data !== undefined &&
                  data.totalDonatedAmount !== null &&
                  data.totalDonatedAmount > 0 && (
                    <div className="amt">
                      <div className="amount">${data.totalDonatedAmount}</div>

                      <div className="title">Total Fund Raised</div>
                    </div>
                  )}
              </div>
            </Col>
          </Row>
          <Row>
            <div className="inputs">
              <InputField
                placeholder={"Enter Amount"}
                type="number"
                onChange={(e) => setAmount(e.target.value)}
                value={Amount}
                required
              />
              <CurrencyDropdown
                chosenCurrencyUSD={(e) => setCurrencyUSD(e)}
                ethOrUsd={(e) => setChosenCurrency(e)}
                popupType={"Donate"}
              />
              {chooseCurrency === "usdc" && Amount !== "" ? (
                <div className="count"> {Amount} USDC</div>
              ) : chooseCurrency === "eth" && Amount !== "" ? (
                <div className="count">{Amount} ETH</div>
              ) : chooseCurrency === "bnb" && Amount !== "" ? (
                <div className="count"> {Amount} BNB</div>
              ) : chooseCurrency === "busd" && Amount !== "" ? (
                <div className="count">{Amount} BUSD</div>
              ) : chooseCurrency === "btc" && Amount !== "" ? (
                <div className="count">{Amount} BTC</div>
              ) :chooseCurrency === "fiat" && Amount !== "" ? (
                <div className="count">${Amount}</div>
              ) : (
                ""
              )}
            </div>
            {/* {description !== undefined && (
              <div className="desc">
                <ReactReadMoreReadLess
                  charLimit={65}
                  readMoreText={"read more"}
                  readLessText={"read less"}
                  readMoreClassName="read"
                  readLessClassName="read"
                >
                  {description}
                </ReactReadMoreReadLess>

              </div>
            )} */}
          </Row>
          <Row>
            <Col xs={12} className="btn-div">
              <div className="confirm">
                <CustomButton
                  type="submit"
                  name="Donate"
                  filled={true}
                  primary={true}
                  onClick={() => {
                    if (chooseCurrency === "btc" && Amount !== "") {
                      handleBitCoin();
                    } 
                    else if(chooseCurrency === "fiat" && Amount !== ""){
                      handleStripeCheckOut()
                    }
                     else{
                      handlePay();
                    }
                  }}
                />
              </div>
              <div className="cancel">
                <CustomButton
                  classname="cancelbtn"
                  type="submit"
                  name="Cancel"
                  filled={true}
                  primary={true}
                  onClick={() => {
                    clearClose();
                    clearStripeCheckOutBackend();
                    clearBTCPowerVote();
                    setShowModal(false);
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="error-text">
                {showFrontendError !== "" && showFrontendError}
                {donateProjectUsingEthError !== undefined &&
                  donateProjectUsingEthError}
                {donateProjectUsingTokenError !== undefined &&
                  donateProjectUsingTokenError}
                {donateBTCError !== undefined && donateBTCError}
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
const mapStateToProps = ({ powerVote = {}, user = {} }) => {
  const donateProjectEthLoading = _.get(
    powerVote,
    "donateProjectUsingEthLoading",
    false
  );
  const donateProjectTokenLoading = _.get(
    powerVote,
    "donateProjectUsingTokenLoading",
    false
  );
  const donateProjectUsingEthAchieved = _.get(
    powerVote,
    "donateProjectUsingEth",
    undefined
  );
  const usdValueEth = _.get(powerVote, "conversionFromUSDtoEth", undefined);
  const userDetails = _.get(user, "userInfo", undefined);
  const donateProjectUsingTokenAchieved = _.get(
    powerVote,
    "donateProjectUsingToken",
    undefined
  );
  const donateProjectUsingEthError = _.get(
    powerVote,
    "donateProjectUsingEthError",
    undefined
  );
  const donateProjectUsingTokenError = _.get(
    powerVote,
    "donateProjectUsingTokenError",
    undefined
  );
  const convertedETULoading = _.get(
    powerVote,
    "conversionFromEthToUsdLoading",
    false
  );
  const convertedETU = _.get(powerVote, "conversionFromEthtoUsd", undefined);
  const convertedBTULoading = _.get(
    powerVote,
    "conversionFromBNBtoUSDLoading",
    false
  );
  const convertedBTU = _.get(powerVote, "conversionFromBNBUSD", undefined);
  const donateBTCLoading = _.get(powerVote, "donateBitcoinLoading", false);
  const donateBTCSuccess = _.get(powerVote, "donateBitcoin", undefined);
  const donateBTCError = _.get(powerVote, "donateBitcoinError", undefined);
  const stripeCheckLoading = _.get(powerVote , "stripeCheckoutLoading" , false);
  const stripeCheckResult = _.get(powerVote , "stripeCheckout" , undefined);
  

  return {
    stripeCheckLoading,
    stripeCheckResult,
    donateBTCLoading,
    donateBTCSuccess,
    donateBTCError,
    convertedBTULoading,
    convertedBTU,
    convertedETU,
    convertedETULoading,
    donateProjectUsingEthAchieved,
    donateProjectTokenLoading,
    donateProjectEthLoading,
    usdValueEth,
    userDetails,
    donateProjectUsingEthError,
    donateProjectUsingTokenAchieved,
    donateProjectUsingTokenError,
  };
};
const mapDispatchToProps = (dispatch) => ({
  donateProjectUsingEthAsync: (data) =>
    dispatch({ type: "donateProjectUsingEthSagaCalled", payload: data }),
  clearErrorState: () => dispatch(clearReduxOnMount()),
  clearUSDToEth: () => dispatch(clearConversionFromUTS()),
  donateProjectUsingTokenAsync: (data) =>
    dispatch({
      type: "donateProjectUsingTokenSagaCalled",
      payload: data,
    }),
  conversionFromETUAsync: (data) =>
    dispatch({ type: "getUSDFromEthSagaCalled", payload: data }),
  conversionFromBTUAsync: (data) =>
    dispatch({ type: "getUSDFromBNBSagaCalled", payload: data }),
  donateBTCAsync: (data) =>
    dispatch({ type: "donateBitcoinSagaCalled", payload: data }),
    stripeCheckoutAsync : (data)=>dispatch({type : "stripeCheckoutSagaCalled" , payload : data}),
    clearStripeCheckOutBackend : ()=>dispatch(clearStripeCheckout()),
  clearBTCPowerVote: () => dispatch(clearDonateBTCPopup()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(DonatePopup));
