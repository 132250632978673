import React, { useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import { connect } from "react-redux";
import CustomButton from "@components/custom-button/CustomButton";
import "@components/filter/FilterStyle.scss";

const Filter = ({ allCategories, getCategories }) => {

  const FilterType = [
    {
     type: "Spotlight",
      
    },
    {
      type: "Trending",
    
    },
    {
      type: "Featured",
      
    },
    {
      type: "All",
    
    },
  ];

  return (
    <div className="Filter">
      <Container>
        <Row>
          <Col xs={4}>
            <div className="filter-type">Filter by Category</div>
            {allCategories &&
              allCategories.length > 0 &&
              allCategories.map((item) => (
                <div className="checkboxholder">
                  <Form.Check
                    type="radio"
                    id={`${item.id}`}
                    label={`${item.name}`}
                    name="category"
                    value={item.id}
                    // onChange={(e) => handleRadioButton(e)}
                  />
                </div>
              ))}
          </Col>
          <Col xs={4}>
            <div className="filter-type">Filter by Creator</div>
            <div className="checkboxholder">
              <Form.Check
                type="radio"
                // id={`${item.id}`}
                label="Most created projects"
                name="category"
                // value={item.id}
                // onChange={(e) => handleRadioButton(e)}
              />
            </div>
          </Col>
          <Col xs={4}>
            <div className="filter-type">Filter by Type</div>
            {FilterType.map((item) => (
            <div className="checkboxholder">
              <Form.Check
                type="radio"
                // id={`${item.id}`}
                label={`${item.type}`}
                name="category"
                // value={item.id}
                // onChange={(e) => handleRadioButton(e)}
              />
            </div>
            ))}
          </Col>
          <Col lg={12} sm={12} xs={12} className="btn-div">
            <div className="confirm">
              <CustomButton
                type="submit"
                name="Apply"
                filled={true}
                primary={true}
              />
            </div>
            <div className="cancel">
              <CustomButton
                classname="cancelbtn"
                type="submit"
                name="Reset"
                filled={true}
                primary={true}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
const mapStateToProps = ({ category = {} }) => {
  const allCategories = _.get(category, "categories", []);
  return {
    allCategories,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getCategories: () => dispatch({ type: "getCategoriesSagaCalled" }),
});
export default connect(mapStateToProps, mapDispatchToProps)(Filter);
