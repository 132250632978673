import React, { useEffect } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as CloseIcon } from "@images/close-icon.svg";
import "@components/delete-popup/DeletePopupStyle.scss";
const DeletePopup = ({
   show, 
   setShowModal, 
   setShowDeleteSuccess ,
    onPress ,
    onCancelled ,
     message,
     role ,
     type,
     onCancelled2,
     setStatusByAdmin
      }) => {


    

  return (
    <Modal
      className="DeletePopup"
      show={show}
      onHide={() => {
        setShowModal(false);
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon
          className="closebtn"
          onClick={() => {
           
            setShowModal(false);
          }}
        />
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading-text">
                Are You Sure ,<br />
                {message}
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} sm={12} xs={12} className={role == "admin" ? "admin-btn-div" : "btn-div"}>
              <div className="confirm">
                <CustomButton
                  type="submit"
                  name="Yes"
                  filled={true}
                  primary={true}
                  onClick={() => {
                    if(setStatusByAdmin){
                      setStatusByAdmin("deleted")
                      // onPress();
                    } 
                
                    // setShowDeleteSuccess(true)
                    if(onPress!==undefined){
                      onPress()
                    }
                    setShowModal(false)
                  }}
                />
              </div>
              <div className="cancel">
                <CustomButton
                  classname="cancelbtn"
                  type="button"
                  name="Cancel"
                  filled={true}
                  primary={true}
                  onClick={() => {
                   
                   onCancelled()
                    // setShowModal(false) 
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(DeletePopup);
