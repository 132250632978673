import React, { useEffect } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as CloseIcon } from "@images/close-icon.svg";
import { ReactComponent as Failed } from "@images/failed-popup.svg";
import { ReactComponent as Tick } from "@images/orng-tick.svg";
import "@components/vote-successful-popup/VoteSuccessfulPopupStyle.scss";
import { connect } from "react-redux";
import {
  clearReduxOnMount,
  clearDonationUsingEth,
  clearDonationUsingERC20,
} from "@store/powerVote_redux";
import {
  clearPowerVoteEthState,
  clearBuyPowerVoteUsingToken,
} from "@store/powerVote_redux";

const VoteSuccessfulPopup = ({
  show,
  setShowModal,
  message,
  popupType,
  setShowSelectPopup,
  navigateToEtherScan,
  showRedirection,
  setMetaErrorStateToPrevious,
  clearPowerVoteBuyState,
  clearPowerVoteBuyTokenState,
  popupSuccess,
  clearProejctUsingERC20State,
  clearProjectUsingEthState,
  setTrue,
  navigateToBSC,
  setEtherError,
  setBSCError,
}) => {
  const clear = () => {
    clearProejctUsingERC20State();
    clearProjectUsingEthState();
  };
  const clearPowerVoteState = () => {};

  return (
    <Modal
      className="VoteSuccessfulPopup"
      show={show}
      onHide={() => {
        popupType === "donation" && clear();
        popupType === "transactionCancel" && setTrue(false);
        setShowModal(false);
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon
          className="closebtn"
          onClick={() => {
            popupType === "donation" && clear();
            popupType === "transactionCancel" && setTrue(false);
            setShowModal(false);
          }}
        />
        <Container>
          <Row>
            <Col lg={12}>
              {popupSuccess === false ? (
                <div className="success">
                  <Failed className="tick-icon" />
                  <div className="bold-text">Oops!</div>
                  <div className="sub-text">{message}</div>
                </div>
              ) : (
                <div className="success">
                  <Tick className="tick-icon" />
                  <div className="bold-text">Great!</div>
                  <div className="sub-text">{message}</div>
                  {/* {(popupType === "redirectToEtherScan" ||
                    showRedirection === true) &&
                  navigateToEtherScan !== "" ? (
                    <div
                      className="link-text"
                      onClick={() => {
                        window.open(
                          `https://goerli.etherscan.io/tx/${navigateToEtherScan}`
                        );
                      }}
                    >
                      Check on etherscan
                    </div>
                  ) : (
                    (popupType === "redirectToEtherScan" ||
                      showRedirection === true) &&
                    navigateToBSC !== "" && (
                      <div
                        className="link-text"
                        onClick={() => {
                          window.open(
                            `https://testnet.bscscan.com/tx/${navigateToBSC}`
                          );
                        }}
                      >
                        Check on bscscan
                      </div>
                    )
                  )} */}
                </div>
              )}
            </Col>
          </Row>
          <Row>
            {popupType === "buySucess" ? (
              <Col lg={12} sm={12} xs={12} className="btn-div">
                <div className="cancel">
                  <CustomButton
                    classname="cancelbtn"
                    type="submit"
                    name="Continue"
                    filled={true}
                    primary={true}
                    onClick={() => {
                      setShowSelectPopup(true);
                      setBSCError("");
                      setEtherError("");
                      clearPowerVoteBuyState();
                      clearPowerVoteBuyTokenState();
                      setShowModal(false);
                    }}
                  />
                </div>
              </Col>
            ) : popupType === "pauseit" ? null : (
              <Col lg={12} sm={12} xs={12} className="btn-div">
                <div className="cancel">
                  <CustomButton
                    classname="cancelbtn"
                    type="submit"
                    name="Close"
                    filled={true}
                    primary={true}
                    onClick={() => {
                      if (setMetaErrorStateToPrevious !== undefined) {
                        setMetaErrorStateToPrevious("");
                      }
                      popupType === "donation" && clear();
                      popupType === "transactionCancel" && setTrue(false);
                      setShowModal(false);
                    }}
                  />
                </div>
              </Col>
            )}
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
const mapDispatchToProps = (dispatch) => ({
  clearProjectUsingEthState: () => dispatch(clearDonationUsingEth()),
  clearProejctUsingERC20State: () => dispatch(clearDonationUsingERC20()),
  clearPowerVoteBuyState: () => dispatch(clearPowerVoteEthState()),
  clearPowerVoteBuyTokenState: () => dispatch(clearBuyPowerVoteUsingToken()),
});

export default connect(
  null,
  mapDispatchToProps
)(React.memo(VoteSuccessfulPopup));
