import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import CreatorDetailPageCard from "@components/creator-detail-page-card/CreatorDetailPageCard";
import NavBar from "@components/navbar/NavBar";
import "@components/all-projects/AllProjectsStyle.scss";

const AllProjects = () => {
  return (
    <>
      <NavBar pageName="My Projects" />
      <div className="AllProjects">
        <Container>
          <Row>
            <Col>
              <CreatorDetailPageCard page={"see-all"}/>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};


export default AllProjects;
