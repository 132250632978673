import React, { Fragment } from "react";
import * as _ from "lodash";
import "@components/custom-button/CustomButtonStyles.scss";

const CustomButton = ({
  type,
  filled,
  primary,
  name,
  navigation,
  disable,
  text,
  image,
  testvalue,
  onClick,
  active,
  leftIcon,
  rounded,
  bordered,
  classname,
  id,
}) => {
  return (
    <div className="CustomButton">
      {filled && primary && !leftIcon ? (
        <button
          id={id}
          disabled={disable}
          type={type}
          onClick={onClick}
          className={`buttonmain ${
            classname && classname
          }`}
        >
          {name}
        </button>
      ) : filled && !primary && !leftIcon ? (
        <button
          disabled={disable}
          type={type}
          onClick={onClick}
          id={id}
          className="nonfilledbtn"
        >
          {name}
        </button>
      ) : text && image ? (
        <button
          disabled={disable}
          type={type}
          onClick={onClick}
          className="btnwithimg"
          id={id}
        >
          <span className="buttontext"> {name}</span>
          {testvalue}
        </button>
      ) : filled && primary && leftIcon ? (
        <button
          disabled={disable}
          type={type}
          onClick={onClick}
          className="btnwithlefticon"
          id={id}
        >
          <img src={leftIcon} alt="button left icon" className="lefticonimg" />
          <span className="buttontext2"> {name}</span>
        </button>
      ) : rounded && bordered ? (
        <button
          disabled={disable}
          type={type}
          onClick={onClick}
          id={id}
          className={`roundedbordered`}
        >
          <span className={`buttontext ${classname && classname}`}>
            {" "}
            {name}
          </span>
        </button>
      ) : null}
    </div>
  );
};
export default CustomButton;
