import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
  name: "Notification",
  initialState: {
    notificationLoading: false,
    allNotifications: undefined,
    notificationError: undefined,

    notificationCountLoading: false,
    notficationCount: undefined,
    notificationCountError: undefined,

    singleNotificationReadLoading: false,
    singleNotificationRead: undefined,
    singleNotificationReadError: undefined,

    allNotificationReadLoading: false,
    allNotificationRead: undefined,
    allNotificationReadError: undefined,

    newsLetterLoading: false,
    newsLetter: undefined,
    newsLetterError: undefined,
  },
  reducers: {
    notficationLoadingStart: (state, action) => {
      return {
        ...state,
        notificationLoading: true,
        allNotifications: undefined,
        notificationError: undefined,
      };
    },
    notificationSuccess: (state, action) => {
      return {
        ...state,
        notificationLoading: false,
        allNotifications: action.payload,
        notificationError: undefined,
      };
    },
    notificationFailure: (state, action) => {
      return {
        ...state,
        notificationLoading: false,
        allNotifications: undefined,
        notificationError: action.payload,
      };
    },

    notficationCountLoadingStart: (state, action) => {
      return {
        ...state,
        notificationCountLoading: true,
        notficationCount: undefined,
        notificationCountError: undefined,
      };
    },
    notificationCountSuccess: (state, action) => {
      return {
        ...state,
        notificationCountLoading: false,
        notficationCount: action.payload,
        notificationCountError: undefined,
      };
    },
    notificationCountFailure: (state, action) => {
      return {
        ...state,
        notificationCountLoading: false,
        notficationCount: undefined,
        notificationCountError: action.payload,
      };
    },
    singleNotificationReadLoadingStart: (state, action) => {
      return {
        ...state,
        singleNotificationReadLoading: true,
        singleNotificationRead: undefined,
        singleNotificationReadError: undefined,
      };
    },
    singleNotificationReadSuccess: (state, action) => {
      return {
        ...state,
        singleNotificationReadLoading: false,
        singleNotificationRead: action.payload,
        singleNotificationReadError: undefined,
      };
    },
    singleNotificationReadFailure: (state, action) => {
      return {
        ...state,
        singleNotificationReadLoading: false,
        singleNotificationRead: undefined,
        singleNotificationReadError: action.payload,
      };
    },

    allNotificationReadLoadingStart: (state, action) => {
      return {
        ...state,
        allNotificationReadLoading: true,
        allNotificationRead: undefined,
        allNotificationReadError: undefined,
      };
    },
    allNotificationReadSuccess: (state, action) => {
      return {
        ...state,
        allNotificationReadLoading: false,
        allNotificationRead: action.payload,
        allNotificationReadError: undefined,
      };
    },
    allNotificationReadFailure: (state, action) => {
      return {
        ...state,
        allNotificationReadLoading: false,
        allNotificationRead: undefined,
        allNotificationReadError: action.payload,
      };
    },
    newsLetterLoadingStart: (state, action) => {
      return {
        ...state,
        newsLetterLoading: true,
        newsLetter: undefined,
        newsLetterError: undefined,
      };
    },
    newsLetterSuccess: (state, action) => {
      return {
        ...state,
        newsLetterLoading: false,
        newsLetter: action.payload,
        newsLetterError: undefined,
      };
    },
    newsLetterFailure: (state, action) => {
      return {
        ...state,
        newsLetterLoading: false,
        newsLetter: undefined,
        newsLetterError: action.payload,
      };
    },
    clearNotificationErrorFromBackend: (state, action) => {
      return {
        ...state,
        newsLetterError: undefined,
      };
    },
  },
});
export default notificationSlice.reducer;
export const {
  notficationLoadingStart,
  notificationSuccess,
  notificationFailure,
  notficationCountLoadingStart,
  notificationCountSuccess,
  notificationCountFailure,
  singleNotificationReadLoadingStart,
  singleNotificationReadSuccess,
  singleNotificationReadFailure,
  allNotificationReadLoadingStart,
  allNotificationReadSuccess,
  allNotificationReadFailure,
  newsLetterLoadingStart,
  newsLetterSuccess,
  newsLetterFailure,
  clearNotificationErrorFromBackend,
} = notificationSlice.actions;
