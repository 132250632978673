import { call, put } from "redux-saga/effects";
import Api from "./../services/ApiCaller";
import { disconnect } from "src/WalletProvider/Web3";

import { clearUserRedux, clearUserDetails } from "./user_redux";

import { clearAdminRedux } from "./admin_redux";
import { clearCategoryRedux } from "./category_redux";

import { clearProjectRedux } from "./project_redux";
import { clearCompetitionRedux } from "./competetion_redux";
import { clearPowerVoteRedux, clearDonationRedux } from "./powerVote_redux";
import {
  checkMinBalLoadingStart,
  checkMinBalSuccess,
  checkMinBalFailure,
  getUserBalLoadingStart,
  getUserBalSuccess,
  getUserBalFailure,
  getExchangeCreateToBusdLoadingStart,
  getExchangeCreateToBusdSuccess,
  getExchangeCreateToBusdFailure,
  clearAppRedux,
  getProjectCreatorCountLoadingStart,
  getProjectCreatorCountSuccess,
  getProjectCreatorCountFailure,
} from "./app_redux";
import { getAccessToken } from "src/utilities/utility";

export function* logOut(api, { payload }) {
  if (payload !== undefined) {
    const { callFromMetaMask, disconnectMetaMask } = payload;
    if (callFromMetaMask === false) {
      disconnect();
    } else {
      disconnectMetaMask(false);
    }
    if (localStorage.walletconnect) {
      disconnect();
    }
  }

  yield put(clearUserRedux());
  yield put(clearAdminRedux());
  yield put(clearCategoryRedux());
  yield put(clearProjectRedux());
  yield put(clearCompetitionRedux());
  // yield put(clearPowerVoteRedux());
  yield put(clearAppRedux());
  yield put(clearDonationRedux());
  // yield put(clearUserDetails());
  // localStorage.clear();
}

export function* CheckMinimalBalanceOfUser(api, { payload }) {
  yield put(checkMinBalLoadingStart());
  const token = yield getAccessToken();

  try {
    const result = yield call(
      Api.callServer,
      api.CheckWalletBalance,
      token,
      true
    );
    // console.log("min bal checkup");
    yield put(checkMinBalSuccess(result.result));
    return true;
  } catch (e) {
    // yield put(checkMinBalSuccess(false))
    yield put(checkMinBalFailure(e.error));
    return false;
  }
}

export function* GetUserWalletBalanceSaga(api, { payload }) {
  yield put(getUserBalLoadingStart());
  const token = yield getAccessToken();
  try {
    const result = yield call(
      Api.callServer,
      api.GetCreateTokenBalanceForWallet,
      token,
      true
    );

    yield put(getUserBalSuccess(result.result));
  } catch (e) {
    yield put(getUserBalFailure(e.error));
  }
}

export function* GetExchangeRateCreateToBusdSaga(api, { payload }) {
  yield put(getExchangeCreateToBusdLoadingStart());
  const token = yield getAccessToken();
  try {
    const result = yield call(
      Api.callServer,
      api.GetCreateTokenExchangeRateInBusd,
      token,
      true
    );
    yield put(getExchangeCreateToBusdSuccess(result.result));
  } catch (e) {
    yield put(getExchangeCreateToBusdFailure(e.error));
  }
}

export function* GetCreatorProjectCountForHomePageSaga(api, { payload }) {
  yield put(getProjectCreatorCountLoadingStart());
  try {
    const result = yield call(
      Api.callServer,
      api.GetAllCreatorAndProjectsForHome,
      {},
      true
    );
    yield put(getProjectCreatorCountSuccess(result.result));
  } catch (e) {
    yield put(getProjectCreatorCountFailure(e.error));
  }
}
