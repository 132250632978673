import * as _ from "lodash";
import { select } from "redux-saga/effects";

export function* getAccessToken() {
  const user = yield select(({ user }) => user);
  return _.get(user, "accessToken", false);
}

export function* getuserDetails() {
  const user = yield select(({ user }) => user);
  return _.get(user, "userInfo", undefined);
}

export function* getMinWalletBalanceCheck() {
  const checkBal = yield select(({ app }) => app);

  return _.get(checkBal, "checkMinimalBalance", false);
}
