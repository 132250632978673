import { createSlice } from "@reduxjs/toolkit";

const projectSlice = createSlice({
  name: "Project",
  initialState: {
    createProjectLoading: undefined,
    createProjectError: undefined,
    listAllProjectLoading: undefined,
    listAllProjectError: undefined,
    deleteProjectLoading: undefined,
    deleteProjectError: undefined,
    editProjectStart: undefined,
    editProjectError: undefined,
    allProjects: [],
    getliveProjectLoading: undefined,
    getliveProjectError: undefined,
    getallliveProjects: [],
    publishProjectLoading: undefined,
    publishProjectError: undefined,
    joinElectionLoading: undefined,
    joinElectionError: undefined,
    voteProjectLoading: undefined,
    voteProjectError: undefined,
    top10ProjectLoading: undefined,
    top10Projects: [],
    top10ProjectsError: undefined,
    donateProjectLoading: undefined,
    donateProjects: [],
    donateProjectsError: undefined,
    publishToDraftLoading: undefined,
    publishToDraftError: undefined,
    searchProjectLoading: undefined,
    searchProjects: [],
    searchProjectError: undefined,
    singleProjectDetailLoading: undefined,
    singleProjectDetail: {},
    singleProjectDetailError: undefined,
    projectSpotlightLoading: undefined,
    projectSpotlightError: undefined,
    top50ProjectLoading: false,
    top50Projects: [],
    top50ProjectError: undefined,

    getDonationReceivedLoading: false,
    getDonations: undefined,
    getDonationError: undefined,

    getTotalDonationLoading: false,
    getTotalDonation: undefined,
    getTotalDonationError: undefined,

    searchResultLoading: false,
    searchResult: undefined,
    searchResultError: undefined,
  },

  reducers: {
    getDReceivedLoadingStart: (state, action) => {
      return {
        ...state,
        getDonationReceivedLoading: false,
        getDonations: undefined,
        getDonationError: undefined,
      };
    },
    getDReceivedSuccess: (state, action) => {
      return {
        ...state,
        getDonationReceivedLoading: false,
        getDonations: action.payload,
        getDonationError: undefined,
      };
    },
    getDReceivedFailure: (state, action) => {
      return {
        ...state,
        getDonationReceivedLoading: false,
        getDonations: undefined,
        getDonationError: action.payload,
      };
    },
    getTotalDonationLoadingStart: (state, action) => {
      return {
        ...state,

        getTotalDonationLoading: true,
        getTotalDonation: undefined,
        getTotalDonationError: undefined,
      };
    },
    getTotalDonationSuccess: (state, action) => {
      return {
        ...state,

        getTotalDonationLoading: false,
        getTotalDonation: action.payload,
        getTotalDonationError: undefined,
      };
    },
    getTotalDonationFailure: (state, action) => {
      return {
        ...state,

        getTotalDonationLoading: false,
        getTotalDonation: undefined,
        getTotalDonationError: action.payload,
      };
    },
    createProjectStart: (state, action) => {
      return {
        ...state,
        createProjectLoading: true,
        createProjectError: undefined,
      };
    },

    createProjectSuccess: (state, action) => {
      return {
        ...state,
        createProjectLoading: false,
        createProjectError: undefined,
      };
    },

    createProjectFailure: (state, action) => {
      return {
        ...state,
        createProjectLoading: false,
        createProjectError: action.payload,
      };
    },

    listAllProjectStart: (state) => {
      return {
        ...state,
        listAllProjectLoading: true,
      };
    },
    listAllProjectSuccess: (state, action) => {
      return {
        ...state,
        listAllProjectLoading: false,
        allProjects: action.payload,
      };
    },

    listAllProjectFailure: (state, action) => {
      return {
        ...state,
        listAllProjectLoading: false,
        listAllProjectError: action.payload,
        allProjects: [],
      };
    },

    deleteProjectStart: (state, action) => {
      return {
        ...state,
        deleteProjectLoading: true,
      };
    },

    deleteProjectSuccess: (state, action) => {
      return {
        ...state,
        deleteProjectLoading: false,
      };
    },

    deleteProjectFailure: (state, action) => {
      return {
        ...state,
        deleteProjectLoading: false,
        deleteProjectError: action.payload,
      };
    },

    editProjectStart: (state, action) => {
      return {
        ...state,
        editProjectStart: true,
        editProjectError: undefined,
      };
    },

    editProjectSuccess: (state, action) => {
      return {
        ...state,
        editProjectStart: false,
        editProjectError: undefined,
      };
    },

    editProjectFailure: (state, action) => {
      return {
        ...state,
        editProjectStart: false,
        editProjectError: action.payload,
      };
    },
    clearProjectErrorFromBackend: (state, action) => {
      return {
        ...state,
        createProjectError: undefined,
        editProjectError: undefined,
        listAllProjectError: undefined,
        deleteProjectError: undefined,
        getliveProjectError: undefined,
        publishProjectError: undefined,
        joinElectionError: undefined,
        voteProjectError: undefined,
        top10ProjectsError: undefined,
        donateProjectsError: undefined,
        publishToDraftError: undefined,
        searchProjectError: undefined,
        singleProjectDetailError: undefined,
        projectSpotlightError: undefined,
      };
    },
    clearProjectRedux: (state, action) => {
      return {};
    },
    liveProjectLoading: (state, action) => {
      return {
        ...state,
        getliveProjectLoading: true,
        getliveProjectError: undefined,
        getallliveProjects: [],
      };
    },

    liveProjectSuccess: (state, action) => {
      return {
        ...state,
        getliveProjectLoading: false,
        getliveProjectError: undefined,
        getallliveProjects: action.payload,
      };
    },

    liveProjectFailure: (state, action) => {
      return {
        ...state,
        getliveProjectLoading: false,
        getliveProjectError: action.payload,
        getallliveProjects: [],
      };
    },
    publishProjectLoadingStart: (state, action) => {
      return {
        ...state,
        publishProjectLoading: true,
        publishProjectError: undefined,
      };
    },
    publishProjectSuccess: (state, action) => {
      return {
        ...state,
        publishProjectLoading: false,
        publishProjectError: undefined,
      };
    },
    publicProjectFailure: (state, action) => {
      return {
        ...state,
        publishProjectLoading: false,
        publishProjectError: action.payload,
      };
    },
    joinElectionLoadingStart: (state, action) => {
      return {
        ...state,
        joinElectionLoading: true,
        joinElectionError: undefined,
      };
    },
    joinElectionSuccess: (state, action) => {
      return {
        ...state,
        joinElectionLoading: false,
        joinElectionError: undefined,
      };
    },
    joinElectionFailure: (state, action) => {
      return {
        ...state,
        joinElectionLoading: false,
        joinElectionError: action.payload,
      };
    },
    voteProjectLoadingStart: (state, action) => {
      return {
        ...state,
        voteProjectLoading: true,
        voteProjectError: undefined,
      };
    },
    voteProjectSuccess: (state, action) => {
      return {
        ...state,
        voteProjectLoading: false,
        voteProjectError: undefined,
      };
    },
    voteProjectFailure: (state, action) => {
      return {
        ...state,
        voteProjectLoading: false,
        voteProjectError: action.payload,
      };
    },

    top10ProjectLoadingStart: (state, action) => {
      return {
        ...state,
        top10ProjectLoading: true,
        top10Projects: [],
        top10ProjectsError: undefined,
      };
    },
    top10ProjectSuccess: (state, action) => {
      return {
        ...state,
        top10ProjectLoading: false,
        top10Projects: action.payload,
        top10ProjectsError: undefined,
      };
    },
    top10ProjectFailure: (state, action) => {
      return {
        ...state,
        top10ProjectLoading: false,
        top10Projects: [],
        top10ProjectsError: action.payload,
      };
    },
    donateProjectLoadingStart: (state, action) => {
      return {
        ...state,
        donateProjectLoading: true,
        donateProjects: [],
        donateProjectsError: undefined,
      };
    },
    donateProjectSuccess: (state, action) => {
      return {
        ...state,
        donateProjectLoading: false,
        donateProjects: action.payload,
        donateProjectsError: undefined,
      };
    },
    donateProjectFaliure: (state, action) => {
      return {
        ...state,
        donateProjectLoading: false,
        donateProjects: [],
        donateProjectsError: action.payload,
      };
    },
    publishToDraftLoadingStart: (state, action) => {
      return {
        ...state,
        publishToDraftLoading: true,
        publishToDraftError: undefined,
      };
    },
    publishToDraftSuccess: (state, action) => {
      return {
        ...state,
        publishToDraftLoading: false,
        publishToDraftError: undefined,
      };
    },
    publishToDraftFailure: (state, action) => {
      return {
        ...state,
        publishToDraftLoading: false,
        publishToDraftError: action.payload,
      };
    },
    searchProjectLoadingStart: (state, action) => {
      return {
        ...state,
        searchProjectLoading: true,
        searchProjects: [],
        searchProjectError: undefined,
      };
    },
    searchProjectSuccess: (state, action) => {
      return {
        ...state,
        searchProjectLoading: false,
        searchProjects: action.payload,
        searchProjectError: undefined,
      };
    },
    searchProjectFailure: (state, action) => {
      return {
        ...state,
        searchProjectLoading: false,
        searchProjects: [],
        searchProjectError: action.payload,
      };
    },
    singleProjectDetailLoadingStart: (state, action) => {
      return {
        ...state,
        singleProjectDetailLoading: true,
        singleProjectDetail: {},
        singleProjectDetailError: undefined,
      };
    },
    singleProjectDetailSuccess: (state, action) => {
      return {
        ...state,
        singleProjectDetailLoading: false,
        singleProjectDetail: action.payload,
        singleProjectDetailError: undefined,
      };
    },
    singleProjectDetailFailure: (state, action) => {
      return {
        ...state,
        singleProjectDetailLoading: false,
        singleProjectDetail: {},
        singleProjectDetailError: action.payload,
      };
    },
    projectSpotlightLoadingStart: (state, action) => {
      return {
        ...state,
        projectSpotlightLoading: true,
        projectSpotlightError: undefined,
      };
    },
    projectSpotlightSuccess: (state, action) => {
      return {
        ...state,
        projectSpotlightLoading: false,
        projectSpotlightError: undefined,
      };
    },
    projectSpotlightError: (state, action) => {
      return {
        ...state,
        projectSpotlightLoading: false,
        projectSpotlightError: action.payload,
      };
    },
    top50ProjectLoadingStart: (state, action) => {
      return {
        ...state,
        top50ProjectLoading: true,
        top50Projects: [],
        top50ProjectError: undefined,
      };
    },
    top50ProjectSuccess: (state, action) => {
      return {
        ...state,
        top50ProjectLoading: false,
        top50Projects: action.payload,
        top50ProjectError: undefined,
      };
    },
    top50ProjectFailure: (state, action) => {
      return {
        ...state,
        top50ProjectLoading: false,
        top50Projects: [],
        top50ProjectError: action.payload,
      };
    },

    searchResultLoadingStart: (state, action) => {
      return {
        ...state,
        searchResultLoading : true,
        searchResult : [],
        searchResultError : undefined,
      }
    },

    searchResultSuccess: (state, action) => {
      return {
        ...state,
        searchResultLoading : false, 
        searchResult : action.payload,
        searchResultError : undefined,
      }
    },

    searchResultFailure: (state, action) => {
      return {
        ...state,
        searchResultLoading : false,
        searchResult : [],
        searchResultError : action.payload,
      }
    },
  },
});

export default projectSlice.reducer;

export const {
  createProjectStart,
  createProjectSuccess,
  createProjectFailure,
  listAllProjectStart,
  listAllProjectSuccess,
  listAllProjectFailure,
  deleteProjectStart,
  deleteProjectSuccess,
  deleteProjectFailure,
  editProjectStart,
  editProjectSuccess,
  editProjectFailure,
  clearProjectRedux,
  liveProjectLoading,
  liveProjectSuccess,
  liveProjectFailure,
  publishProjectLoadingStart,
  publishProjectSuccess,
  publicProjectFailure,
  joinElectionLoadingStart,
  joinElectionSuccess,
  joinElectionFailure,
  voteProjectLoadingStart,
  voteProjectSuccess,
  voteProjectFailure,
  top10ProjectLoadingStart,
  top10ProjectSuccess,
  top10ProjectFailure,
  donateProjectLoadingStart,
  donateProjectSuccess,
  donateProjectFaliure,
  publishToDraftLoadingStart,
  publishToDraftSuccess,
  publishToDraftFailure,
  searchProjectLoadingStart,
  searchProjectSuccess,
  searchProjectError,
  clearProjectErrorFromBackend,
  singleProjectDetailLoadingStart,
  singleProjectDetailSuccess,
  singleProjectDetailFailure,
  projectSpotlightLoadingStart,
  projectSpotlightSuccess,
  projectSpotlightError,
  top50ProjectLoadingStart,
  top50ProjectSuccess,
  top50ProjectFailure,
  getDReceivedLoadingStart,
  getDReceivedSuccess,
  getDReceivedFailure,
  getTotalDonationLoadingStart,
  getTotalDonationSuccess,
  getTotalDonationFailure,
  searchResultLoadingStart,
  searchResultSuccess,
  searchResultFailure,
} = projectSlice.actions;
