import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom/index";
import Spinner from "@components/Spinner/Spinner";
import "@components/search-result/SearchResultStyle.scss";

const SearchResult = ({ searchData }) => {
  const navigate = useNavigate();

  return (
    <div className="SearchResult">
      {
        searchData !== undefined &&
          searchData.length > 0 &&
          searchData.map((data) => (
            <li
              key={data.id}
              onClick={() => {
                if (data && data.type) {
                  navigate(`/competition-details/${data.id}`);
                } else {
                  navigate(`/project-details/${data.id}`);
                }
              }}
            >
              <Dropdown.Item>
                <div className="result">
                  {data !== undefined && data.bannerImage !== null && (
                    <div className="image">
                      <img
                        src={`${process.env.REACT_APP_IMGLINK}${data.bannerImage}`}
                        className="img"
                      />
                    </div>
                  )}
                  <span className="project-namee">{data.name}</span>
                </div>
              </Dropdown.Item>
              <hr />
            </li>
          ))
        // :<span>No results</span>
      }
    </div>
  );
};

// const mapStateToProps = ({ user = {} }) => {
//   const searchResultLoading = _.get(user, "searchLoading", false);
//   return {
//     searchResultLoading,
//   };
// };

export default SearchResult;
