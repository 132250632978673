import apisauce from "apisauce";
import _ from "lodash";
const baseUrl = process.env.REACT_APP_APILINK;
const create = (baseURL = baseUrl) => {
  const apis = apisauce.create({
    baseURL,
    headers: {
      "Cache-Control": "no-cache",
    },
    timeout: 200000,
  });

  // api listing starts

  // onboarding starts

  const loginApi = (data) => {
    return apis.post(`auth/checkUserPresent`, {
      walletAddress: data.walletAddress,
    });
  };

  const createUserApi = (data) => {
    const formData = new FormData();
    formData.append("walletAddress", data.walletAddress);
    formData.append("name", data.name);
    formData.append("file", data.file);
    formData.append("twitterUserName", data.twitterUserName);
    formData.append("email", data.email);

    return apis.post(`auth/createUser`, formData);
  };

  // onboarding ends

  // category starts

  const GetCategoriesApi = (data) => {
    return apis.get(`category/allCategories`);
  };

  // category ends

  // crud elenction starts
  const GetElectionApi = (data) => {
    const { details, token } = data;
    const { skip, limit, type } = details;
    return apis.get(
      `election/getAllElections?skip=${skip}&limit=${limit}&type=${type}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const CreatElectionApi = (data) => {
    const { token, details } = data;
    console.log("api data for backend", data);
    delete data["token"];
    return apis.post(`election/create`, details, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  const VotingHistoryApplication = (data) => {
    const { dataForBackend, token } = data;
    return apis.get(
      `/user/allVotes?skip=${dataForBackend.skip}&limit=${dataForBackend.limit}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
  const EditElectionApi = (data) => {
    const { token, details } = data;

    delete data["token"];
    const { id, skipLimit } = details;
    delete details["id"];
    delete details["skipLimit"];

    return apis.patch(`election/editElection/${id}`, details, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const DeleteElectionApi = (data) => {
    const { token, details } = data;
    delete data["token"];

    return apis.delete(
      `election/deleteElection/${details}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  // crud election ends

  // crd project start

  const GetProjectsApi = (data) => {
    const { categoryId, skip, limit, token } = data;
    return apis.get(
      `project/allUserProjects`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const CreateProjectApi = (data) => {
    const { token, details } = data;
    delete data["token"];
    const formData = new FormData();
    formData.append("name", details.name);
    details.description !== "" &&
      formData.append("description", details.description);
    formData.append("categoryId", details.categoryId);
    formData.append("file", details.file);
    details.youtubeVideoLink !== "" &&
      formData.append("youtubeVideoLink", details.youtubeVideoLink);
    formData.append("bannerImage", details.bannerImage);
    return apis.post(`project/create`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const EditProjectApi = (data) => {
    const { details, token } = data;
    const { id } = details;
    delete details["id"];
    return apis.patch(`project/editProject/${id}`, details, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const DeleteProjectApi = (data) => {
    const { details, token } = data;

    return apis.delete(
      `project/deleteProject/${details.projectId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const UpdateProjectDocument = (data) => {
    const { details, token } = data;
    const { id } = details;
    delete details["id"];
    const formData = new FormData();
    formData.append("file", details.file);
    return apis.patch(`project/uploadProjectDoc/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  // crud project ends

  const UpdateProjectBannerImage = (data) => {
    const { details, token } = data;
    const { id } = details;
    delete details["id"];
    const formData = new FormData();
    formData.append("bannerImage", details.bannerImage);
    return apis.patch(`project/uploadBannerImage/${id}`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const GetAllLiveProjects = () => {
    return apis.get(`project/allLiveProjects`);
  };

  const PublishProject = (data) => {
    const { token, details } = data;
    const { projectId } = details;
    delete details["projectId"];
    return apis.patch(`project/publishProject/${projectId}`, details, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const PublishToDraft = (data) => {
    const { token, details } = data;
    const { projectId } = details;
    return apis.patch(
      `project/draftProject/${projectId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const JoinElection = (data) => {
    const { details, token } = data;
    const { projectId } = details;
    delete details["projectId"];
    delete data["token"];
    return apis.patch(`project/joinElection/${projectId}`, details, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const VoteProject = (data) => {
    const { details, token } = data;
    return apis.patch(
      `project/voteProject/${details.projectId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const AMAElectionCreation = (data) => {
    const { token, details } = data;
    const { electionId } = details;
    delete details["electionId"];
    delete details["skipLimit"];

    return apis.patch(`election/createAMA/${electionId}`, details, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const EditProfileInput = (data) => {
    const { details, token } = data;
    return apis.patch(`auth/changeUserDetails`, details, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const EditProfileImage = (data) => {
    const { details, token } = data;

    const formData = new FormData();
    formData.append("file", details.file);
    return apis.patch(`auth/changeProfilePic`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const GetPublicCompetetion = (data) => {
    return apis.get(`election/getPublicElections`, data);
  };

  const GetElectionGif = (data) => {
    const { electionId } = data;
    return apis.patch(`election/getElectionGIF/${electionId}`);
  };

  const GetTop10Projects = (data) => {
    return apis.get(`category/topTwoProjectsOnEachCategory`);
  };

  const ElectionTop2Projects = (data) => {
    const { details, token } = data;

    return apis.get(
      `election/getTopTwoProjectsOfElection/${details.electionId}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const GetProjectsForSearchPage = () => {
    return apis.get(`project/allLiveAndTrendingProjectsBasedOnCategory`);
  };

  const GetAllPlatformProjectsForAdmin = (data) => {
    const { details, token } = data;

    let link = "";
    if (details.skip !== undefined) link += `skip=${details.skip}&`;
    if (details.limit !== undefined) link += `limit=${details.limit}&`;

    return apis.get(
      `project/allPlatformProjects?${link}projectType=${details.projectType}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const GetAllPlatformUsersForAdmin = (data) => {
    const { details, token } = data;

    let link = "";
    if (details.skip !== undefined) link += `skip=${details.skip}&`;
    if (details.limit !== undefined) link += `limit=${details.limit}&`;
    return apis.get(
      `user/allPlatformUsers?${link}userType=${details.userType}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const MyParticipatedElections = (data) => {
    const { token } = data;
    return apis.get(
      `user/getUserParticipatedElections`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const ChangeUserStatusByAdminApi = (data) => {
    const { details, token } = data;
    const { userId } = details;
    delete details["userId"];

    return apis.patch(`user/changeUserPlatformStatus/${userId}`, details, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const ChangeProjectStatusByAdminApi = (data) => {
    const { details, token } = data;
    const { projectId } = details;
    delete details["projectId"];

    return apis.patch(
      `project/changeUserPlatformStatus/${projectId}`,
      details,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const GetAllReportsForAdmin = (data) => {
    const { details, token } = data;
    let link = "";
    if (details.skip !== undefined) link += `skip=${details.skip}&`;
    if (details.limit !== undefined) link += `limit=${details.limit}&`;
    return apis.get(
      `report/allReports?${link}type=${details.type}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const DeleteReportByAdmin = (data) => {
    const { details, token } = data;
    return apis.delete(
      `/report/deleteReport/${details.id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const CreateAReport = (data) => {
    const { details, token } = data;
    delete data["token"];

    return apis.post(`report/putReport`, details, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const manageBanner = (data) => {
    const { details, token } = data;

    let formData = new FormData();
    if (details.headerImage !== undefined) {
      formData.append("headerImage", details.headerImage);
    }
    if (details.footerImage !== undefined) {
      formData.append("footerImage", details.footerImage);
    }

    return apis.patch(`platformBanner/changePlatformBanner/1`, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const Getbanner = () => {
    return apis.get(`platformBanner/getPlatformBanner`);
  };
  const GlobalSearch = (data) => {
    const { details, token } = data;
    let link = "";

    if (details.skip) {
      link += `skip=${details.skip}&`;
    }
    if (details.limit) {
      link += `limit=${details.limit}&`;
    }
    if (details.searchPhase) {
      link += `searchPhase=${details.searchPhase}`;
    }
    if (details.categoryId) {
      link += `categoryId=${details.categoryId}`;
    }
    return apis.get(
      `/user/globalSearch?${link}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const SetBalanceLimitByAdmin = (data) => {
    const { details, token } = data;
    return apis.patch(`platformRules/updatePlatformMinimumBalance/1`, details, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const GetHomePageData = (data) => {
    const { skip, limit } = data;
    return apis.get(`/home/projectsAndCreators?skip=${skip}&limit=${limit}`);
  };

  const GetSearchResult = ({ skip, limit, name, categoryId, type }) => {
    if (name !== "" && type === "project") {
      return apis.get(
        `home/getPlatformSearchResult?skip=${skip}&limit=${limit}&${
          name !== "" && `name=${name}&`
        }categoryId=${categoryId}&type=${type}`
      );
    } else if (type === "project") {
      console.log("project api", categoryId);

      return apis.get(
        `home/getPlatformSearchResult?skip=${skip}&limit=${limit}&type=${type}&categoryId=${categoryId}`
      );
    } else {
      return apis.get(
        `home/getPlatformSearchResult?skip=${skip}&limit=${limit}&type=${type}&${
          name !== "" && `name=${name}&`
        }`
      );
    }
  };

  const GetSearchVersion2 = ({ skip, limit, name, categoryId, type }) => {
    let link = "";
    if (name !== "") link += `&name=${name}`;
    if (categoryId !== "") link += `&categoryId=${categoryId}`;
    if (link !== "") {
      console.log("link if", link);
      return apis.get(
        `home/getPlatformSearchResult?skip=${skip}&limit=${limit}&type=${type}${link}`
      );
    } else {
      console.log("else");
      return apis.get(
        `home/getPlatformSearchResult?skip=${skip}&limit=${limit}&type=${type}`
      );
    }
  };

  const GetSingleProjectDetails = (data) => {
    const { id } = data;

    return apis.get(`/project/getProjectDetails/${id}`);
  };

  const GetSingleUserDetails = (data) => {
    const { id } = data;
    return apis.get(`/user/getUserDetails/${id}`);
  };
  const ProjectToSpotlight = (data) => {
    const { details, token } = data;
    const { id } = details;
    delete details["id"];
    return apis.patch(`project/addOrRemoveToSpotlight/${id}`, details, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const UsersToSpotlight = (data) => {
    const { details, token } = data;
    const { id } = details;
    delete details["id"];
    return apis.patch(`user/addOrRemoveToSpotlight/${id}`, details, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const GetSingleElectionDetails = (data) => {
    const { id } = data;
    return apis.get(`/election/getElectionDetails/${id}`);
  };

  const CheckWalletBalance = (token) => {
    return apis.get(
      `/platformRules/checkWalletBalance`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
  const GetCreateTokenBalanceForWallet = (data) => {
    return apis.get(
      `/platformRules/getCreateTokenWalletBalance`,
      {},
      {
        headers: {
          Authorization: `Bearer ${data}`,
        },
      }
    );
  };

  const GetCreateTokenExchangeRateInBusd = (data) => {
    const { token } = data;
    return apis.get(
      `/platformRules/exchangeRateOfCreateTokenInBUSD`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const GetAllCreatorAndProjectsForHome = () => {
    return apis.get(`home/getAllProjectsAndCreatorCount`);
  };

  const AllPowerVotesOwnedByUser = ({ dataForBackend, token }) => {
    // console.log("ddddd", data);
    if (dataForBackend !== "") {
      return apis.get(
        `/user/allPowerVotes?skip=${dataForBackend.skip}&limit=${dataForBackend.limit}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } else {
      return apis.get(
        `user/ownedPowerVotes`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }
  };

  const BuyPowerVotes = (data, token) => {
    // console.log("data for api", data);
    return apis.patch(`/user/buyPowerVotesUsingETH`, data.data, {
      headers: {
        Authorization: `Bearer ${data.token}`,
      },
    });
  };

  const PowerVotesByPlatform = (token) => {
    return apis.get(
      `/powerVote/allPackages`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const getConvertedOfEthInWei = (data) => {
    return apis.get(
      `/platformRules/getEquivalantETHValueForUSD?usdInWEI=${data}`
    );
  };
  const getConvertedUsdFromEth = (data) => {
    return apis.get(
      `platformRules/getEquivalantUSDValueForETH?ethInWei=${data}`
    );
  };
  const getConvertedUSDForBnb = (data) => {
    return apis.get(
      `/platformRules/getEquivalantUSDValueForBNB?bnbInWei=${data}`
    );
  };
  const VoteUsingPowerVote = (data) => {
    const { token, dataForBackend } = data;
    const { eventId } = dataForBackend;
    delete dataForBackend["eventId"];
    return apis.patch(
      `/project/voteUsingPowerVote/${eventId}`,
      dataForBackend,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
  const BuyVoteUsingToken = (data) => {
    const { dataForBackend, token } = data;
    return apis.patch(`/user/buyPowerVotesUsingERC20`, dataForBackend, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  const DonateProjectApiUsingEth = (data) => {
    const { dataForBackend, token } = data;
    const { projectId, loggedIn } = dataForBackend;
    delete dataForBackend["projectId"];
    delete dataForBackend["loggedIn"];
    if (loggedIn === false) {
      return apis.patch(`/project/donateUsingETH/${projectId}`, dataForBackend);
    } else {
      return apis.patch(
        `/project/donateUsingETHForLoggedInUser/${projectId}`,
        dataForBackend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }
  };

  const DonateProjectUsingToken = (data) => {
    // console.log("data", data);
    const { dataForBackend, token } = data;
    const { projectId, loggedIn } = dataForBackend;
    delete dataForBackend["projectId"];
    delete dataForBackend["loggedIn"];
    if (loggedIn === false) {
      return apis.patch(
        `/project/donateUsingERC20/${projectId}`,
        dataForBackend
      );
    } else {
      return apis.patch(
        `/project/donateUsingERC20ForLoggedInUser/${projectId}`,
        dataForBackend,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
    }
  };

  const GetDonationTableForAdmin = (data) => {
    const { dataForBackend, token } = data;
    return apis.get(
      `/transaction/allCreatorDonations?skip=${dataForBackend.skip}&limit=${dataForBackend.limit}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
  const GetDonationTableForCreator = (data) => {
    const { dataForBackend, token } = data;
    return apis.get(
      `/transaction/allProjectTransactions?skip=${dataForBackend.skip}&limit=${dataForBackend.limit}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
  const GetPowerVoteTableAdmin = (data) => {
    const { dataForBackend, token } = data;
    return apis.get(
      `/user/allPlatformUserPowerVotes?skip=${dataForBackend.skip}&limit=${dataForBackend.limit}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  /////// onboarding phase 2 ///////////////////
  const SignUpUsingEmail = (data) => {
    return apis.post(`/auth/signUpUsingEmail`, data);
  };

  const ForgotPasswordRequest = (data) => {
    return apis.post(`/auth/request-reset-password`, data);
  };
  const SignInUsingEmail = (data) => {
    return apis.post(`/auth/signInUsingEmail`, data);
  };
  const SetNewPassword = (data) => {
    const { email, token } = data;
    delete data["email"];
    delete data["token"];
    return apis.post(
      `/auth/set-new-password?email=${email}&token=${token}`,
      data
    );
  };
  const ReverifyEmail = (data) => {
    return apis.post(
      `/auth/verify-email`,
      {},
      {
        headers: {
          Authorization: `Bearer ${data}`,
        },
      }
    );
  };
  const VerifyEmail = () => {
    return apis.get(`/auth/confirm-email?email&token`);
  };
  const GoogleLogin = (data) => {
    return apis.post(`/auth/signInUsingGoogle`, data);
  };
  const GoogleSignup = (data) => {
    // console.log("data", data);
    return apis.post(`/auth/signUpUsingGoogle`, data);
  };
  // api listing ends

  const GetAllGenre = () => {
    return apis.get(`/project/allGenre`);
  };
  const GetTop50Projects = (data) => {
    return apis.get(`/category/topFiftyProjectsOnCategory/${data.id}`);
  };
  const GetTop50Creators = () => {
    console.log('called')
    return apis.get(`user/top50CreatorsBasedOnVotes`);
  };

  const GetDonationReceived = (data) => {
    const { dataForBackend, token } = data;
    return apis.get(
      `/user/allProjectDonations?skip=${dataForBackend.skip}&limit=${dataForBackend.limit}&relations=["project"]`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
  const TotalDonationReceived = (data) => {
    return apis.get(
      `/user/allProjectTotalDonationInUsd`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const DonationUserLoggedIn = (data) => {
    const { dataForBackend, token } = data;
    return apis.get(
      `/user/allUserDonationsForLoggedInUser?skip=${dataForBackend.skip}&limit=${dataForBackend.limit}&relations=["project"]`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const GetAllNotifications = (data) => {
    const { dataForBackend, token } = data;
    return apis.get(
      `/notification/getNotifications?skip=${dataForBackend.skip}&limit=${dataForBackend.limit}`,
      // &relations=["transaction","userPowerVotes"]
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
  const GetAllUnreadNotificationCount = (data) => {
    return apis.get(
      `/notification/getAllUnreadNotifications`,
      {},
      {
        headers: {
          Authorization: `Bearer ${data}`,
        },
      }
    );
  };

  const MarkSingleNotificationRead = (data) => {
    const { dataForBackend, token } = data;
    return apis.patch(
      `/notification/markRead/${dataForBackend}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const MarkAllNotificationRead = (data) => {
    return apis.patch(
      `/notification/markReadAll`,
      {},
      {
        headers: {
          Authorization: `Bearer ${data}`,
        },
      }
    );
  };
  const CreatePaymentForBitcoin = (data) => {
    const { dataForBackend, token } = data;
    return apis.post(
      `/bitcoinPayment/createBitCoinAddressForPayment`,
      dataForBackend,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };

  const NewsLetterRequest = (data) => {
    return apis.post(`/newsletter/addEmail`, data);
  };

  const GetRewardForLoggedInUser = (data) => {
    console.log("data with backend", data);
    return apis.get(
      `user/allTokenRewardsForLoggedInUser`,
      data.dataForBackend,
      {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }
    );
  };

  const GetRewardWithAddress = (data) => {
    console.log("data", data);
    return apis.get(`user/allTokenRewardsForPublicUser`, data);
  };

  const GetmyDonationWallet = (data) => {
    return apis.get(
      `user/allUserDonationPublic?skip${data.skip}=&limit=${data.limit}&relations=[%22project%22]&walletAddress=${data.walletAddress}`
    );
  };

  const DonateBitCoinForLoggedInUser = (data) => {
    const { dataForBackend, token } = data;
    const { projectId } = dataForBackend;
    delete dataForBackend["projectId"];
    return apis.post(
      `/bitcoinPayment/createBitCoinAddressForDonationPaymentForLoggedInUser/${projectId}`,
      dataForBackend,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  };
  const voteCreator = (data, token) => {
    return apis.post(
      `user/voteCreator/${data.id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }
    );
  };
  const voteCreatorcheck = (data, token) => {
    return apis.get(
      `user/checkVoteGivenToTheCreator/${data.id}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      }
    );
  };
  const DonateBitCoinForNotLoggedInUser = (data) => {
    const { projectId } = data;
    delete data["projectId"];
    return apis.post(
      `/bitcoinPayment/createBitCoinAddressForDonationPayment/${projectId}`,
      data
    );
  };

  const StripeCheckout = (data) => {
    return apis.post(`stripe/createCheckoutSession`, data);
  };

  return {
    StripeCheckout,
    DonateBitCoinForLoggedInUser,
    DonateBitCoinForNotLoggedInUser,
    GetmyDonationWallet,
    GetRewardForLoggedInUser,
    GetRewardWithAddress,
    CreatePaymentForBitcoin,
    GetAllNotifications,
    GetAllUnreadNotificationCount,
    MarkSingleNotificationRead,
    MarkAllNotificationRead,
    DonationUserLoggedIn,
    TotalDonationReceived,
    GetDonationReceived,
    getConvertedUsdFromEth,
    getConvertedUSDForBnb,
    loginApi,
    createUserApi,
    GetCategoriesApi,
    GetElectionApi,
    CreatElectionApi,
    EditElectionApi,
    DeleteElectionApi,
    GetProjectsApi,
    CreateProjectApi,
    EditProjectApi,
    DeleteProjectApi,
    UpdateProjectDocument,
    UpdateProjectBannerImage,
    GetAllLiveProjects,
    PublishProject,
    JoinElection,
    VoteProject,
    AMAElectionCreation,
    EditProfileInput,
    EditProfileImage,
    GetPublicCompetetion,
    GetElectionGif,
    GetTop10Projects,
    PublishToDraft,
    ElectionTop2Projects,
    GetProjectsForSearchPage,
    GetAllPlatformProjectsForAdmin,
    GetAllPlatformUsersForAdmin,
    MyParticipatedElections,
    ChangeUserStatusByAdminApi,
    GetAllReportsForAdmin,
    ChangeProjectStatusByAdminApi,
    DeleteReportByAdmin,
    CreateAReport,
    manageBanner,
    Getbanner,
    GlobalSearch,
    SetBalanceLimitByAdmin,
    GetHomePageData,
    GetSingleProjectDetails,
    GetSingleUserDetails,
    ProjectToSpotlight,
    UsersToSpotlight,
    GetSingleElectionDetails,
    CheckWalletBalance,
    GetCreateTokenBalanceForWallet,
    GetCreateTokenExchangeRateInBusd,
    GetAllCreatorAndProjectsForHome,
    BuyPowerVotes,
    AllPowerVotesOwnedByUser,
    PowerVotesByPlatform,
    getConvertedOfEthInWei,
    VoteUsingPowerVote,
    BuyVoteUsingToken,
    DonateProjectApiUsingEth,
    DonateProjectUsingToken,
    GetDonationTableForAdmin,
    GetDonationTableForCreator,
    GetPowerVoteTableAdmin,
    SignUpUsingEmail,
    ForgotPasswordRequest,
    SignInUsingEmail,
    SetNewPassword,
    ReverifyEmail,
    VerifyEmail,
    GoogleLogin,
    GoogleSignup,
    GetAllGenre,
    GetTop50Projects,
    GetSearchResult,
    NewsLetterRequest,
    GetSearchVersion2,
    VotingHistoryApplication,
    voteCreator,
    voteCreatorcheck,
    GetTop50Creators,
  };
};

export default {
  create,
};
