import React, { useState, useEffect } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as CloseIcon } from "@images/close-icon.svg";
import { connect } from "react-redux";
import Loader from "@components/loader/Loader";
import { clearProjectErrorFromBackend } from "@store/project_redux";
import * as _ from "lodash";
import "@components/confirm-vote-popup/ConfirmVotePopupStyle.scss";
import { useParams } from "react-router-dom";

const ConfirmVotePopup = ({
  show,
  setShowModal,
  setShowSuccess,
  detailsToShow,
  voteLoading,
  VoteProjectAsync,
  voteProjectBackendError,
  clearBackendError,
  checkMinimalBalLoading,
  checkMinimalBalError,
  setShowMinBal,
  userDetails,
  creator,
  votescreator,
}) => {
  const [details, setDetails] = useState();
  const [makeDisableDetails, setMakeDisableDetails] = useState();
  const { id } = useParams();

  useEffect(() => {
    if (detailsToShow !== undefined) {
      setDetails(detailsToShow);
      clearBackendError();
    }
  }, [detailsToShow]);

  const handleYes = () => {
    const data = {
      projectId: details.id,
      callBack: () => {
        setShowSuccess(true);
        setShowModal(false);
      },
      openMinBalPopup: () => {
        setShowMinBal(true);
        setShowModal(false);
      },
    };
    if (creator) {
      votescreator(data);
    } else {
      VoteProjectAsync(data);
    }
  };
  const makeButtonDisabled = () => {
    let makeMeTrueORFalse;
    let value = [];
    // console.log("details in comp-details age", details);
    if (
      details !== undefined &&
      details.votes !== undefined &&
      details.votes.length > 0 &&
      userDetails !== undefined
    ) {
      value = details.votes.filter((item) => {
        if (
          item.votedProjectId === details.id &&
          item.electionId === id &&
          userDetails.id === item.userId
        ) {
          return true;
        } else {
          return false;
        }
      });
      return value;
    }

    if (value.length > 0) {
      makeMeTrueORFalse = true;
      return makeMeTrueORFalse;
    } else {
      return (makeMeTrueORFalse = false);
    }
  };

  return (
    <Modal
      className="ConfirmVotePopup"
      show={show}
      onHide={() => {
        clearBackendError();
        setShowModal(false);
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon
          className="closebtn"
          onClick={() => {
            clearBackendError();
            setShowModal(false);
          }}
        />
        <Container>
          <Row>
            <Col lg={12}>
              {(voteLoading || checkMinimalBalLoading) && <Loader />}
              {!creator && (
                <div className="heading-text">Confirm Your Vote For </div>
              )}
              {creator && (
                <div className="nav-text mb-3">Confirm Your Vote For </div>
              )}

              {creator && <div className="heading-text">{details?.name} </div>}
              {!creator && (
                <div className="detail-list">
                  <ul>
                    <div className="title">Competition Category</div>
                    <li>
                      <div className="name-text">
                        {details !== undefined &&
                          details.category !== undefined &&
                          details.category.name}
                      </div>
                    </li>
                    <div className="title">Project Name</div>
                    <li>
                      <div className="name-text">
                        {details !== undefined &&
                          details.name !== undefined &&
                          details.name}
                      </div>
                    </li>
                    <div className="title">Creator Name</div>
                    <li>
                      <div className="name-text">
                        {details !== undefined &&
                          details.user !== undefined &&
                          details.user.name !== undefined &&
                          details.user.name}
                      </div>
                    </li>
                  </ul>
                </div>
              )}
            </Col>
          </Row>
          <Row>
            {/* {makeButtonDisabled() === false ? ( */}
            <Col xs={12} className="btn-div">
              <div className="confirm">
                <CustomButton
                  type="submit"
                  name="Confirm"
                  filled={true}
                  primary={true}
                  // disabled={console.log('makeeeeeee',makeButtonDisabled())}
                  onClick={() => {
                    handleYes();
                  }}
                />
              </div>
              <div className="cancel">
                <CustomButton
                  classname="cancelbtn"
                  type="submit"
                  name="Cancel"
                  filled={true}
                  primary={true}
                  onClick={() => {
                    // setDetails();
                    clearBackendError();
                    setShowModal(false);
                  }}
                />
              </div>
            </Col>
            {/* ) : (
              <Row>
                <Col>
                  <div className="error-text">
                    You have already voted this project
                  </div>
                </Col>
              </Row>
            )} */}
          </Row>
          <Row>
            <Col>
              <div className="error-text">
                {voteProjectBackendError !== undefined
                  ? voteProjectBackendError
                  : ""}
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({ project = {}, app = {}, user = {} }) => {
  const voteLoading = _.get(project, "voteProjectLoading", false);
  const voteProjectBackendError = _.get(project, "voteProjectError", undefined);
  const checkMinimalBalLoading = _.get(
    app,
    "checkMinimalBalanceLoading",
    false
  );
  const checkMinimalBalError = _.get(
    app,
    "checkMinimalBalanceError",
    undefined
  );
  const userDetails = _.get(user, "userInfo", undefined);

  return {
    voteLoading,
    voteProjectBackendError,
    checkMinimalBalLoading,
    checkMinimalBalError,
    userDetails,
  };
};
const mapDispatchToProps = (dispatch) => ({
  VoteProjectAsync: (data) =>
    dispatch({ type: "voteProjectSagaCalled", payload: data }),
  votescreator: (data) =>
    dispatch({ type: "votescreatorSagaCalled", payload: data }),
  clearBackendError: () => dispatch(clearProjectErrorFromBackend()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ConfirmVotePopup));
