import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Table,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { connect } from "react-redux";
import ReactPaginate from "react-paginate";
import { ToastContainer, toast } from "react-toastify";
import NavBar from "src/components/navbar/NavBar";
import SearchBox from "src/components/search-box/SearchBox";
import TableSearch from "src/components/table-search/TableSearch";
import { ReactComponent as PreviousIcon } from "@images/previous.svg";
import { ReactComponent as NextIcon } from "@images/next.svg";
import { web3 } from "src/WalletProvider/Web3";
import moment from "moment";
import { clearReduxOnMount } from "@store/powerVote_redux";
import Loader from "@components/loader/Loader";
import { ReactComponent as NoData } from "@images/nothing_found.svg";
import "@features/admin-votes/AdminVotesStyle.scss";

function AdminVotes({
  userInformation,
  donationAdmin,
  donationUser,
  getAdminDonationTable,
  getUserDonationTable,
  donationAdminLoading,
  dontationUserLoading,
  donationAdminError,
  donationUserError,
  clearErrorState,
}) {
  let [pages, setpages] = useState(0);
  let [countNumber, setCountNumber] = useState(40);
  let [limit, setlimit] = useState(10);
  let [skip, setskip] = useState(0);
  const [copied, setcopied] = useState(false);
  let BN = web3.utils.BN;
  useEffect(() => {
    clearErrorState();
  }, []);
  useEffect(() => {
    // console.log("user", userInformation);
    if (userInformation !== undefined && userInformation.role === "admin") {
      getAdminDonationTable({
        skip: 0,
        limit: 10,
      });
    }
    // else {
    //   getUserDonationTable({
    //     skip: 0,
    //     limit: 10,
    //   });
    // }
  }, [userInformation]);

  return (
    <>
      <NavBar pageName="Votes" role="admin" />
      <div id="AdminVotes">
        {donationAdminLoading && <Loader />}
        {donationAdmin !== undefined && donationAdmin.result.length !== 0 ? (
          <Container>
            <Row>
              <Col>
                <Table responsive="sm" className="transfertbl" borderless>
                  <thead className="">
                    <tr>
                      <th className="trtbleheading ">
                        <span className="heading">Sr No</span>
                      </th>
                      <th className="trtbleheading">
                        <span className="heading">User Name</span>
                      </th>
                      <th className="trtbleheading category">
                        <span className="heading">Amount</span>
                      </th>
                      <th className="trtbleheading">
                        <span className="heading"> User Wallet Address</span>
                      </th>
                      <th className="trtbleheading vote-quantity">
                        <span className="heading"> Power Vote Quantity</span>
                      </th>

                      <th className="trtbleheading">
                        <span className="heading">Purchased On</span>
                      </th>
                    </tr>
                  </thead>
                  {donationAdmin !== undefined &&
                    donationAdmin.result.length > 0 && (
                      <tbody>
                        {donationAdmin.result.map((item) => (
                          <tr>
                            <td className="amt">
                              <div className="innerTd idtr ">
                                <span className="id idtxt">{item.id}</span>
                              </div>
                            </td>
                            {item.userDetails !== undefined &&
                            item.userDetails.name !== null ? (
                              <td className="amt ">
                                <OverlayTrigger
                                  placement={"bottom"}
                                  overlay={
                                    <Tooltip id="tooltip-btn">
                                      {item.userDetails.name}
                                    </Tooltip>
                                  }
                                >
                                  {item.userDetails.name.length > 20 ? (
                                    <div className="innerTd">{`${item.userDetails.name.substring(
                                      0,
                                      20
                                    )}...`}</div>
                                  ) : (
                                    <div className="innerTd">
                                      {item.userDetails.name}
                                    </div>
                                  )}
                                </OverlayTrigger>
                              </td>
                            ) : (
                              <td className="amt ">
                                <div className="innerTd vote-quantity">-</div>
                              </td>
                            )}
                            {item &&
                            item.tokenName === "ETH" &&
                            item.amount !== null ? (
                              <td className="amt ">
                                <div className="innerTd">
                                  {Number.parseFloat(
                                    web3.utils.fromWei(item.amount, "ether")
                                  ).toFixed(4)}{" "}
                                  ETH
                                </div>
                              </td>
                            ) : (
                              item.amount !== null && (
                                <td className="amt ">
                                  <div className="innerTd">
                                    ${" "}
                                    {new BN(item.amount)
                                      .div(new BN(Math.pow(10, 6)))
                                      .toString()}
                                  </div>
                                </td>
                              )
                            )}
                            {item.userDetails !== undefined &&
                            item.userDetails.walletAddress !== null ? (
                              <td className="amt">
                                <div
                                  className="address-div"
                                  onClick={() => {
                                    setcopied(item.userDetails.walletAddress);
                                    navigator.clipboard.writeText(
                                      item.userDetails.walletAddress
                                    );
                                    toast(
                                      "User wallet address copied successfully!",
                                      {
                                        position: "top-right",
                                        autoClose: 5000,
                                        hideProgressBar: false,
                                        closeOnClick: true,
                                        pauseOnHover: false,
                                        draggable: true,
                                        progress: undefined,
                                      }
                                    );
                                  }}
                                >
                                  <OverlayTrigger
                                    placement={"bottom"}
                                    overlay={
                                      <Tooltip id="tooltip-btn">
                                        {item !== undefined &&
                                          item.userDetails !== undefined &&
                                          item.userDetails.walletAddress !==
                                            null &&
                                          `${item.userDetails.walletAddress.substring(
                                            0,
                                            10
                                          )}....${item.userDetails.walletAddress.substring(
                                            item.userDetails.walletAddress
                                              .length - 10,
                                            item.userDetails.walletAddress
                                              .length
                                          )}`}
                                      </Tooltip>
                                    }
                                  >
                                    {item.userDetails.walletAddress.length >
                                    20 ? (
                                      <div className="innerTd">{`${item.userDetails.walletAddress.substring(
                                        0,
                                        20
                                      )}...`}</div>
                                    ) : (
                                      <div className="innerTd">
                                        {item.userDetails.walletAddress}
                                      </div>
                                    )}
                                  </OverlayTrigger>
                                </div>
                              </td>
                            ) : (
                              <td className="amt ">
                                <div className="innerTd vote-quantity">-</div>
                              </td>
                            )}
                            {item.userPowerVotes !== undefined && (
                              <td className="amt ">
                                <div className="innerTd vote-quantity">
                                  {item.userPowerVotes.length}{" "}
                                </div>
                              </td>
                            )}

                            {item.createdOn !== null && (
                              <td className="amt ">
                                <div className="innerTd">
                                  {moment(item.createdOn)
                                    .local()
                                    .format("MM/DD/YYYY")}{" "}
                                </div>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    )}
                </Table>
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />

                {donationAdmin !== undefined && donationAdmin.count > 10 && (
                  <div className="paginationsection">
                    <div className="paginationtxtholer">
                      <span className="noofpagetxt">
                        Showing {pages + 1} From{" "}
                        {Math.ceil(donationAdmin.count / limit)}
                      </span>
                    </div>
                    <div className="paginationallignment">
                      <ReactPaginate
                        previousLabel={
                          <PreviousIcon className="paginationicon" />
                        }
                        nextLabel={<NextIcon className="paginationicon" />}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={donationAdmin.count / limit}
                        // pageCount={2000}
                        forcePage={pages}
                        // marginPagesDisplayed={1}
                        pageRangeDisplayed={2}
                        onPageChange={(e) => {
                          setskip(e.selected * limit);
                          getAdminDonationTable({
                            skip: e.selected * limit,
                            limit,
                          });
                          setpages(e.selected);
                        }}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                      />
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col>
                {donationAdminError !== undefined && donationAdminError}
              </Col>
            </Row>
          </Container>
        ) : (
          <Container>
            <Row>
              <Col>
                <div>
                  <NoData className="no-data" />
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    </>
  );
}
const mapStateToProps = ({ user = {}, powerVote = {} }) => {
  const userInformation = _.get(user, "userInfo", undefined);
  const donationAdmin = _.get(powerVote, "donationTablePowerVote", undefined);
  const donationUser = _.get(powerVote, "donationTableUser", undefined);
  const donationAdminLoading = _.get(
    powerVote,
    "donationTablePowerVoteLoading",
    false
  );
  const dontationUserLoading = _.get(
    powerVote,
    "donationTableUserLoading",
    false
  );
  const donationAdminError = _.get(powerVote, "donationTableError", undefined);
  const donationUserError = _.get(
    powerVote,
    "donationTableUserError",
    undefined
  );

  return {
    userInformation,
    donationAdmin,
    donationUser,
    donationAdminLoading,
    dontationUserLoading,
    donationAdminError,
    donationUserError,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getAdminDonationTable: (data) =>
    dispatch({ type: "donationTablePowerVoteSagaCalled", payload: data }),
  getUserDonationTable: (data) =>
    dispatch({ type: "donationUserTableSagaCalled", payload: data }),
  clearErrorState: () => dispatch(clearReduxOnMount()),
});
export default connect(mapStateToProps, mapDispatchToProps)(AdminVotes);
