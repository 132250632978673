import { call, put, takeLatest } from "redux-saga/effects";
import Api from "./../services/ApiCaller";

import {
  categoriesLoading,
  categoriesSuccess,
  categoriesFail,
  genreLoading,
  genreSuccess,
  genreFail,
} from "./category_redux";

export function* getCategories(api, { payload = {} }) {
  yield put(categoriesLoading(true));
  try {
    const data = yield call(Api.callServer, api.GetCategoriesApi, {}, true);

    yield put(categoriesSuccess(data.result));
  } catch (e) {
    yield put(categoriesFail(e.error));
  }
}

export function* getGenre(api, { payload = {} }) {
  yield put(genreLoading());
  try {
    const data = yield call(Api.callServer, api.GetAllGenre, {}, true);
    // console.log("data from genre saga", data);
    yield put(genreSuccess(data.result));
  } catch (e) {
    yield put(genreFail(e.error));
  }
}
