import React, { useState, useEffect } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { ReactComponent as CloseIcon } from "@images/close-icon.svg";
import InputField from "@components/input-field/InputField";
import CustomButton from "@components/custom-button/CustomButton";
import { connect } from 'react-redux';
import * as _ from "lodash";
import { publicProjectFailure } from "src/store/project_redux";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import Loader from "@components/loader/Loader";
import { clearError } from '@store/user_redux';
import "@components/report-popup/ReportPopupStyle.scss";


const ReportPopup = ({
  show,
  setShowModal,
  type,
  reportAProjectOrFeatureAsync,
  createAReportLoading,
  createAReportError,
  setShowSuccess,
  passProjectId,
  clearBackendError

}) => {


  const navigate = useNavigate();
  const [desc, setDesc] = useState("");
  const [error, setError] = useState("");
  useEffect(() => {
    if (desc !== "" && desc.length > 1) {
      setError("")
    }
  }, [desc])
  const ReportCalled = () => {
    if (desc === "" && desc.length < 1) {
      setError("*Please add a comment less than 500 letters")
    } else {

      if(type === "User"){
        reportAProjectOrFeatureAsync({
          comment: desc,
          creatorId: passProjectId,
          callBack: () => {
            setDesc("");
            setError("");
          
            clearBackendError()
            setShowSuccess(true)
            setShowModal(false);
          }
        })
      }else{
        reportAProjectOrFeatureAsync({
          comment: desc,
          projectId: passProjectId,
          callBack: () => {
            setDesc("");
            setError("");
          
            clearBackendError()
            setShowSuccess(true)
            setShowModal(false);
          }
        })
      }
     
    }
  }



  return (
    <Modal
      className="ReportPopup"
      show={show}
      onHide={() => {
        setDesc("");
        setShowModal(false);
        clearBackendError();
        setError("")
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon
          className="closebtn"
          onClick={() => {
            setDesc("");
            setError("");
            clearBackendError();
            setShowModal(false);
          }}
        />
        {createAReportLoading && <Loader />}
        <Container>
          <Row>
            <Col lg={12}>

              <div className="heading-text">
                Report {type !== undefined && type}
              </div>
            </Col>
            <Col lg={6}>
              <div className="second-heading">
                Reason to report
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="input-div">


                <textarea
                  placeholder="Add a comment less then 500 letters"
                  className="form-control"
                  onChange={(e) => setDesc(e.target.value)}
                  value={desc}
                  maxLength={500}

                ></textarea>

              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} sm={12} xs={12} className="btn-div">
              <div className="confirm">
                <CustomButton
                  type="button"
                  name="Report"
                  filled={true}
                  primary={true}
                  onClick={() => {

                    ReportCalled()
                  }}
                />
              </div>
              <div className="cancel">
                <CustomButton
                  classname="cancelbtn"
                  type="button"
                  name="Cancel"
                  filled={true}
                  primary={true}
                  onClick={() => {

                    setDesc("");
                    setError("");
                    clearBackendError();
                    setShowModal(false)


                  }}
                />
              </div>

            </Col>
          </Row>
          <Row>
            <Col>
              <div className="error-text">
                {error && error.length > 0 && error}
                {createAReportError !== undefined && createAReportError}
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  )
}


const mapStateToProps = ({ user = {} }) => {
  const createAReportLoading = _.get(user, "reportCreationLoading", false);
  const createAReportError = _.get(user, "reportCreationError", undefined);
  return {
    createAReportLoading,
    createAReportError,
  }
}
const mapDispatchToProps = (dispatch) => ({
  reportAProjectOrFeatureAsync: (data) => dispatch({ type: "reportingAProjectOrUserSagaCalled", payload: data }),
  clearBackendError: () => dispatch(clearError())
})

export default connect(mapStateToProps, mapDispatchToProps)(ReportPopup)