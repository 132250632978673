import React, { useState, useEffect } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as CloseIcon } from "@images/close-icon.svg";
import "@components/confirm-powervote-popup/ConfirmPowerVotePopupStyle.scss";
import { connect } from "react-redux";
import { clearReduxOnMount } from "@store/powerVote_redux";
import Loader from "@components/loader/Loader";

const ConfirmPowerVotePopup = ({
  show,
  setShowModal,
  setShowSuccess,
  detailsToShow,
  votePackeageId,
  voteUsingPowerVote,
  powerVoteChosen,
  clearErrorState,
  voteUsingPowerVtError,
  voteUsnigPowerVoteLoading,
}) => {
  const [details, setDetails] = useState();
  useEffect(() => {
    console.log("divya dwived", detailsToShow);
    if (detailsToShow !== undefined) {
      setDetails(detailsToShow);
    }
  }, [detailsToShow]);
  const handleYesForVoting = () => {
    if (votePackeageId !== undefined) {
      voteUsingPowerVote({
        powerVoteId: votePackeageId,
        eventId: details.id,
        openSucess: () => {
          setShowSuccess(true);
          setShowModal(false);
        },
      });
    }
  };
  return (
    <Modal
      className="ConfirmPowerVotePopup"
      show={show}
      onHide={() => {
        // clearBackendError();
        clearErrorState();
        powerVoteChosen();
        setShowModal(false);
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon
          className="closebtn"
          onClick={() => {
            // clearBackendError();
            clearErrorState();
            powerVoteChosen();
            setShowModal(false);
          }}
        />
        {voteUsnigPowerVoteLoading && <Loader />}
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading-text">Confirm Your Power Vote For</div>
              <div className="detail-list">
                <ul>
                  <div className="title">Competition Name</div>
                  <li>
                    {
                      <div className="name-text">
                        {details !== undefined &&
                          details.category !== undefined &&
                          details.category.name}
                      </div>
                    }
                  </li>
                  <div className="title">Project Name</div>
                  <li>
                    {
                      <div className="name-text">
                        {details !== undefined && details.name}
                      </div>
                    }
                  </li>
                  <div className="title">Creator Name</div>
                  <li>
                    <div className="name-text">
                      {details !== undefined &&
                        details.user !== undefined &&
                        details.user.name}
                    </div>
                  </li>
                  {/* <div className="title">Power vote price</div>
                  <li>
                    <div className="name-text">$00.00</div>
                  </li> */}
                </ul>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="btn-div">
              <div className="confirm">
                <CustomButton
                  type="submit"
                  name="Yes"
                  filled={true}
                  primary={true}
                  onClick={() => {
                    handleYesForVoting();
                  }}
                />
              </div>
              <div className="cancel">
                <CustomButton
                  classname="cancelbtn"
                  type="submit"
                  name="Cancel"
                  filled={true}
                  primary={true}
                  onClick={() => {
                    clearErrorState();
                    powerVoteChosen();
                    setShowModal(false);
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="error-text">
                {voteUsingPowerVtError !== undefined && voteUsingPowerVtError}
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
const mapStateToProps = ({ powerVote = {} }) => {
  const voteUsingPowerVtError = _.get(
    powerVote,
    "voteUsingPowerVoteError",
    undefined
  );
  const voteUsnigPowerVoteLoading = _.get(
    powerVote,
    "voteUsingPowerVoteLoading",
    false
  );
  return {
    voteUsingPowerVtError,
    voteUsnigPowerVoteLoading,
  };
};
const mapDispatchToProps = (dispatch) => ({
  voteUsingPowerVote: (data) =>
    dispatch({ type: "voteUsingPowerVoteSagaCalled", payload: data }),
  clearErrorState: () => dispatch(clearReduxOnMount()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ConfirmPowerVotePopup));
