import { createSlice } from "@reduxjs/toolkit";

const adminslice = createSlice({
  name: "Admin",
  initialState: {
    createElectionLoading: undefined,
    createElectionError: undefined,
    listElectionLoading: undefined,
    listElectionError: undefined,
    listElection: [],
    listElectionCount: 0,
    editElectionLoading: undefined,
    editElectionError: undefined,
    deleteElectionLoading: undefined,
    deleteElectionError: undefined,
    createAMAElectionLoading: undefined,
    createAMAElectionError: undefined,
    electionTOp2ProjectLoading: undefined,
    electionTop2Projects: [],
    electionTop2ProjectsError: undefined,
    allPlatformProjectsLoading: undefined,
    allPlatformProjects: [],
    allPlatformProjectsError: undefined,
    allPlatformUserLoading: undefined,
    allPlatformUsers: [],
    allPlatformUserError: undefined,
    changeStatusOfUserLoading: undefined,
    changeStatusOfUserError: undefined,
    changeStatusOfProjectLoading: undefined,
    changeStatusOfProjectError: undefined,
    adminGetReportLoading: undefined,
    adminGetReports: [],
    adminGetReportError: undefined,
    deleteReportByAdminLoading: undefined,
    deleteReportByAdminError: undefined,
    adminBalanceSetupLoading: undefined,
    adminBalanceError: undefined,
    addUserToSpotlightLoading: undefined,
    addUserToSpotlightError: undefined,
    singleElectionDetailLoading: undefined,
    singleElectionDetail: {},
    singleElectionDetailError: undefined,
  },
  reducers: {
    createElectionTrigger: (state, action) => {
      return {
        ...state,
        createElectionLoading: true,
        createElectionError: undefined,
      };
    },
    createElectionSuccess: (state, action) => {
      return {
        ...state,
        createElectionLoading: false,
        createElectionError: undefined,
      };
    },
    createElectionFail: (state, action) => {
      return {
        ...state,
        createElectionLoading: false,
        createElectionError: action.payload,
      };
    },

    listElectionTrigger: (state, action) => {
      return {
        ...state,
        listElectionLoading: true,
        listElection: [],
        listElectionCount: 0,
        listElectionError: undefined,
      };
    },
    listElectionSuccess: (state, action) => {
      return {
        ...state,
        listElectionLoading: false,
        listElection: action.payload.elections,
        listElectionCount: action.payload.count,
        listElectionError: undefined,
      };
    },
    listElectionFail: (state, action) => {
      return {
        ...state,
        listElectionLoading: false,
        listElection: [],
        listElectionCount: 0,
        listElectionError: action.payload,
      };
    },

    editElectionTrigger: (state, action) => {
      return {
        ...state,
        editElectionLoading: true,
        editElectionError: undefined,
      };
    },
    editElectionSuccess: (state, action) => {
      return {
        ...state,
        editElectionLoading: false,
        editElectionError: undefined,
      };
    },
    editElectionFail: (state, action) => {
      return {
        ...state,
        editElectionLoading: false,
        editElectionError: action.payload,
      };
    },

    deleteElectionTrigger: (state, action) => {
      return {
        ...state,
        deleteElectionLoading: true,
        deleteElectionError: undefined,
      };
    },
    deleteElectionSuccess: (state, action) => {
      return {
        ...state,
        deleteElectionLoading: false,
        deleteElectionError: undefined,
      };
    },
    deleteElectionFail: (state, action) => {
      return {
        ...state,
        deleteElectionLoading: false,
        deleteElectionError: action.payload,
      };
    },

    storeSkipLimit: (state, action) => {
      return {
        ...state,
        skipAndLimit: action.payload,
      };
    },

    clearAdminRedux: (state, action) => {
      return {};
    },

    createAMAElectionStart: (state, action) => {
      return {
        ...state,
        createAMAElectionLoading: true,
        createElectionError: undefined,
      };
    },

    createAMAElectionSuccess: (state, action) => {
      return {
        ...state,
        createAMAElectionLoading: false,
        createAMAElectionError: undefined,
        showCreateAMAButton: false,
      };
    },
    createAMAElectionFail: (state, action) => {
      return {
        ...state,
        createAMAElectionLoading: false,
        createAMAElectionError: action.payload,
      };
    },

    electionTop2ProjectsLoadingStart: (state, action) => {
      return {
        ...state,
        electionTOp2ProjectLoading: true,
        electionTop2Projects: [],
        electionTop2ProjectsError: undefined,
      };
    },
    electionTop2ProjectsSuccess: (state, action) => {
      return {
        ...state,
        electionTOp2ProjectLoading: false,
        electionTop2Projects: action.payload,
        electionTop2ProjectsError: undefined,
      };
    },
    electionTop2ProjectsFailure: (state, action) => {
      return {
        ...state,
        electionTOp2ProjectLoading: false,
        electionTop2Projects: [],
        electionTop2ProjectsError: action.payload,
      };
    },
    clearElectionError: (state, action) => {
      return {
        ...state,
        createElectionError: undefined,
        editElectionError: undefined,
        deleteElectionError: undefined,
        listElectionError: undefined,
        createAMAElectionError: undefined,
        electionTop2ProjectsError: undefined,
        allPlatformProjectsError: undefined,
        allPlatformUserError: undefined,
        changeStatusOfUserError: undefined,
        changeStatusOfProjectError: undefined,
        adminGetReportError: undefined,
        deleteReportByAdminError: undefined,
        admibannerLoading: undefined,
        adminbanner: [],
        adminbannerError: undefined,
        adminBalanceError: undefined,
        addUserToSpotlightError: undefined,
        singleElectionDetailError: undefined,
      };
    },
    // to be checked
    allPlatformProjectLoadingStart: (state, action) => {
      return {
        ...state,
        allPlatformProjectsLoading: true,
        allPlatformProjects: [],
        allPlatformProjectsError: undefined,
      };
    },
    allPlatformProjectsSuccess: (state, action) => {
      return {
        ...state,
        allPlatformProjectsLoading: false,
        allPlatformProjects: action.payload,
        allPlatformProjectsError: undefined,
      };
    },
    allPlatformProjectFailure: (state, action) => {
      return {
        ...state,
        allPlatformProjectsLoading: false,
        allPlatformProjects: [],
        allPlatformProjectsError: action.payload,
      };
    },
    // to be checked
    allPlatformUserLoadingStart: (state, action) => {
      return {
        ...state,
        allPlatformUserLoading: true,
        allPlatformUsers: [],
        allPlatformUserError: undefined,
      };
    },

    allPlatformUserSuccess: (state, action) => {
      return {
        ...state,
        allPlatformUserLoading: false,
        allPlatformUsers: action.payload,
        allPlatformUserError: undefined,
      };
    },
    allPlatformUserFailure: (state, action) => {
      return {
        ...state,
        allPlatformUserLoading: false,
        allPlatformUsers: [],
        allPlatformUserError: action.payload,
      };
    },

    changeStatusOfUserByAdminLoading: (state, action) => {
      return {
        ...state,
        changeStatusOfUserLoading: true,
        changeStatusOfUserError: undefined,
      };
    },
    changeStatusOfUserByAdminSuccess: (state, action) => {
      return {
        ...state,
        changeStatusOfUserLoading: false,
        changeStatusOfUserError: undefined,
      };
    },
    changeStatusOfUserByAdminFailure: (state, action) => {
      return {
        ...state,
        changeStatusOfUserLoading: false,
        changeStatusOfUserError: action.payload,
      };
    },
    changeStatusOfProjectByAdminLoadingStart: (state, action) => {
      return {
        ...state,
        changeStatusOfProjectLoading: true,
        changeStatusOfProjectError: undefined,
      };
    },
    changeStatusOfProjectByAdminSuccess: (state, action) => {
      return {
        ...state,
        changeStatusOfProjectLoading: false,
        changeStatusOfProjectError: undefined,
      };
    },
    changeStatusOfProjectsByAdminFailure: (state, action) => {
      return {
        ...state,
        changeStatusOfProjectLoading: false,
        changeStatusOfProjectError: action.payload,
      };
    },
    adminGetReportLoadingStart: (state, action) => {
      return {
        ...state,
        adminGetReportLoading: true,
        adminGetReports: [],
        adminGetReportError: undefined,
      };
    },
    adminGetReportSuccess: (state, action) => {
      return {
        ...state,
        adminGetReportLoading: false,
        adminGetReports: action.payload,
        adminGetReportError: undefined,
      };
    },
    adminGetReportFailure: (state, action) => {
      return {
        ...state,
        adminGetReportLoading: false,
        adminGetReports: [],
        adminGetReportError: action.payload,
      };
    },

    deleteReportByAdminLoadingStart: (state, action) => {
      return {
        ...state,
        deleteReportByAdminLoading: true,
        deleteReportByAdminError: undefined,
      };
    },
    deleteReportByAdminSuccess: (state, action) => {
      return {
        ...state,
        deleteReportByAdminLoading: false,
        deleteReportByAdminError: undefined,
      };
    },
    deleteReportByAdminFailure: (state, action) => {
      return {
        ...state,
        deleteReportByAdminLoading: false,
        deleteReportByAdminError: action.payload,
      };
    },
    adminbannerLoadingStart: (state, action) => {
      return {
        ...state,
        admibannerLoading: true,
        adminbanner: [],
        adminbannerError: undefined,
      };
    },
    adminbannerEditSuccess: (state, action) => {
      return {
        ...state,
        admibannerLoading: false,

        adminbannerError: undefined,
      };
    },

    adminbannerSuccess: (state, action) => {
      return {
        ...state,
        admibannerLoading: false,
        adminbanner: action.payload,
        adminbannerError: undefined,
      };
    },

    adminbannerFailure: (state, action) => {
      return {
        ...state,
        admibannerLoading: false,
        adminbanner: [],
        adminbannerError: action.payload,
      };
    },

    adminBalanceSetLoading: (state, action) => {
      return {
        ...state,
        adminBalanceSetupLoading: true,
        adminBalanceError: undefined,
      };
    },
    adminBalanceSetSuccess: (state, action) => {
      return {
        ...state,
        adminBalanceSetupLoading: false,
        adminBalanceError: undefined,
      };
    },
    adminBalanceSetFailure: (state, action) => {
      return {
        ...state,
        adminBalanceSetupLoading: false,
        adminBalanceError: action.payload,
      };
    },

    addSpotlightUserLoadingStart: (state, action) => {
      return {
        ...state,
        addUserToSpotlightLoading: true,
        addUserToSpotlightError: undefined,
      };
    },
    addSpotlightUserSuccess: (state, action) => {
      return {
        ...state,
        addUserToSpotlightLoading: false,
        addUserToSpotlightError: undefined,
      };
    },
    addSpotlightUserFailure: (state, action) => {
      return {
        ...state,
        addUserToSpotlightLoading: false,
        addUserToSpotlightError: action.payload,
      };
    },
    singleElectionDetailLoadingStart: (state, action) => {
      return {
        ...state,
        singleElectionDetailLoading: true,
        singleElectionDetail: {},
        singleElectionDetailError: undefined,
      };
    },
    singleElectionDetailSuccess: (state, action) => {
      return {
        ...state,
        singleElectionDetailLoading: false,
        singleElectionDetail: action.payload,
        singleElectionDetailError: undefined,
      };
    },
    singleElectionDetailFailure: (state, action) => {
      return {
        ...state,
        singleElectionDetailLoading: false,
        singleElectionDetail: {},
        singleElectionDetailError: action.payload,
      };
    },
    // end of admin actions
  },
});

export default adminslice.reducer;

export const {
  createElectionTrigger,
  createElectionSuccess,
  createElectionFail,
  listElectionTrigger,
  listElectionSuccess,
  listElectionFail,
  editElectionTrigger,
  editElectionSuccess,
  editElectionFail,

  deleteElectionTrigger,
  deleteElectionSuccess,
  deleteElectionFail,
  storeSkipLimit,
  clearElectionError,
  createAMAElectionStart,
  createAMAElectionSuccess,
  createAMAElectionFail,
  electionTop2ProjectsLoadingStart,
  electionTop2ProjectsSuccess,
  electionTop2ProjectsFailure,
  allPlatformProjectLoadingStart,
  allPlatformProjectsSuccess,
  allPlatformProjectFailure,
  allPlatformUserLoadingStart,
  allPlatformUserSuccess,
  allPlatformUserFailure,
  changeStatusOfUserByAdminLoading,
  changeStatusOfUserByAdminSuccess,
  changeStatusOfUserByAdminFailure,
  changeStatusOfProjectByAdminLoadingStart,
  changeStatusOfProjectByAdminSuccess,
  changeStatusOfProjectsByAdminFailure,
  adminGetReportLoadingStart,
  adminGetReportSuccess,
  adminGetReportFailure,
  deleteReportByAdminLoadingStart,
  deleteReportByAdminSuccess,
  deleteReportByAdminFailure,
  adminbannerLoadingStart,
  adminbannerEditSuccess,
  adminbannerSuccess,
  adminbannerFailure,
  adminBalanceSetLoading,
  adminBalanceSetSuccess,
  adminBalanceSetFailure,
  addSpotlightUserLoadingStart,
  addSpotlightUserSuccess,
  addSpotlightUserFailure,
  singleElectionDetailLoadingStart,
  singleElectionDetailSuccess,
  singleElectionDetailFailure,

  clearAdminRedux,
} = adminslice.actions;
