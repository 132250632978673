import React, { useState, useEffect } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { navigateToForm } from "src/store/user_redux";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { disconnect } from "src/WalletProvider/Web3";
import QRCode from "react-qr-code";
import { ToastContainer, toast } from "react-toastify";
import { clearCheckWalletMinimalBalBackendError } from "@store/app_redux";
import CustomButton from "@components/custom-button/CustomButton";
import InputField from "@components/input-field/InputField";
import { ReactComponent as Copy } from "@images/copy.svg";
import { ReactComponent as CloseIcon } from "@images/close-icon.svg";
import BTC from "@images/bitcoin-logo.svg";
import "@components/btcpopup/btcPopup.scss";
import { clearDonateBTCPopup } from "@store/powerVote_redux";

const DonateBTC = ({
  show,
  setShowModal,
  openBitPage,
  powerVoteBTSucc,
  clearBTCPowerVote,
  //   message,
  //   status,
  //   callFromProject,
  //   minBalCheck,
  //   clearBackendErrorOfMinBalRule,
  donateBTCLoading,
  donateBTCSuccess,
  donateBTCError,
}) => {
  const navigate = useNavigate();

  const [copied, setcopied] = useState(false);
  useEffect(() => {
    console.log("donateBTc", donateBTCSuccess);
  }, [donateBTCSuccess]);
  return (
    <Modal
      className="BTCPopup"
      show={show}
      onHide={() => {
        clearBTCPowerVote();
        setShowModal(false);
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon
          className="closebtn"
          onClick={() => {
            // callFromProject && navigate("/myprojects");
            clearBTCPowerVote();
            setShowModal(false);
          }}
        />
        <Container>
          <Row>
            {
              <Col lg={12}>
                <div>
                  <img src={BTC} className="currency-icon" />
                </div>
              </Col>
            }
          </Row>
          <Row>
            <Col lg={12}>
              {donateBTCSuccess !== undefined &&
                donateBTCSuccess.bitcoinPayment !== undefined &&
                donateBTCSuccess.amountToShow !== null && (
                  <div className="message-text">
                    Use this address below to donate{" "}
                    {donateBTCSuccess.amountToShow} BTC from your wallet.
                  </div>
                )}
            </Col>

            <Col xs={12}>
              {donateBTCSuccess !== undefined &&
                donateBTCSuccess.bitcoinPayment !== undefined &&
                donateBTCSuccess.bitcoinPayment.bitcoinAddress !== null && (
                  <QRCode
                    title="title"
                    value={donateBTCSuccess.bitcoinPayment.bitcoinAddress}
                    bgColor={"#FFFFFF"}
                    fgColor={"#000000"}
                    // level="level"
                    size={100}
                    className="qr-cls"
                  />
                )}
            </Col>
            <Col xs={12}>
              <div className="inputbox address-field">
                {donateBTCSuccess !== undefined &&
                  donateBTCSuccess.bitcoinPayment !== undefined &&
                  donateBTCSuccess.bitcoinPayment.bitcoinAddress !== null && (
                    <InputField
                      className="address-div"
                      value={donateBTCSuccess.bitcoinPayment.bitcoinAddress}
                      placeholder={"Wallet Address"}
                      readOnly={true}
                    />
                  )}

                <Copy
                  onClick={() => {
                    if (
                      donateBTCSuccess !== undefined &&
                      donateBTCSuccess.bitcoinPayment !== undefined &&
                      donateBTCSuccess.bitcoinPayment.bitcoinAddress !== null
                    ) {
                      setcopied(donateBTCSuccess.bitcoinPayment.bitcoinAddress);
                      navigator.clipboard.writeText(
                        donateBTCSuccess.bitcoinPayment.bitcoinAddress
                      );
                      toast("Address copied successfully!", {
                        position: "top-right",
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: false,
                        draggable: true,
                        progress: undefined,
                      });
                    }
                  }}
                  className="copy"
                />

                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </div>
            </Col>
            <Col xs={12}>
              {/* {message !== "Connect your wallet to continue" ? ( */}
              <div className="btn-div">
                <CustomButton
                  filled
                  name="Proceed"
                  type="button"
                  primary
                  classname={"submit-btn"}
                  onClick={() => {
                    // disconnect()
                    // navigate(-1)
                    //   if (minBalCheck !== undefined && minBalCheck === true) {
                    //     clearBackendErrorOfMinBalRule();
                    //   }
                    openBitPage(true);
                    clearBTCPowerVote();
                    setShowModal(false);
                  }}
                />
              </div>
              {/* ) 
              : (
                <div className="btn-div">
                  <CustomButton
                    filled
                    name="Connect your wallet"
                    type="button"
                    primary
                    classname={"connect-btn"}
                    onClick={() => {
                      // disconnect()
                      // navigate(-1)
                      // if (minBalCheck !== undefined && minBalCheck === true) {
                      //   clearBackendErrorOfMinBalRule();
                      // }
                      navigate("/myprofile");
                      setShowModal(false);
                    }}
                  />
                </div>
              ) */}
              {/* } */}
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="error-text">
                Click Proceed once you transfer bitcoin to given address . Send
                only BTC to this address. Sending other tokens or NFTs to this
                address may result in loss of your donation.This address will
                expire in 2 hours.
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
const mapStateToProps = ({ powerVote = {} }) => {
  const powerVoteBTLoading = _.get(powerVote, "powerVoteBTCLoading", false);
  const powerVoteBTSucc = _.get(powerVote, "powerVoteBTC", undefined);
  const powerVoteBTError = _.get(powerVote, "powerVoteBTCError", undefined);
  const donateBTCLoading = _.get(powerVote, "donateBitcoinLoading", false);
  const donateBTCSuccess = _.get(powerVote, "donateBitcoin", undefined);
  const donateBTCError = _.get(powerVote, "donateBitcoinError", undefined);
  return {
    powerVoteBTLoading,
    powerVoteBTSucc,
    powerVoteBTError,
    donateBTCLoading,
    donateBTCSuccess,
    donateBTCError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  clearBackendErrorOfMinBalRule: () =>
    dispatch(clearCheckWalletMinimalBalBackendError()),
  clearBTCPowerVote: () => dispatch(clearDonateBTCPopup()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(DonateBTC));
