import React, { useEffect, useState } from "react";
import { Modal, Container, Row, Col, Form } from "react-bootstrap";
import CustomButton from "@components/custom-button/CustomButton";
import InputField from "@components/input-field/InputField";
import CurrencyDropdown from "@components/currency-dropdown/CurrencyDropdown";
import { ReactComponent as CloseIcon } from "@images/close-icon.svg";
import { Dropdown, FormControl } from "react-bootstrap";
import { ReactComponent as DropdownIcon } from "@images/dropdown.svg";
import "@components/buy-power-vote-popup/BuyPowerVotePopupStyle.scss";
import { connect } from "react-redux";

const BuyPowerVotePopup = ({
  show,
  setShowModal,
  setShowPowerVote,
  setShowConfirmPower,
  getPowerVotesOwnedByUser,
  powerVoteLoading,
  powerVotesOwnedByUser,
  powerVotesError,
  powerVoteChosen,
}) => {
  const [option, setOption] = useState("");
  const [checked, setChecked] = useState(false);
  const [idChosen, setIdChosen] = useState();
  const [notSelectedError, setNotSelectedError] = useState("");
  const VoteData = [
    {
      detail: "4 Power Vote",
    },
    {
      detail: "8 Power Vote",
    },
    {
      detail: "11 Power Vote",
    },
  ];

  const handleRadioButton = (e, id) => {
    powerVoteChosen(id);
  };

  // useEffect(() => {
  //   getPowerVotesOwnedByUser();
  // }, []);
  // useEffect(() => {
  //   console.log("powerrrr", powerVotesOwnedByUser);
  // }, [powerVotesOwnedByUser]);

  const handleVoting = () => {
    if (powerVoteChosen !== undefined && atLeastOneRadio() === true) {
      setShowConfirmPower(true);
      setShowModal(false);
    } else {
      setNotSelectedError("Select any power vote package to continue !");
    }
  };
  function atLeastOneRadio() {
    var checked_gender = document.querySelector(
      'input[name = "category"]:checked'
    );

    if (checked_gender != null) {
      //Test if something was checked
      return true; //Alert the value of the checked.
    } else {
      return false; //Alert, nothing was checked.
    }
  }

  useEffect(() => {
    if (powerVoteChosen !== undefined && atLeastOneRadio() === true) {
      setNotSelectedError("");
    }
    // console.log("id chosen", isNaN(powerVoteChosen));
  }, [powerVoteChosen]);
  return (
    <Modal
      className="BuyPowerVotePopup"
      show={show}
      onHide={() => {
        powerVoteChosen();
        setShowModal(false);
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon
          className="closebtn"
          onClick={() => {
            powerVoteChosen();
            setNotSelectedError("");

            setShowModal(false);
          }}
        />
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading-text">Power Vote</div>
              <div className="detail-list">
                {powerVotesOwnedByUser &&
                powerVotesOwnedByUser !== undefined &&
                powerVotesOwnedByUser.length > 0 ? (
                  powerVotesOwnedByUser.map((data) => (
                    <div className="vote-detail">
                      <div className="checkboxholder">
                        <Form.Check
                          type="radio"
                          id={`${data.id}`}
                          label={`${data.votePower} Power Vote`}
                          name="category"
                          value={data.id}
                          onClick={(e) => handleRadioButton(e, data.id)}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <p className="show-msg">
                    You don't have any power vote in your account, Click on Buy
                    Power Vote Button below to buy and support your favorite
                    creator/project !
                  </p>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <div className="buy-btn">
              <CustomButton
                classname="cancelbtn"
                type="submit"
                name="Buy Power Vote"
                filled={true}
                primary={true}
                onClick={() => {
                  powerVoteChosen();
                  setShowPowerVote(true);
                  setShowModal(false);
                }}
              />
            </div>
          </Row>
          <Row>
            <Col xs={12} className="btn-div">
              <div className="confirm">
                <CustomButton
                  type="submit"
                  name="Vote"
                  filled={true}
                  primary={true}
                  onClick={() => {
                    handleVoting();
                  }}
                />
              </div>
              <div className="cancel">
                <CustomButton
                  classname="cancelbtn"
                  type="submit"
                  name="Cancel"
                  filled={true}
                  primary={true}
                  onClick={() => {
                    powerVoteChosen();
                    setNotSelectedError("");
                    setShowModal(false);
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="error-text">
                {notSelectedError !== "" && notSelectedError}
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({ powerVote = {} }) => {
  const powerVoteLoading = _.get(powerVote, "powerVoteOfUserLoading", false);
  const powerVotesOwnedByUser = _.get(powerVote, "powerVotesOfUser", []);
  const powerVotesError = _.get(powerVote, "powerVotesOfUserError", undefined);
  return {
    powerVoteLoading,
    powerVotesOwnedByUser,
    powerVotesError,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getPowerVotesOwnedByUser: () =>
    dispatch({ type: "allPowerVotesOwnedByUserSagaCalled" }),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(BuyPowerVotePopup));
