import React from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as CloseIcon } from "@images/close-icon.svg";
import "@components/delete-success-popup/DeleteSuccessPopupStyle.scss";
const DeleteSuccessPopup = ({
  show,
  setShowModal,
  onClick,
  message,
  role,
  editpage,
  navigateToMyProjects,
}) => {
  const navigate = useNavigate();
  return (
    <Modal
      className="DeleteSuccessPopup"
      show={show}
      onHide={() => {
        setShowModal(false);
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon
          className="closebtn"
          onClick={() => {
            setShowModal(false);
          }}
        />
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading-text">{message}</div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} sm={12} xs={12} className={role == "admin" ? "admin-btn-div" : "btn-div"}>
              <div className="cancel">
                <CustomButton
                  classname="cancelbtn"
                  type="submit"
                  name="Close"
                  filled={true}
                  primary={true}
                  onClick={() => {
                    {
                      (editpage  || navigateToMyProjects)=== true
                        ? navigate(`/myprojects`)
                        : setShowModal(false);
                    }
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(DeleteSuccessPopup);
