import Web3 from "web3";
import provider from "./walletProvider";

//  Create Web3 instance
export const web3 = new Web3(provider);

export const disconnect = async () => {
  // Close provider session

  let pr = await provider();
  // console.log("disconnect from provider", pr);
  if (localStorage.getItem("walletconnect")) {
    // localStorage.removeItem("walletconnect")
    pr.disconnect();
  } else if (pr === true) {
  }
};

export const Send = async () => {
  let pr = await provider();
  //  Create Web3 instance

  if (pr !== true) {
    const web3 = new Web3(pr);

    let accounts = await web3.eth.getAccounts();
    let account = accounts[0];
    web3.eth
      .sendTransaction({
        from: account,
        to: "0xA9dAd63Cf8425554bEa1D79593b9f04FF2475524",
        value: "19000000000000",
      })
      .then(function (receipt) {});
  }
};
