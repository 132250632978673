import WalletConnectProvider from "@walletconnect/web3-provider";
const Web3 = require("web3");

let web3ForContract;
let web3BNB;
if (localStorage.getItem("walletconnect")) {
  const provider = new WalletConnectProvider({
    rpc: {
      // 97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
      0x5: "https://goerli.infura.io/v3/f07a27ee5029409493ef92be46060763",
    },
  });
  provider.enable();
  web3ForContract = new Web3(provider);
  // web3 = new Web3(provider);
} else if (window.ethereum) {
  web3ForContract = new Web3(window.ethereum);
} else if (window.web3) {
  const provider = new Web3.providers.HttpProvider(
    "https://goerli.infura.io/v3/f07a27ee5029409493ef92be46060763"
  );
  web3 = new Web3(provider);
} else {
  const provider = new Web3.providers.HttpProvider(
    "https://goerli.infura.io/v3/f07a27ee5029409493ef92be46060763"
  );
  web3ForContract = new Web3(provider);
}

if (localStorage.getItem("walletconnect")) {
  const provider = new WalletConnectProvider({
    rpc: {
      // 0x61: "wss://ws-nd-746-676-186.p2pify.com/53e83d2557d104836c20eb61c0f022c9",

      0x61: "https://data-seed-prebsc-1-s1.binance.org:8545",
      // wss:ws-nd-746-676-186.p2pify.com/53e83d2557d104836c20eb61c0f022c9
    },
  });
  provider.enable();
  web3BNB = new Web3(provider);
  // web3 = new Web3(provider);
} else if (window.ethereum) {
  web3BNB = new Web3(window.ethereum);
} else if (window.web3) {
  const provider = new Web3.providers.HttpProvider(
    "https://data-seed-prebsc-1-s1.binance.org:8545"
  );
  web3BNB = new Web3(provider);
} else {
  const provider = new Web3.providers.HttpProvider(
    "https://data-seed-prebsc-1-s1.binance.org:8545"
  );
  web3BNB = new Web3(provider);
}
export default { web3ForContract, web3BNB };
