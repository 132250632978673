import React from 'react';
import {ThreeDots} from "react-loader-spinner";
import "@components/Spinner/Spinner.scss";

const Spinner = () => {


  return (
    
      <div className="spinner">
        <ThreeDots type="ThreeDots" color="#ff8c00" height="50" width="50" />
      </div>
    )
  
};

export default Spinner;