import { call, put, takeLatest } from "redux-saga/effects";
import Api from "./../services/ApiCaller";
import { getAccessToken } from "src/utilities/utility";

import {
  creatorsDetailsLoadingStart,
  creatorDetailsSuccess,
  creatorDetailsFailure,
  top50CreatorLoadingStart,
  top50CreatorSuccess,
  top50CreatorFailure,
  votecreatorFailure,
  votecreatorLoadingStart,
  votecreatorSuccess,
  checkvoteLoading,
} from "./creator_redux";
import { updateVOtes } from "./user_redux";

export function* CreatorDetailsSaga(api, { payload }) {
  yield put(creatorsDetailsLoadingStart());
  try {
    const result = yield call(
      Api.callServer,
      api.GetHomePageData,
      payload,
      true
    );
    yield put(creatorDetailsSuccess(result.result));
  } catch (e) {
    yield put(creatorDetailsFailure(e.error));
  }
}

export function* Top50CreatorSaga(api, {payload}) {
  console.log('top 50 saga');
  yield put(top50CreatorLoadingStart());
  try {
    const result = yield call(
      Api.callServer,
      api.GetTop50Creators,
      {},
      true
    );
    console.log("result", result);
    yield put(top50CreatorSuccess(result.result));
  } catch (e) {
    yield put(top50CreatorFailure(e.error));
  }
}
export function* VoteCreatorSaga(api, { payload }) {
  yield put(votecreatorLoadingStart());
  try {
    let token = yield getAccessToken();

    const result = yield call(
      Api.callServer,
      api.voteCreator,
      { id: payload.projectId, token },
      true
    );

    payload.callBack();
    yield put(updateVOtes(result.result));

    yield put(votecreatorSuccess(result.result));
  } catch (e) {
    yield put(votecreatorFailure(e.error));
  }
}

export function* VoteCheckerSaga(api, { payload }) {
  yield put(checkvoteLoading(true));
  console.log("Pyaloas", payload);
  try {
    let token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.voteCreatorcheck,
      { id: payload.id, token },
      true
    );
    console.log(result);
    payload.callback(result);
  } catch (e) {
    console.log(e, "er");
    yield put(checkvoteLoading(false));
  }
}
