import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
    name: "App",
    initialState: {
        checkMinimalBalanceLoading: undefined,
        checkMininmalBalance: undefined,
        checkMinimalBalanceError: undefined,
        getUserWalletBalLoading: undefined,
        getUserBal: undefined,
        getUserBalError: undefined,
        getExchangeRateCreateToBusdLoading : undefined,
        getExchangeRateCreateToBusd : undefined,
        getExchangeRateCreateToBusdError : undefined,
        getProjectCreatorCountForHomeLoading : undefined,
        getProjectCreatorCount : undefined,
        getProjectCreatorCountError : undefined,

    },
    reducers: {
        checkMinBalLoadingStart: (state, action) => {
            return {
                ...state,
                checkMinimalBalanceLoading: true,
                checkMininmalBalance: undefined,
                checkMinimalBalanceError: undefined,
            }
        },
        checkMinBalSuccess: (state, action) => {
            return {
                ...state,
                checkMinimalBalanceLoading: false,
                checkMininmalBalance: action.payload,
                checkMinimalBalanceError: undefined,
            }
        },
        checkMinBalFailure: (state, action) => {
            return {
                ...state,
                checkMinimalBalanceLoading: false,
                checkMininmalBalance: undefined,
                checkMinimalBalanceError: action.payload,
            }
        },
        getUserBalLoadingStart: (state, action) => {
            return {
                ...state,
                getUserWalletBalLoading: true,
                getUserBal: undefined,
                getUserBalError: undefined,
            }
        },
        getUserBalSuccess: (state, action) => {
            return {
                ...state,
                getUserWalletBalLoading: false,
                getUserBal: action.payload,
                getUserBalError: undefined,
            }
        },
        getUserBalFailure: (state, action) => {
            return {
                ...state,
                getUserWalletBalLoading: false,
                getUserBal: undefined,
                getUserBalError: action.payload,
            }
        },
        getExchangeCreateToBusdLoadingStart : (state,action)=>{
            return{
                ...state,
                getExchangeRateCreateToBusdLoading : true,
                getExchangeRateCreateToBusd : undefined,
                getExchangeRateCreateToBusdError : undefined,
            }
        },
        getExchangeCreateToBusdSuccess :(state,action)=>{
            return{
                ...state,
                getExchangeRateCreateToBusdLoading : false,
                getExchangeRateCreateToBusd : action.payload,
                getExchangeRateCreateToBusdError : undefined,
            }
        },
        getExchangeCreateToBusdFailure : (state,action)=>{
            return{
                ...state,
                getExchangeRateCreateToBusdLoading : false,
                getExchangeRateCreateToBusd : undefined,
                getExchangeRateCreateToBusdError : action.payload,
            }
        },
        clearCheckWalletMinimalBalBackendError : (state,action)=>{
            return{
                ...state,
                checkMinimalBalanceError : undefined
            }
        },
        clearAppRedux : (state,action)=>{
            return{}
        },
        getProjectCreatorCountLoadingStart : (state,action)=>{
            return{
                ...state,
                getProjectCreatorCountForHomeLoading : true,
                getProjectCreatorCount : undefined,
                getProjectCreatorCountError : undefined,
            }
        },
        getProjectCreatorCountSuccess : (state,action)=>{
            return{
                ...state,
                getProjectCreatorCountForHomeLoading : false,
                getProjectCreatorCount : action.payload,
                getProjectCreatorCountError : undefined,
            }
        },
        getProjectCreatorCountFailure : (state,action)=>{
            return{
                ...state,
                getProjectCreatorCountForHomeLoading : false,
                getProjectCreatorCount : undefined,
                getProjectCreatorCountError : action.payload,
            }
        },
    }
})

export default appSlice.reducer;
export const {
    checkMinBalLoadingStart,
    checkMinBalSuccess,
    checkMinBalFailure,
    getUserBalLoadingStart,
    getUserBalSuccess,
    getUserBalFailure,
    getExchangeCreateToBusdLoadingStart,
    getExchangeCreateToBusdSuccess,
    getExchangeCreateToBusdFailure,
    clearCheckWalletMinimalBalBackendError,
    clearAppRedux,
    getProjectCreatorCountLoadingStart,
    getProjectCreatorCountSuccess,
    getProjectCreatorCountFailure,

} = appSlice.actions; 