import React, { useState, useEffect } from "react";
import { Container, Row, Col, Card, Dropdown } from "react-bootstrap";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { useNavigate } from "react-router";
import { ReactComponent as VerticleIcon } from "@images/verticalicon.svg";
import DeletePopup from "@components/delete-popup/DeletePopup";
import DeleteSuccessPopup from "@components/delete-success-popup/DeleteSuccessPopup";
import CampaignDeadlinePopup from "@components/campaign-deadline-popup/CampaignDeadlinePopup";
import TemplatePopup from "@components/template-popup/TemplatePopup";
import { ReactComponent as Deactivated } from "@images/deactivate.svg";
import { ReactComponent as Deleted } from "@images/deleted.svg";
import { ReactComponent as NoData } from "@images/nothing_found.svg";
import Cover from "@images/demo-cover.jpg";
import { isEmpty } from "lodash";
import { connect } from "react-redux";
import "@components/creator-detail-page-card/CreatorDetailPageCardStyle.scss";

const CreatorDetailPageCard = ({
  cardType,
  page,
  data,
  publishProjectAsync,
}) => {
  const [showDelete, setShowDelete] = useState(false);
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const [showDraft, setShowDraft] = useState(false);
  const [showDraftSuccess, setShowDraftSuccess] = useState(false);
  const [showCampainDeadline, setShowCampainDeadline] = useState(false);
  const [showTemplate, setShowTemplate] = useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [projectId, setProjectId] = useState("");
  const [currentId, setCurrentId] = useState();
  const [projectList, setProjectList] = useState([]);
  const [
    dataFromCampaignPopupForCreatorDetail,
    setDataFromCampaignPopupForCreatorDetail,
  ] = useState({});
  const [showPublishSuccess, setShowPublishSuccess] = useState(false);
  const navigate = useNavigate();
  const [showDraftTemplate, setShowDraftTemplate] = useState(false);

  useEffect(() => {
    if (data !== undefined) {
      setProjectList(data);
    }
  }, [data]);

  useEffect(() => {
    if (
      dataFromCampaignPopupForCreatorDetail !== undefined &&
      isEmpty(dataFromCampaignPopupForCreatorDetail) === false
    ) {
      // console.log("dat", dataFromCampaignPopupForCreatorDetail);
      publishProjectAsync({
        dataForBackend: dataFromCampaignPopupForCreatorDetail,
        callBackSuccess: () => setShowPublishSuccess(true),
        openMinBalPopup: () => {
          setShowCampainDeadline(false);
          setShowDraftTemplate(true);
        },
      });
    }
  }, [dataFromCampaignPopupForCreatorDetail]);
  return (
    <div className="CreatorDetailPageCard">
      <Container>
        {page === "see-all" ? null : (
          <Row>
            <Col>
              <div className="top-line">
                <div className="card-type">{cardType} </div>
              </div>
            </Col>
          </Row>
        )}
        <Row>
          {projectList !== undefined && projectList?.length === 0 && (
            <NoData className="no-data" />
          )}
          {projectList !== undefined &&
            projectList.length > 0 &&
            projectList.map((item) => (
              <Col xs={12} sm={6} lg={4} className="Card-Col">
                <Card
                  onClick={(e) => {
                    if (e.target.classList.contains("read")) {
                      e.stopPropagation();
                    } else {
                      navigate(`/project-details/${item.id}`);
                    }
                  }}
                >
                  <div className="card-img-block">
                    {item.bannerImage !== undefined && (
                      <img
                        className="img-fluid cover"
                        src={`${process.env.REACT_APP_IMGLINK}${item.bannerImage}`}
                        alt="Card image cap"
                      />
                    )}
                  </div>
                  <Card.Body>
                    <div className="detail-div">
                      <div className="left">
                        {item.name !== undefined && (
                          <div className="project-name">{item.name}</div>
                        )}
                        {item.category !== undefined && (
                          <div className="category-text">
                            {item.category.name}
                          </div>
                        )}
                      </div>
                      {page === "see-all" ? (
                        <div className="edit">
                          <Dropdown>
                            <Dropdown.Toggle
                              className="threedot"
                              id="dropdown-basic"
                            >
                              <VerticleIcon className="vertical " />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="menu">
                              <Dropdown.Item
                                onClick={() => {
                                  navigate("/editproject", { state: item });
                                }}
                              >
                                Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => {
                                  setDeleteId(item.id);
                                  setShowDelete(true);
                                }}
                              >
                                Delete
                              </Dropdown.Item>
                              {cardType == "Draft Projects" ? (
                                <Dropdown.Item
                                  onClick={() => {
                                    setProjectId(item.id);
                                    setShowCampainDeadline(true);
                                  }}
                                >
                                  Publish
                                </Dropdown.Item>
                              ) : (
                                <Dropdown.Item
                                  onClick={() => {
                                    setProjectId(item.id);
                                    setShowDraft(true);
                                  }}
                                >
                                  Send to Draft
                                </Dropdown.Item>
                              )}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      ) : null}
                    </div>
                    {item.description !== null && (
                      <div className="card-desc">
                        <ReactReadMoreReadLess
                          charLimit={100}
                          readMoreText={"read more"}
                          readLessText={"read less"}
                          readMoreClassName="read"
                          readLessClassName="read"
                        >
                          {item.description}
                        </ReactReadMoreReadLess>
                      </div>
                    )}

                    {item.totalDonatedAmount !== null &&
                      item.totalDonatedAmount > 0 && (
                        <div className="last-line">
                          <span className="color-text">Total Fund Raised</span>
                          <span className="amount">
                            ${item.totalDonatedAmount}
                          </span>
                        </div>
                      )}
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
      </Container>

      <DeletePopup
        show={showDelete}
        setShowModal={(e) => setShowDelete(e)}
        setShowDeleteSuccess={(e) => setShowDeleteSuccess(e)}
        message="You want to delete project ?"
        deleteId={deleteId}
        onPress={() => {
          deleteProjectAsync({
            projectId: deleteId,
            template: (e) => setShowTemplate(e),
            callBack: () => {
              setShowDeleteSuccess(true);
            },
            // skipLimit
          });
        }}
        onCancelled={() => {
          setShowDelete(false);
          setDeleteId("");
        }}
      />
      <DeleteSuccessPopup
        show={showDeleteSuccess}
        setShowModal={(e) => setShowDeleteSuccess(e)}
        message="Project deleted successfully!"
      />
      <DeletePopup
        show={showDraft}
        setShowModal={(e) => setShowDraft(e)}
        setShowDeleteSuccess={(e) => setShowDeleteSuccess(e)}
        message="You want to draft project ?"
        projectId={projectId}
        onPress={() => {
          draftToPublicProjectAsync({
            projectId,
            callBack: () => {
              setShowDraft(false);
              setShowDraftSuccess(true);
              // setShowDraftSuccess(false);
            },
          });
        }}
        onCancelled={() => {
          // setProjectId("");
          // setShowDelete(false);
          setShowDraft(false);
          // setShowCampainDeadline(false);
        }}
        type={"publictodraft"}
      />
      <DeleteSuccessPopup
        show={showDraftSuccess}
        setShowModal={(e) => setShowDraftSuccess(e)}
        message="Project moved to draft successfully"
      />
      <CampaignDeadlinePopup
        show={showCampainDeadline}
        setShowModal={(e) => setShowCampainDeadline(e)}
        setShowDraftSuccessProp={(e) => setShowDraftSuccess(e)}
        projectId={projectId}
        // publishProjectAction={(data) => publishProjectAsync(data)}
        //publishProjectBackendError={publishProjectBackendError}
        onCancelled={() => {
          setProjectId("");
          setShowCampainDeadline(false);
        }}
        getDataForCampaign={(e) => {
          setDataFromCampaignPopupForCreatorDetail(e);
        }}
      />
      <DeleteSuccessPopup
        show={showPublishSuccess}
        setShowModal={(e) => setShowPublishSuccess(e)}
        message="Project published successfully"
      />
      <TemplatePopup
        show={showTemplate}
        setShowModal={setShowTemplate}
        //message={projectBackendError !== undefined && projectBackendError}
      />
      <TemplatePopup
        show={showDraftTemplate}
        setShowModal={setShowDraftTemplate}
        message={"Connect your wallet to continue"}
        minBalCheck={true}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  publishProjectAsync: (data) =>
    dispatch({ type: "publishProjectSagaCalled", payload: data }),
});

export default connect(null, mapDispatchToProps)(CreatorDetailPageCard);
