import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import createSagaMiddleware from "redux-saga";
import saga from "./sagaindex";
import admin from "./admin_redux";
import user from "./user_redux";
import category from "./category_redux";
import project from "./project_redux";
import competetion from "./competetion_redux";
import creator from "./creator_redux";
import app from "./app_redux";
import powerVote from "./powerVote_redux";
import notificationRedux from "./notification_redux";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
  whitelist: [
    "user",
    "admin",
    "category",
    "project",
    "competetion",
    "creator",
    "app",
    "powerVote",
    "notificationRedux",
  ],
};

const reducer = combineReducers({
  user,
  admin,
  category,
  project,
  competetion,
  creator,
  app,
  powerVote,
  notificationRedux,
});
const persistedReducer = persistReducer(persistConfig, reducer);
let sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    ...getDefaultMiddleware({
      thunk: false,
      serializableCheck: false,
    }),
    sagaMiddleware,
  ],
});
sagaMiddleware.run(saga);

const persistor = persistStore(store);

export { persistor, store };
