import { call, put, takeLatest } from "redux-saga/effects";
import Api from "./../services/ApiCaller";
import { getAccessToken } from "src/utilities/utility";
import {
  notficationLoadingStart,
  notificationSuccess,
  notificationFailure,
  notficationCountLoadingStart,
  notificationCountSuccess,
  notificationCountFailure,
  singleNotificationReadLoadingStart,
  singleNotificationReadSuccess,
  singleNotificationReadFailure,
  allNotificationReadLoadingStart,
  allNotificationReadSuccess,
  allNotificationReadFailure,
  newsLetterLoadingStart,
  newsLetterSuccess,
  newsLetterFailure,
} from "./notification_redux";
import { signleUserDetailLoadingStart } from "./user_redux";
export function* GetAllNotificationSaga(api, { payload }) {
  console.log("notification", payload);
  yield put(notficationLoadingStart());
  let token = yield getAccessToken();
  try {
    const result = yield call(
      Api.callServer,
      api.GetAllNotifications,
      {
        dataForBackend: payload,
        token,
      },
      true
    );
    yield put(notificationSuccess(result.result));
  } catch (e) {
    yield put(notificationFailure(e.message));
  }
}

export function* GetAllNotificationCountSaga(api, { payload }) {
  yield put(notficationCountLoadingStart());
  let token = yield getAccessToken();
  try {
    const result = yield call(
      Api.callServer,
      api.GetAllUnreadNotificationCount,

      token,

      true
    );
    yield put(notificationCountSuccess(result.result));
  } catch (e) {
    yield put(notificationCountFailure(e.message));
  }
}

export function* SingleNotificationReadSaga(api, { payload }) {
  yield put(singleNotificationReadLoadingStart());
  let token = yield getAccessToken();

  try {
    const { id, type, navigate, user } = payload;
    delete payload["id"];
    const result = yield call(
      Api.callServer,
      api.MarkSingleNotificationRead,
      {
        dataForBackend: id,
        token,
      },
      true
    );
    yield put(singleNotificationReadSuccess(result.result));
    // setClickedOpen(true);
    if (type === "powerVote") {
      navigate("/mypowervote");
    } else if (type === "donate") {
      navigate(`/mydonations`);
    }
    yield call(GetAllNotificationCountSaga, api, {});
    yield call(GetAllNotificationSaga, api, { payload });
  } catch (e) {
    yield put(singleNotificationReadFailure(e.message));
  }
}

export function* AllNotificationReadSaga(api, { payload }) {
  yield put(allNotificationReadLoadingStart());
  let token = yield getAccessToken();

  try {
    const result = yield call(
      Api.callServer,
      api.MarkAllNotificationRead,
      token,
      true
    );
    yield put(allNotificationReadSuccess(result.result));
    yield call(GetAllNotificationCountSaga, api, {});
    console.log("payload", { payload });
    yield call(GetAllNotificationSaga, api, { payload });
  } catch (e) {
    yield put(allNotificationReadFailure(e.message));
  }
}

export function* requestNewsLetterSaga(api, { payload }) {
  yield put(newsLetterLoadingStart());
  try {
    const { openSuccess } = payload;
    delete payload["openSuccess"];

    const result = yield call(
      Api.callServer,
      api.NewsLetterRequest,
      payload,
      true
    );
    yield put(newsLetterSuccess(result.result));
    openSuccess();
  } catch (e) {
    yield put(newsLetterFailure(e.error));
  }
}
