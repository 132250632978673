import React, { useState, useEffect } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { ReactComponent as CloseIcon } from "@images/close-icon.svg";
import InputField from "@components/input-field/InputField";
import CustomButton from "@components/custom-button/CustomButton";
import { connect } from "react-redux";
import { publicProjectFailure } from "src/store/project_redux";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from "react-router-dom";
import Loader from "@components/loader/Loader";
import { clearProjectErrorFromBackend } from "@store/project_redux";
import "@components/campaign-deadline-popup/CampaignDeadlinePopupStyle.scss";

const CampaignDeadlinePopup = ({
  show,
  setShowModal,
  onPress,
  onCancelled,
  setShowDraftSuccessProp,
  projectId,
  publishProjectAction,
  publishProjectBackendError,
  publishProjectAsync,
  createProjectCall,
  clearBackendError,
  publishProjectLoading,
  getDataForCampaign,
  // setDeleteSuccess,
  setShowDelete,
}) => {
  const [date, setDate] = useState("");

  const [Amount, setAmount] = useState("");
  const [error, setError] = useState("");
  const [amountError, setAmountError] = useState(false);

  const navigate = useNavigate();

  const modifyDate = (date) => {
    let utcStartDate = new Date(date).toUTCString();
    let utcToIso = new Date(utcStartDate).toISOString();
    return utcToIso;
  };

  const PublishProject = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const data = {
      projectId: projectId,
      campaignDateTime: date !== "" ? modifyDate(date) : "",
      fundsAmount: Amount !== "" ? Number(Amount) : 0,

      callBack: () => closePopup(),
    };
    getDataForCampaign(data);

    // publishProjectAsync(data)
  };
  const skipCalled = () => {
    const data = {
      projectId: projectId,
      campaignDateTime: date !== "" ? modifyDate(date) : "",

      callBack: () => closePopup(),
    };
    getDataForCampaign(data);
  };
  useEffect(() => {
    if (date !== "") {
      setError("");
    }
  }, [date]);
  useEffect(() => {
    if (amountError !== "" && Amount !== "") {
      setAmountError("");
    }
  }, [Amount]);

  const closePopup = () => {
    setDate("");
    setAmount("");
    setError("");
    clearBackendError();
    navigate("/myprojects");
    setShowModal(false);
  };
  return (
    <Modal
      className="CampaignDeadlinePopup"
      show={show}
      onHide={() => {
        closePopup();
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon
          className="closebtn"
          onClick={() => {
            closePopup();
          }}
        />
        <Container>
          <Row>
            <Col lg={12}>
              {publishProjectLoading && <Loader />}
              <div className="heading-text">Set Campaign Deadline</div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <div className="input-div">
                <div className="inputbox">
                  <DatePicker
                    placeholderText="Date"
                    timeFormat="HH:mm"
                    timeIntervals={15}
                    showTimeSelect
                    minDate={new Date()}
                    timeCaption="Time"
                    dateFormat="MMMM d, yyyy"
                    selected={date}
                    onChange={(date) => setDate(date)}
                  />
                </div>
                <div className="inputbox">
                  <DatePicker
                    placeholderText="Time"
                    disabled={true}
                    minDate={new Date()}
                    dateFormat="hh:mm a"
                    selected={date}
                  />
                </div>
                <div className="inputbox amount">
                  <InputField
                    placeholder={"Amount in USD"}
                    type="number"
                    onChange={(e) => setAmount(e.target.value)}
                    value={Amount}
                    required
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12} sm={12} xs={12} className="btn-div">
              <div className="confirm">
                <CustomButton
                  type="button"
                  name="Publish"
                  filled={true}
                  primary={true}
                  onClick={(e) => {
                    // we need to implement and operator here later
                    PublishProject(e);
                  }}
                />
              </div>
              <div className="cancel">
                <CustomButton
                  classname="cancelbtn"
                  type="button"
                  name="Skip"
                  filled={true}
                  primary={true}
                  onClick={() => {
                    // PublishProject()
                    // navigate('/myprojects')
                    skipCalled();
                    // closePopup();
                    // onCancelled()
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="error-text">
                {error !== "" && error}
                {amountError !== "" && amountError}
                {publishProjectBackendError !== undefined &&
                  publishProjectBackendError}
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
const mapStateToProps = ({ project = {} }) => {
  const publishProjectLoading = _.get(project, "publishProjectLoading", false);
  return {
    publishProjectLoading,
  };
};
const mapDispatchToProps = (dispatch) => ({
  clearBackendError: () => dispatch(clearProjectErrorFromBackend()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CampaignDeadlinePopup);
