import * as _ from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { Dropdown, FormControl } from "react-bootstrap";
import Select, { components } from "react-select";
import { ReactComponent as CaretDownIcon } from "@images/dropdown.svg";
import ETH from "@images/eth-logo.svg";
import USDC from "@images/usdc-logo.svg";
import BNB from "@images/bnb-logo.svg";
import BTC from "@images/btc.png";
import { clearConversionFromUTS } from "@store/powerVote_redux";
import { connect } from "react-redux";
import BUSD from "@images/busd.svg";
import Web3 from "web3";
import USD from "@images/Dollar-Icon.svg";
import "@components/currency-dropdown/CurrencyDropdownStyle.scss";

const CurrencyDropdown = ({
  usdValueEth,
  convertUSDToEth,
  clearUSDToEth,
  usdValue,
  conversionLoading,
  chosenCurrencyUSD,
  ethOrUsd,
  popupType,
}) => {
  const options = [
    {
      value: "eth",
      label: (
        <div>
          <img src={ETH} className="currency-icon" />
          ETH{" "}
        </div>
      ),
    },
    {
      value: "usdc",
      label: (
        <div>
          <img src={USDC} className="currency-icon" />
          USDC{" "}
        </div>
      ),
    },
    {
      value: "busd",
      label: (
        <div>
          <img src={BUSD} className="currency-icon" />
          BUSD{" "}
        </div>
      ),
    },
    {
      value: "btc",
      label: (
        <div>
          <img src={BTC} className="currency-icon" />
          BTC{" "}
        </div>
      ),
    },
  ];
  const optionsDonate = [
    {
      value: "bnb",
      label: (
        <div>
          <img src={BNB} className="currency-icon" />
          BNB{" "}
        </div>
      ),
    },
    {
      value: "eth",
      label: (
        <div>
          <img src={ETH} className="currency-icon" />
          ETH{" "}
        </div>
      ),
    },
    {
      value: "usdc",
      label: (
        <div>
          <img src={USDC} className="currency-icon" />
          USDC{" "}
        </div>
      ),
    },
    {
      value: "busd",
      label: (
        <div>
          <img src={BUSD} className="currency-icon" />
          BUSD{" "}
        </div>
      ),
    },
    {
      value: "btc",
      label: (
        <div>
          <img src={BTC} className="currency-icon" />
          BTC{" "}
        </div>
      ),
    },
    {
      value: "fiat",
      label: (
        <div>
          <img src={USD} className="currency-icon" />
          USD{" "}
        </div>
      ),
    },
  ];
  const [selectedCurreny, seSelecteCurrency] = useState("");
  const customStyles = {
    option: (base, state) => ({
      ...base,
      color: "#261B1B",
      background: state.isFocused
        ? "#F3F3F3"
        : state.isSelected
        ? "#e3e3e3"
        : "white",
      padding: "10px 12px",
      fontSize: "14px",
      fontWeight: "400px",
      cursor: "pointer",
      textAlign: "left",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: "all .2s ease",
      transform: state.isFocused ? "rotate(180deg)" : null,
    }),
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon className="dropdown-icon" />
      </components.DropdownIndicator>
    );
  };
  useEffect(() => {
    if (selectedCurreny === "eth") {
      // convertUSDToEth(Web3.utils.toWei(usdValue, "mwei"));
      chosenCurrencyUSD("ETH");
      convertUSDToEth(usdValue);
    } else if (selectedCurreny === "usdc") {
      chosenCurrencyUSD("USD");
      clearUSDToEth();
    } else if (selectedCurreny === "busd") {
      chosenCurrencyUSD("BUSD");
      clearUSDToEth();
    } else if (selectedCurreny === "btc") {
      chosenCurrencyUSD("BTC");
      clearUSDToEth();
    } else {
      clearUSDToEth();
    }
    // console.log(selectedCurreny);
  }, [selectedCurreny]);

  const handleSelectChange = (e) => {
    // console.log(e.value);
    if (e && e.value !== undefined) {
      seSelecteCurrency(e.value);
      ethOrUsd !== undefined && ethOrUsd(e.value);
    }
  };
  return (
    <div className="CurrencyDropdown">
      {popupType === "Donate" ? (
        <div className="select-dp">
          <Select
            placeholder="Currency"
            options={optionsDonate}
            styles={customStyles}
            components={{ DropdownIndicator }}
            isLoading={conversionLoading ? true : false}
            onChange={(e) => handleSelectChange(e)}
          />
        </div>
      ) : (
        <div className="select-dp">
          <Select
            placeholder="Currency"
            options={options}
            styles={customStyles}
            components={{ DropdownIndicator }}
            isLoading={conversionLoading ? true : false}
            onChange={(e) => handleSelectChange(e)}
          />
        </div>
      )}
    </div>
  );
};
const mapStateToProps = ({ powerVote = {} }) => {
  const usdValueEth = _.get(powerVote, "conversionFromUSDtoEth", undefined);
  const conversionLoading = _.get(
    powerVote,
    "conversionFromUSDtoEthLoading",
    false
  );
  return {
    usdValueEth,
    conversionLoading,
  };
};
const mapDispatchToProps = (dispatch) => ({
  // getAllPlatformPowerVotes: () =>
  //   dispatch({ type: "showAllPlatformPowerVotesSagaCalled" }),
  convertUSDToEth: (data) =>
    dispatch({ type: "conversionFromUTESagaCalled", payload: data }),
  clearUSDToEth: () => dispatch(clearConversionFromUTS()),
  buyPowerVoteUsingEthAsync: (data) =>
    dispatch({ type: "buyPowerVotesUsingEthSagaCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(CurrencyDropdown);
