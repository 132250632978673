// import React from 'react';
// import ReactDOM from 'react-dom';
// import './index.css';
// import App from './App';
// import reportWebVitals from './reportWebVitals';

// import { createRoot } from 'react-dom/client';

// ReactDOM.render(
//   <React.StrictMode>
//     <ErrorWrapper>
//     <App />
//     </ErrorWrapper>
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();





// const render = () => {
//   const container = document.getElementById('root');
//   const root = createRoot(container); // createRoot(container!) if you use TypeScript
//   root.render(<App />);
// }

// render();

// if (process.env.NODE_ENV === "development" && module.hot) {
//   module.hot.accept("./App", render);
// }

import { createRoot } from 'react-dom/client';

const App = require("./App").default;

const render = () => {
  const container = document.getElementById('root');
  const root = createRoot(container); // createRoot(container!) if you use TypeScript
  root.render(<App />);
}

render();

if (process.env.NODE_ENV === "development" && module.hot) {
  module.hot.accept("./App", render);
}



