import { createSlice } from "@reduxjs/toolkit";

const categoryslice = createSlice({
  name: "Category",
  initialState: {
    categories: [],
    categoriesLoading: false,
    categoriesLoadingError: "",
    genre: [],
    genreLoading: false,
    genreLoadingError: undefined,
  },

  reducers: {
    categoriesLoading: (state, action) => {
      return {
        ...state,
        categoriesLoading: action.payload,
        categories: [],
        categoriesLoadingError: false,
      };
    },
    categoriesSuccess: (state, action) => {
      return {
        ...state,
        categoriesLoading: false,
        categories: action.payload,
        categoriesLoadingError: false,
      };
    },
    categoriesFail: (state, action) => {
      return {
        ...state,
        categoriesLoading: false,
        categories: [],
        categoriesLoadingError: action.payload,
      };
    },

    genreLoading: (state, action) => {
      return {
        ...state,
        genreLoading: true,
        genre: [],
        genreLoadingError: undefined,
      };
    },
    genreSuccess: (state, action) => {
      return {
        ...state,
        genreLoading: false,
        genre: action.payload,
        genreLoadingError: undefined,
      };
    },
    genreFail: (state, action) => {
      return {
        ...state,
        genreLoading: false,
        genre: [],
        genreLoadingError: action.payload,
      };
    },
    
    //clear genre state
    clearAllGenre: (state, action) => {
      return {
        ...state,
        genreLoading: false,
        genre: [],
        genreLoadingError: undefined,
      };
    },

    clearCategoryRedux: (state, action) => {
      return {};
    },
  },
});

export default categoryslice.reducer;
export const {
  categoriesLoading,
  categoriesSuccess,
  categoriesFail,
  genreLoading,
  genreSuccess,
  genreFail,
  clearCategoryRedux,
  clearAllGenre,
} = categoryslice.actions;
