import React, { useEffect, useState } from "react";
import "@components/loader/LoaderStyle.scss";

function Loader({ loading, message }) {
  // const [loadingState, setLoadingState] = useState(loading);
  // useEffect(() => {
  //     setLoadingState(loading);
  //     if (loading == undefined) {
  //         setLoadingState(true);
  //     }
  //     return () => {
  //         setLoadingState(false);
  //     };
  // }, [loading]);
  return (
    <div className="loader">
      <div className={`fadeloader fadeinloader`}>
        <div id="preloader" className={`fadeinpreloader`}>
          <div className="twitch-loading">
            <span className="top-left-square"></span>
            <span className="top-right-square"></span>
            <span className="center-square"></span>
            <span className="bottom-left-square"></span>
            <span className="bottom-right-square"></span>
          </div>
          {message !== undefined && <p className="msg">{message}</p>}
        </div>
      </div>
    </div>
  );
}

export default Loader;
