import { call, put, takeLatest } from "redux-saga/effects";
import Api from "./../services/ApiCaller";
import { getAccessToken } from "src/utilities/utility";
import {
  createElectionTrigger,
  createElectionSuccess,
  createElectionFail,
  listElectionTrigger,
  listElectionSuccess,
  listElectionFail,
  editElectionTrigger,
  editElectionSuccess,
  editElectionFail,
  deleteElectionTrigger,
  deleteElectionSuccess,
  deleteElectionFail,
  storeSkipLimit,
  createAMAElectionStart,
  createAMAElectionSuccess,
  createAMAElectionFail,
  electionTop2ProjectsLoadingStart,
  electionTop2ProjectsSuccess,
  electionTop2ProjectsFailure,
  allPlatformProjectLoadingStart,
  allPlatformProjectsSuccess,
  allPlatformProjectFailure,
  allPlatformUserLoadingStart,
  allPlatformUserSuccess,
  allPlatformUserFailure,
  changeStatusOfUserByAdminLoading,
  changeStatusOfUserByAdminSuccess,
  changeStatusOfUserByAdminFailure,
  changeStatusOfProjectByAdminLoadingStart,
  changeStatusOfProjectByAdminSuccess,
  changeStatusOfProjectsByAdminFailure,
  adminGetReportLoadingStart,
  adminGetReportSuccess,
  adminGetReportFailure,
  deleteReportByAdminLoadingStart,
  deleteReportByAdminSuccess,
  deleteReportByAdminFailure,
  adminbannerLoadingStart,
  adminbannerEditSuccess,
  adminbannerSuccess,
  adminbannerFailure,
  adminBalanceSetLoading,
  adminBalanceSetSuccess,
  adminBalanceSetFailure,
  addSpotlightUserLoadingStart,
  addSpotlightUserSuccess,
  addSpotlightUserFailure,
  singleElectionDetailLoadingStart,
  singleElectionDetailSuccess,
  singleElectionDetailFailure,
} from "./admin_redux";

export function* createElectionSaga(api, { payload }) {
  yield put(createElectionTrigger());
  const { dataForBackend, callBackSuccess } = payload;
  const { skipLimit, callBack, type } = dataForBackend;
  delete dataForBackend["skipLimit"];
  delete dataForBackend["callBack"];
  delete dataForBackend["type"];
  delete payload["callBackSuccess"];
  let token = yield getAccessToken();
  try {
    const data = yield call(
      Api.callServer,
      api.CreatElectionApi,
      {
        details: dataForBackend,
        token,
      },
      true
    );

    yield put(createElectionSuccess());
    callBack();
    yield call(listElectionSaga, api, { payload: skipLimit, type });
    callBackSuccess();
  } catch (e) {
    yield put(createElectionFail(e.error));
  }
}

export function* listElectionSaga(api, { payload }) {
  yield put(listElectionTrigger());
  let token = yield getAccessToken();
  try {
    const data = yield call(
      Api.callServer,
      api.GetElectionApi,
      {
        details: payload,
        token,
      },
      true
    );

    yield put(listElectionSuccess(data.result));
    yield put(storeSkipLimit(payload));
  } catch (e) {
    yield put(listElectionFail(e.error));
  }
}

export function* editElectionSaga(api, { payload }) {
  const { dataForBackend, callBackSuccess } = payload;
  const { callBack, skipLimit, type } = dataForBackend;
  delete dataForBackend["callBack"];
  delete dataForBackend["type"];
  delete payload["callBackSuccess"];
  yield put(editElectionTrigger());
  let token = yield getAccessToken();
  try {
    const data = yield call(
      Api.callServer,
      api.EditElectionApi,
      {
        details: dataForBackend,
        token,
      },
      true
    );

    yield put(editElectionSuccess());
    callBack();
    yield call(listElectionSaga, api, { payload: skipLimit, type });
    callBackSuccess();
  } catch (e) {
    yield put(editElectionFail(e.error));
  }
}
export function* deleteElectionSaga(api, { payload }) {
  yield put(deleteElectionTrigger());
  const { electionId, callBack, skipLimit, type } = payload;
  delete payload["type"];
  let token = yield getAccessToken();
  try {
    const data = yield call(
      Api.callServer,
      api.DeleteElectionApi,
      {
        details: electionId,
        token,
      },
      true
    );

    yield put(deleteElectionSuccess());
    callBack();
    yield call(listElectionSaga, api, { payload: skipLimit, type });
  } catch (e) {
    yield put(deleteElectionFail(e.error));
  }
}

export function* createAMASaga(api, { payload }) {
  yield createAMAElectionStart();
  const { skipLimit, callBack, type } = payload;
  delete payload["callBack"];
  delete payload["type"];
  let token = yield getAccessToken();
  try {
    const result = yield call(Api.callServer, api.AMAElectionCreation, {
      details: payload,
      token,
    });

    yield put(createAMAElectionSuccess());
    callBack();
    yield call(listElectionSaga, api, { payload: skipLimit, type });
  } catch (e) {
    yield put(createAMAElectionFail(e.error));
  }
}
export function* Top2ProjectElectionsSaga(api, { payload }) {
  yield put(electionTop2ProjectsLoadingStart());
  const { callBack } = payload;
  delete payload["callBack"];
  let token = yield getAccessToken();
  try {
    const result = yield call(
      Api.callServer,
      api.ElectionTop2Projects,
      {
        details: payload,
        token,
      },
      true
    );

    yield put(electionTop2ProjectsSuccess(result.result));
    callBack();
  } catch (e) {
    yield put(electionTop2ProjectsFailure(e.error));
  }
}

export function* AllPlatformProjectSaga(api, { payload }) {
  yield put(allPlatformProjectLoadingStart());
  const token = yield getAccessToken();
  try {
    const result = yield call(
      Api.callServer,
      api.GetAllPlatformProjectsForAdmin,
      {
        details: payload,
        token,
      },
      true
    );

    yield put(allPlatformProjectsSuccess(result.result));
  } catch (e) {
    yield put(allPlatformProjectFailure(e.error));
  }
}

export function* AllPlatformUserSaga(api, { payload }) {
  yield put(allPlatformUserLoadingStart());
  const token = yield getAccessToken();
  try {
    const result = yield call(
      Api.callServer,
      api.GetAllPlatformUsersForAdmin,
      {
        details: payload,
        token,
      },
      true
    );

    yield put(allPlatformUserSuccess(result.result));
  } catch (e) {
    yield put(allPlatformUserFailure(e.error));
  }
}

export function* ChangeUserStatusByAdminSaga(api, { payload }) {
  yield put(changeStatusOfUserByAdminLoading());
  let token = yield getAccessToken();
  const { callBack, skip, limit, userType } = payload;
  delete payload["callBack"];
  delete payload["skip"];
  delete payload["limit"];
  delete payload["userType"];

  try {
    const result = yield call(
      Api.callServer,
      api.ChangeUserStatusByAdminApi,
      {
        details: payload,
        token,
      },
      true
    );
    yield put(changeStatusOfUserByAdminSuccess());

    yield call(AllPlatformUserSaga, api, {
      payload: { userType, skip, limit },
    });
    callBack();
  } catch (e) {
    yield put(changeStatusOfUserByAdminFailure(e.error));
  }
}

export function* ChangeProjectStatusByAdminSaga(api, { payload }) {
  yield put(changeStatusOfProjectByAdminLoadingStart());
  let token = yield getAccessToken();
  const { callBack, skip, limit, projectType } = payload;
  delete payload["callBack"];
  delete payload["skip"];
  delete payload["limit"];
  delete payload["projectType"];
  try {
    const result = yield call(
      Api.callServer,
      api.ChangeProjectStatusByAdminApi,
      {
        details: payload,
        token,
      },
      true
    );
    yield put(changeStatusOfProjectByAdminSuccess());
    yield call(AllPlatformProjectSaga, api, {
      payload: { projectType, skip, limit },
    });
    callBack();
  } catch (e) {
    yield put(changeStatusOfProjectsByAdminFailure(e.error));
  }
}

export function* GetAdminReportSaga(api, { payload }) {
  yield put(adminGetReportLoadingStart());
  let token = yield getAccessToken();
  try {
    const data = yield call(
      Api.callServer,
      api.GetAllReportsForAdmin,
      {
        details: payload,
        token,
      },
      true
    );

    yield put(adminGetReportSuccess(data.result));
    // yield put(storeSkipLimit(payload));
  } catch (e) {
    yield put(adminGetReportFailure(e.error));
  }
}

export function* DeleteReportByAdminSaga(api, { payload }) {
  yield put(deleteReportByAdminLoadingStart());
  let token = yield getAccessToken();
  const { type, skip, limit, callBack } = payload;
  delete payload["type"];
  delete payload["skip"];
  delete payload["limit"];
  delete payload["callBack"];
  try {
    const result = yield call(
      Api.callServer,
      api.DeleteReportByAdmin,
      {
        details: payload,
        token,
      },
      true
    );
    yield put(deleteReportByAdminSuccess());
    callBack();
    yield call(GetAdminReportSaga, api, { payload: { type, skip, limit } });
  } catch (e) {
    yield put(deleteReportByAdminFailure(e.error));
  }
}

export function* UpdatemanageBannerByAdminSaga(api, { payload }) {
  yield put(adminbannerLoadingStart());

  const { callBack } = payload;
  const token = yield getAccessToken();
  delete payload["callBack"];
  try {
    const result = yield call(
      Api.callServer,
      api.manageBanner,
      {
        details: payload,
        token,
      },
      true
    );

    yield put(adminbannerEditSuccess());
    callBack();
    yield call(GetbannerSaga, api, { payload: {} });
  } catch (e) {
    yield put(adminbannerFailure(e.error));
  }
}

export function* GetbannerSaga(api, { payload }) {
  yield put(adminbannerLoadingStart());

  try {
    const result = yield call(Api.callServer, api.Getbanner, {}, true);

    yield put(adminbannerSuccess(result.result));
  } catch (e) {
    yield put(adminbannerFailure(e.error));
  }
}

export function* AdminMinBalanceSetSaga(api, { payload }) {
  yield put(adminBalanceSetLoading());
  const { callBack } = payload;
  delete payload["callBack"];
  const token = yield getAccessToken();
  try {
    const result = yield call(
      Api.callServer,
      api.SetBalanceLimitByAdmin,
      {
        details: payload,
        token,
      },
      true
    );
    yield put(adminBalanceSetSuccess());

    callBack();
  } catch (e) {
    yield put(adminBalanceSetFailure(e.error));
  }
}

export function* SpotlightUserSaga(api, { payload }) {
  yield put(addSpotlightUserLoadingStart());
  let token = yield getAccessToken();
  const { callBack, userType, skip, limit } = payload;
  delete payload["callBack"];
  try {
    const result = yield call(
      Api.callServer,
      api.UsersToSpotlight,
      {
        details: payload,
        token,
      },
      true
    );
    yield put(addSpotlightUserSuccess());
    yield call(AllPlatformUserSaga, api, {
      payload: { userType, skip, limit },
    });
    yield callBack();
  } catch (e) {
    yield put(addSpotlightUserFailure(e.error));
  }
}

export function* SingleElectionDetailSaga(api, { payload }) {
  yield put(singleElectionDetailLoadingStart());
  try {
    const result = yield call(
      Api.callServer,
      api.GetSingleElectionDetails,
      payload,
      true
    );
    yield put(singleElectionDetailSuccess(result.result));
  } catch (e) {
    yield put(singleElectionDetailFailure(e.error));
  }
}
