import React, { useState, useRef, useEffect } from "react";
import { Modal, Container, Row, Form } from "react-bootstrap";
import * as _ from "lodash";
import {connect} from 'react-redux';
import{clearError} from '@store/user_redux';
import InputField from "@components/input-field/InputField";
import CustomButton from "src/components/custom-button/CustomButton";
import { ReactComponent as Upload } from "@images/create_add.svg";
import { ReactComponent as Edit } from "@images/create_edit.svg";
import "@components/form-popup/FormPopupStyle.scss";

const FormPopup = ({
  show,
  setShowModal,
  tempWalletAddress,
  createUserCalledAsync,
  userCreationLoading,
  userCreationErrorFromBackend,
  clearAllErrorFromUser,
}) => {
  const [Name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [image, setImage] = useState();
  const [walletAddress, setWalletAddress] = useState("");
  const [validated, setValidated] = useState(false);
  const photoRef = useRef();
  const [showImage, setShowImage] = useState(false);
  const [imageurl, setImageurl] = useState("");
  const [file, setFile] = useState();
  const [nameError , setNameError] = useState("");
  const [emailError , setEmailError] = useState("");
  const [userNameError , setUserNameError] = useState("");
  const [fileError , setFileError] = useState("");
  let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

  const handleImageChange = (e) => {
    e.preventDefault();
    let reader = new FileReader();
    let file = e.target.files[0];
    setFile(file);
    reader.onloadend = () => {
      setImage(file)
      setImageurl(reader.result);
      setShowImage(true);
    };
    reader && reader.readAsDataURL(file);
  };

// useEffect section starts
useEffect(()=>{
  clearAllErrorFromUser()
} , [])



useEffect(()=>{
if(Name.length >0){
setNameError("")
}
} , [Name])
useEffect(()=>{
  
  if(validRegex.test(email)){
    setEmailError("")
  }
} , [email])

useEffect(()=>{
  if(file !== undefined){
    setFileError("")
  }
} , [file])
useEffect(()=>{
  if(userName.length > 0){
    setUserNameError("")
  }

} ,[userName])

// useEffect section ends
const onFormSubmit = (event)=>{
 
  
  event.preventDefault()
    event.stopPropagation()
  const form = event.currentTarget
  if(form.checkValidity() === false){
    if(file === undefined ){
      setFileError("* Profile image is mandatory")
    }else{
      setFileError("")
    }
    if(Name === ""){
      setNameError("* Name is mandatory")
    }else{
      setNameError("")
    }
   
    if(validRegex.test(email) === false){
      setEmailError("* Please enter a valid email")
    }else{
      setEmailError("")
    }
    if(userName === ""){
      setUserNameError("* Twitter user name is mandatory")
    }else{
      setUserNameError("")
    }
    event.preventDefault()
    event.stopPropagation()
    setValidated(false)
   
    
  }
  else{
    event.preventDefault()
      event.stopPropagation()
      setValidated(true)
      createUser()
  }

}

const createUser = ()=>{
 
  if(file !== undefined ){
   
    const data = {
      walletAddress : tempWalletAddress !== undefined && tempWalletAddress,
      name : Name,
      file : file ,
      twitterUserName : userName,
      email : email
    }
    createUserCalledAsync(data)
  }else{
    setFileError("* Profile is mandatory")
  }

  


 
}





  return (
    <Modal
      className="FormPopup"
      show={show}
      onHide={() => {
        setShowModal();
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <Container>
          <Row>
            <Form noValidate onSubmit={onFormSubmit} validated={validated}>
              <div className="outercontainer">
                <div className="outer-div">
                  <div
                    className="logo-imgdiv"
                    onClick={() => photoRef.current.click()}
                  >
                    <input
                      type="file"
                      className="visually-hidden"
                      ref={photoRef}
                      onChange={(e) => handleImageChange(e)}
                      isInvalid={validated === false}
                      error={fileError}

                    />

                    {showImage && imageurl !== undefined ? (
                      <img src={imageurl} className="logo-imgappearance" />
                    ) : (
                      
                      <Upload className="upload" />
                    )}
                    <Edit className="edit-icon" />
                  </div>
                  <div className="img-type">Profile Image</div>
                 <div className="error-text">{fileError.length > 0 && fileError}</div>
                </div>
                <div className="titles">
                  <div className="inputbox">
                    <InputField
                      placeholder={"Name"}
                      type="text"
                      onChange={(e) => setName(e.target.value)}
                      value={Name}
                      required
                      isInvalid={validated === false}
                      error={nameError}
                    />
                  </div>
                </div>
                <div className="titles">
                  <div className="inputbox">
                    <InputField
                      placeholder={"Email"}
                      type="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      required
                      isInvalid={validated === false}
                      error={emailError}
                    />
                  </div>
                </div>
                <div className="titles">
                  <div className="inputbox">
                    <InputField
                      placeholder={"Twitter User Name"}
                      type="text"
                      onChange={(e) => setUserName(e.target.value)}
                      value={userName}
                      required
                      isInvalid={validated === false}
                      error={userNameError}
                    />
                  </div>
                </div>
                <div className="titles">
                  <div className="inputbox">
                    <InputField
                      placeholder={"Wallet Address"}
                      type="text"
                      value={tempWalletAddress !== undefined && `${tempWalletAddress.substring(0,10)}....${tempWalletAddress.substring(tempWalletAddress.length - 10, tempWalletAddress.length)}`}
                      disabled={tempWalletAddress !== undefined}
                      required
                    
                    />
                  </div>
                </div>
                <div className="btn-div">
                  <CustomButton
                    filled
                    name="Submit"
                    type="submit"
                    primary
                    classname={"submit-btn"}
                    disable={userCreationLoading}
                    
                  />
                    <div className="error-text error-div">{userCreationErrorFromBackend !== "" && userCreationErrorFromBackend}</div>
                </div>
              
              </div>
            </Form>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

const mapStateToProps = ({ user = {} }) => {
  const tempWalletAddress = _.get(user, "tempWalletAdd", undefined);
  const userCreationLoading = _.get(user , "createUserLoading" , false);
  const userCreationErrorFromBackend = _.get(user , "createUserError" , "");



  return {
    tempWalletAddress,
    userCreationLoading,
    userCreationErrorFromBackend,
  }
}

const mapDispatchToProps = (dispatch) => ({
  createUserCalledAsync :(data)=> dispatch({type : "creatingNewUserSagaCalled" , payload : data}),
  clearAllErrorFromUser : ()=>dispatch(clearError())

})
export default connect(mapStateToProps, mapDispatchToProps)(React.memo(FormPopup));
