import React, { useState, useEffect } from "react";
import { persistor, store } from "@store";
import { Fragment, lazy, Suspense } from "react";
import { Provider, useSelector } from "react-redux";
import { isExpired, decodeToken } from "react-jwt";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { PersistGate } from "redux-persist/lib/integration/react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-multi-carousel/lib/styles.css";
import "react-toastify/dist/ReactToastify.css";
// import Homepage from "./features/home-page/Homepage";
import Loader from "@components/loader/Loader";
import ErrorWrapper from "./components/ErrorBoundary/ErrorWrapper";

import { clearUserRedux } from "@store/user_redux";

import { clearAdminRedux } from "@store/admin_redux";
import { clearCategoryRedux } from "@store/category_redux";

import { clearProjectRedux } from "@store/project_redux";
import { clearCompetitionRedux } from "@store/competetion_redux";
import {
  clearPowerVoteRedux,
  clearDonationRedux,
} from "@store/powerVote_redux";
import { clearAppRedux } from "@store/app_redux";
// Lazy import started
// homepage
// myprofile
// adminelection
// manageBanner

const Homepage = lazy(() => import("./features/home-page/Homepage"));
const MyProfile = lazy(() => import("./features/my-profile/MyProfile"));
const AdminElection = lazy(() =>
  import("./features/admin-election/AdminElection")
);

const ManageBanner = lazy(() =>
  import("./features/manage-banner/ManageBanner")
);
const MyProjects = lazy(() => import("./features/my-projects/MyProjects"));
const MyReward = lazy(() => import("./features/Reward/MyReward"));
const CreateProject = lazy(() =>
  import("./components/create-project/CreateProject")
);
const EditProject = lazy(() => import("./components/edit-project/EditProject"));
const Competitions = lazy(() => import("./features/competitions/Competitions"));
const CompetitionDetails = lazy(() =>
  import("./components/competition-details/CompetitionDetails")
);
const ProjectPage = lazy(() => import("./components/project-page/ProjectPage"));
const Search = lazy(() => import("./features/search/Search"));
const FundingCompetition = lazy(() =>
  import("./features/funding-competition/FundingCompetition")
);
const DonateScreen = lazy(() => import("./components/donate/DonateScreen"));
const MyDonations = lazy(() => import("./features/my-donations/MyDonations"));
const MyDonationDetails = lazy(() =>
  import("./components/my-donation-detail/MyDonationDetails")
);
const LiveRanking = lazy(() => import("./features/live-ranking/LiveRanking"));
const AdminProjects = lazy(() =>
  import("./features/admin-projects/AdminProjects")
);
const AdminUsers = lazy(() => import("./features/admin-users/AdminUsers"));
const AdminReports = lazy(() =>
  import("./features/admin-reports/AdminReports")
);
const AdminRanking = lazy(() =>
  import("./features/admin-ranking/AdminRanking")
);
const AdminBalanceSetup = lazy(() =>
  import("./features/admin-balance-setup/AdminBalanceSetup")
);
import CreatorDetailsPage from "./components/creator-detail-page/CreatorDetailsPage";
// import AdminBalanceSetup from "./features/admin-balance-setup/AdminBalanceSetup";
import AllProjects from "./components/all-projects/AllProjects";
import ProjectDetailsScreen from "./components/project-details-screen/ProjectDetailsScreen";
import AdminVotes from "./features/admin-votes/AdminVotes";
const MyPowerVote = lazy(() => import("./features/my-power-vote/MyPowerVote"));
const SignIn = lazy(() => import("./features/onboarding/sign-in/Sign-In"));
const SignUp = lazy(() => import("./features/onboarding/sign-up/SignUp"));
const ResetPassword = lazy(() =>
  import("./features/onboarding/reset-password/ResetPassword")
);
const EmailVerification = lazy(() =>
  import("./features/email-verification/EmailVerification")
);
const UserVerification = lazy(() =>
  import("./features/user-verification/UserVerification")
);
const ResetPasswordSuccess = lazy(() =>
  import("./features/onboarding/reset-password-success/ResetPasswordSuccess")
);
const Top50Projects = lazy(() => import("./features/top50/top50"));
const Top50Creators = lazy(()=>import("./features/top50-creators/TopCreators"));
const DonatedByUser = lazy(() =>
  import("./features/donatedByUser/donatedByUser")
);
const SearchPage = lazy(() => import("./features/search-page/SearchPage"));
const Notifications = lazy(() =>
  import("./features/notifications/Notifications")
);
const MyVotingHistory = lazy(() =>
  import("./features/my-voting-history/MyVotingHistory")
);
const OnRampStripe = lazy(() => import("./features/on-ramp/Main"));
const SuccessResponse = lazy (() => import("./features/success-response/SuccessResponse"));
const FailedResponse = lazy (() => import("./features/failed-response/FailedResponse"));

import "./App.scss";


function App() {
  const PrivateRoute = ({ children }) => {
    const auth = useSelector((state) => state.user);
    let Authorized = auth !== undefined && auth.accessToken;
    return Authorized ? children : <Navigate to="/" />;
  };

  return (
    <ErrorWrapper>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router>
            <Suspense fallback={<Loader />}>
              <Routes>
                <Route exact path="/" element={<Homepage />} />
                <Route exact path="/signin" element={<SignIn />} />
                <Route exact path="/signup" element={<SignUp />} />
                <Route
                  exact
                  path="/reset-password"
                  element={<ResetPassword />}
                />
                <Route
                  exact
                  path="/email-verification"
                  element={<EmailVerification />}
                />
                <Route
                  exact
                  path="/user-verification"
                  element={<UserVerification />}
                />
                <Route
                  exact
                  path="/reset-password-success"
                  element={<ResetPasswordSuccess />}
                />
                <Route exact path="/my-reward" element={<MyReward />} />
                <Route
                  exact
                  path="/myprofile"
                  element={
                    <PrivateRoute>
                      <MyProfile />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/mypowervote"
                  element={
                    <PrivateRoute>
                      <MyPowerVote />
                    </PrivateRoute>
                  }
                />
                {/* <Route exact path="/admin_election" element={<AdminPrivateRoute><AdminElection/></AdminPrivateRoute>} /> */}
                <Route
                  exact
                  path="/admin/competition"
                  element={
                    <PrivateRoute>
                      <AdminElection />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/admin/manage-banner"
                  element={
                    <PrivateRoute>
                      <ManageBanner />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/admin/projects"
                  element={
                    <PrivateRoute>
                      <AdminProjects />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/admin/users"
                  element={
                    <PrivateRoute>
                      <AdminUsers />
                    </PrivateRoute>
                  }
                />
                <Route exact path="/buy-crypto" element={<OnRampStripe />} />
                <Route
                  exact
                  path="/admin/reports"
                  element={
                    <PrivateRoute>
                      <AdminReports />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/admin/ranking"
                  element={
                    <PrivateRoute>
                      <AdminRanking />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/admin/donations"
                  element={
                    <PrivateRoute>
                      <MyDonations />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/admin/votes"
                  element={
                    <PrivateRoute>
                      <AdminVotes />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/admin/balance-setup"
                  element={
                    <PrivateRoute>
                      <AdminBalanceSetup />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/myprojects"
                  element={
                    <PrivateRoute>
                      <MyProjects />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/createproject"
                  element={
                    <PrivateRoute>
                      <CreateProject />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/editproject"
                  element={
                    <PrivateRoute>
                      <EditProject />
                    </PrivateRoute>
                  }
                />
                <Route exact path="/competitions" element={<Competitions />} />
                <Route
                  exact
                  path="/competition-details/:id"
                  element={<CompetitionDetails />}
                />
                <Route
                  exact
                  path="/project-page/:id"
                  element={<ProjectPage />}
                />
                <Route exact path="/top-projects" element={<Top50Projects />} />
                <Route exact path="/top-creators" element={<Top50Creators />} />
                <Route exact path="/search" element={<Search />} />
                <Route
                  exact
                  path="/funding_competition"
                  element={
                    <PrivateRoute>
                      <FundingCompetition />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/donate/:id"
                  element={
                    <PrivateRoute>
                      <DonateScreen />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/received-donation"
                  element={
                    <PrivateRoute>
                      <MyDonations />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/mydonations"
                  element={
                    // <PrivateRoute>
                    <DonatedByUser />
                    // </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/mydonation_details"
                  element={
                    <PrivateRoute>
                      <MyDonationDetails />
                    </PrivateRoute>
                  }
                />
                <Route exact path="/live_ranking" element={<LiveRanking />} />
               
                <Route
                  exact
                  path="/creator-details/:id"
                  element={
                    // <Route>
                    <CreatorDetailsPage />
                    // </Route>
                  }
                />
                <Route
                  exact
                  path="/project/see-all"
                  element={<AllProjects />}
                />
                <Route
                  exact
                  path="/project-details/:id"
                  element={<ProjectDetailsScreen />}
                />
                <Route exact path="/search-page" element={<SearchPage />} />
                <Route
                  exact
                  path="/notifications"
                  element={<Notifications />}
                />
                <Route
                  exact
                  path="/myvotinghistory"
                  element={
                    <PrivateRoute>
                      <MyVotingHistory />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/payment-success"
                  element={
                    <PrivateRoute>
                      <SuccessResponse />
                    </PrivateRoute>
                  }
                />
                <Route
                  exact
                  path="/payment-failed"
                  element={
                    <PrivateRoute>
                      <FailedResponse />
                    </PrivateRoute>
                  }
                />
              </Routes>
            </Suspense>
          </Router>
        </PersistGate>
      </Provider>
    </ErrorWrapper>
  );
}

export default App;
