import { call, put } from "redux-saga/effects";
import Api from "./../services/ApiCaller";
import { getAccessToken } from "src/utilities/utility";
import {
  loginTrigger,
  loginSuccess,
  navigateToForm,
  loginFail,
  creatingUserTrigger,
  creatingUserFail,
  saveTempWalletAdd,
  clearTempWalletAdd,
  createUserSuccess,
  editProfileInputStart,
  editProfileInputSuccess,
  editProfileInputFail,
  editProfileImageLoading,
  editProfileImageSuccess,
  editProfileImageFail,
  closeNavigateToForm,
  myTopFundedProjectLoadingStart,
  myTopFundeProjectSuccess,
  myTopFundedProjectFailure,
  myParticipatedElectionLoadingStart,
  myParticipatedElectionSuccess,
  myParticipatedElectionFailure,
  reportCreationLoadingStart,
  reportCreationSuccess,
  reportCreationFailure,
  searchLoadingStart,
  searchResultSuccess,
  searchResultFailure,
  signleUserDetailLoadingStart,
  singleUserDetailSuccess,
  singleUserDetailFailure,

  // onboarding phase 2 states //////////////////////////////////
  loginInLoadingStart,
  loginSuccessPhase2,
  loginFailure,
  signUpStart,
  signUpSuccess,
  signUpFailure,
  setUserDetailsStart,
  setUserDetailsSuccess,
  setUserDetailsFailure,
  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  resendVerificationStart,
  resendVerificationSuccess,
  resendVerificationFailure,
  setNewPassWordLoadingStart,
  setNewPasswordSuccess,
  setNewPasswordFailure,
  verifyEmailLoadingStart,
  verifyEmailSuccess,
  verifyEmailFailure,
  passGoogleAccessTokenLoadingStart,
  passGoogleAccessTokenSuccess,
  passGoogleAccessTokenFailure,
  LoginGoogleAccessTokenLoadingStart,
  LoginGoogleAccessTokenSuccess,
  LoginGoogleAccessTokenFailure,
  clearUserDetails,
  clearUserRedux,
} from "./user_redux";
import { PowerVotesOwnedByUserSaga } from "./powerVote_saga";

export function* ClearUserDetailsSaga(api, { payload }) {
  try {
    yield put(clearUserRedux());
    yield put(clearUserDetails());
    window.location.href = "/signin";
  } catch (e) {
    console.log("error", e);
  }
}

export function* usersignIn(api, { payload }) {
  yield put(loginTrigger(true));
  // const {metaMaskConnected} = payload;
  if (payload.tobeChecked === false) {
    yield put(saveTempWalletAdd(payload.userWalletAdd));
  }

  try {
    const result = yield call(
      Api.callServer,
      api.loginApi,
      {
        walletAddress: payload.userWalletAdd,
      },
      true
    );

    if (result.status !== 0) {
      yield put(loginSuccess(result.result));
      yield put(closeNavigateToForm());
      yield call(PowerVotesOwnedByUserSaga, api, payload);
    } else {
      yield put(navigateToForm());

      // history.push('/')
    }
    // yield call(walletBalanceSaga,api,result.walletAddress)
  } catch (e) {
    yield put(loginFail(e.message));
  }
}

export function* userCreation(api, { payload }) {
  yield put(creatingUserTrigger());
  try {
    const result = yield call(Api.callServer, api.createUserApi, payload, true);

    yield put(createUserSuccess(result.result));

    yield call(usersignIn, api, {
      payload: {
        userWalletAdd: result.result.walletAddress,
        tobeChecked: true,
      },
    });
    yield put(clearTempWalletAdd());
  } catch (e) {
    yield put(creatingUserFail(e.message[0]));
  }
}

export function* EditProfileInputSaga(api, { payload }) {
  yield put(editProfileInputStart());
  let token = yield getAccessToken();
  const { openSuccessPopup } = payload;
  try {
    const result = yield call(
      Api.callServer,
      api.EditProfileInput,
      {
        details: payload,
        token,
      },
      true
    );

    yield put(editProfileInputSuccess(result.result));
    openSuccessPopup();
  } catch (e) {
    yield put(editProfileInputFail(e.error));
  }
}

export function* EditProfileImageSaga(api, { payload }) {
  yield put(editProfileImageLoading());

  delete payload["openSuccessPopup"];
  let token = yield getAccessToken();
  try {
    const result = yield call(
      Api.callServer,
      api.EditProfileImage,
      {
        details: payload,
        token,
      },
      true
    );

    yield put(editProfileImageSuccess(result.result));
  } catch (e) {
    yield put(editProfileImageFail(e.error));
  }
}

export function* MyTopFundedProjectSaga(api, { payload }) {
  yield put(myTopFundedProjectLoadingStart());
  try {
    let token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api,
      {
        details: payload,
        token,
      },
      true
    );
    yield put(myTopFundeProjectSuccess(result.result));
  } catch (e) {
    yield put(myTopFundedProjectFailure(e.error));
  }
}

export function* MyParticipatedElections(api, { payload }) {
  yield put(myParticipatedElectionLoadingStart());
  let token = yield getAccessToken();
  try {
    const result = yield call(
      Api.callServer,
      api.MyParticipatedElections,
      {
        token,
      },
      true
    );
    yield put(myParticipatedElectionSuccess(result.result));
  } catch (e) {
    yield put(myParticipatedElectionFailure(e.error));
  }
}

export function* ReportAProjectOrUser(api, { payload }) {
  yield put(reportCreationLoadingStart());
  let token = yield getAccessToken();
  const { callBack } = payload;
  delete payload["callBack"];
  try {
    const result = yield call(
      Api.callServer,
      api.CreateAReport,
      {
        details: payload,
        token,
      },
      true
    );
    yield put(reportCreationSuccess());
    callBack();
  } catch (e) {
    yield put(reportCreationFailure(e.error));
  }
}

export function* GlobalSearchSaga(api, { payload }) {
  yield put(searchLoadingStart());
  const { callBack } = payload;
  delete payload["callBack"];
  let token = yield getAccessToken();
  try {
    const result = yield call(
      Api.callServer,
      api.GlobalSearch,
      {
        details: payload,
        token,
      },
      true
    );

    yield put(searchResultSuccess(result.result));
    callBack(result.result);
  } catch (e) {
    yield put(searchResultFailure(e.error));
  }
}

export function* SingleUserDetailSaga(api, { payload }) {
  yield put(signleUserDetailLoadingStart());
  try {
    const result = yield call(
      Api.callServer,
      api.GetSingleUserDetails,
      payload,
      true
    );
    yield put(singleUserDetailSuccess(result.result));
  } catch (e) {
    yield put(singleUserDetailFailure(e.error));
  }
}

//////////saga functions for onboarding phase 2 //////////////////////

export function* setUserProfileInfoSaga(
  api,
  { accessToken, link, loginResult, redirectTo }
) {
  yield put(setUserDetailsStart());
  try {
    const result = yield call(
      Api.callServer,
      api.getMyProfile,
      { accessToken: accessToken },
      true
    );

    if (result.emailVerified === true) {
      if (redirectTo === "bookscreen") {
        yield put(loginSuccessPhase2(loginResult));
        yield put(setUserDetailsSuccess(result));
        yield put(profileDataSuccess(result));
        link.goBack();
      } else {
        yield put(loginSuccessPhase2(loginResult));
        yield put(setUserDetailsSuccess(result));
        yield put(profileDataSuccess(result));
        link.push("/");
      }
    } else {
      yield put(loginSuccessPhase2(loginResult));
      yield put(setUserDetailsSuccess());
      link.push("/user-verification");
    }
  } catch (err) {
    yield put(setUserDetailsFailure());
  }
}

export function* userLoginSaga(api, { payload }) {
  yield put(loginInLoadingStart());
  const { navigationTo } = payload;
  delete payload["navigationTo"];
  try {
    const result = yield call(
      Api.callServer,
      api.SignInUsingEmail,
      payload,
      true
    );

    yield put(loginSuccessPhase2(result.result));
    if (result.result.userDetails.emailVerified === true) {
      navigationTo("/");
    } else {
      navigationTo("/user-verification");
    }
  } catch (err) {
    // console.log("e.errror", err.error);
    yield put(loginFailure(err.error));
  }
}

export function* userSignUpSaga(api, { payload }) {
  yield put(signUpStart());
  const { openSuccessPopup } = payload;

  delete payload["openSuccessPopup"];
  try {
    const result = yield call(
      Api.callServer,
      api.SignUpUsingEmail,
      payload,
      true
    );
    yield put(signUpSuccess(result.result));
    openSuccessPopup();
    // link.push("/login");
  } catch (e) {
    yield put(signUpFailure(e.error));
  }
}

export function* requestPasswordResetSaga(api, { payload }) {
  yield put(forgotPasswordStart());
  const { openSuccessPopup } = payload;
  delete payload["openSuccessPopup"];
  try {
    const { email } = payload;
    const result = yield call(
      Api.callServer,
      api.ForgotPasswordRequest,
      payload,
      true
    );
    yield put(forgotPasswordSuccess(result.message));
    openSuccessPopup();
  } catch (err) {
    yield put(forgotPasswordFailure(err.error));
  }
}

export function* SetNewPasswordSaga(api, { payload }) {
  yield put(setNewPassWordLoadingStart());
  const { navigationTo } = payload;
  delete payload["navigationTo"];
  try {
    const result = yield call(
      Api.callServer,
      api.SetNewPassword,
      payload,
      true
    );
    yield put(setNewPasswordSuccess(result.result));
    navigationTo();
  } catch (e) {
    yield put(setNewPasswordFailure(e.error));
  }
}

export function* ResendVerificationMail(api, { payload }) {
  yield put(resendVerificationStart());
  const { openSuccess } = payload;
  const token = yield getAccessToken();
  try {
    const result = yield call(Api.callServer, api.ReverifyEmail, token, true);
    yield put(resendVerificationSuccess(result.result));
    openSuccess();
  } catch (e) {
    yield put(resendVerificationFailure(e.error));
  }
}
export function* VerifyEmailSaga(api, { payload }) {
  yield put(verifyEmailLoadingStart());
  try {
    const result = yield call(Api.callServer, api.VerifyEmail, {}, true);
    yield put(verifyEmailSuccess(result.result));
  } catch (e) {
    yield put(verifyEmailFailure(e.error));
  }
}

export function* googleAccessTokenSaga(api, { payload }) {
  // console.log("called");
  yield put(passGoogleAccessTokenLoadingStart());
  // const { link, redirectTo } = payload;
  // delete payload["link"];
  // delete payload["redirectTo"];
  console.log("signup saga");
  const { navigateTo } = payload;
  delete payload["navigateTo"];
  try {
    const result = yield call(Api.callServer, api.GoogleSignup, payload, true);

    yield put(passGoogleAccessTokenSuccess(result.result));
    navigateTo("/");
  } catch (e) {
    yield put(passGoogleAccessTokenFailure(e.error));
  }
}

export function* LoginGoogleAccessTokenSaga(api, { payload }) {
  // console.log("called");

  yield put(LoginGoogleAccessTokenLoadingStart());
  // const { link, redirectTo } = payload;
  // delete payload["link"];
  // delete payload["redirectTo"];
  const { navigateTo } = payload;
  delete payload["navigateTo"];
  try {
    const result = yield call(Api.callServer, api.GoogleLogin, payload, true);

    yield put(LoginGoogleAccessTokenSuccess(result.result));
    navigateTo("/");
  } catch (e) {
    yield put(LoginGoogleAccessTokenFailure(e.error));
  }
}
