import { call, put, takeLatest } from "redux-saga/effects";
import Api from "./../services/ApiCaller";
import { getAccessToken } from "src/utilities/utility";
import {
    publicCompetetionLoadingStart,
    publicCompetetionSuccess,
    publicCompetetionSuccessPlus1,
    publicCompetetionFail,
    electionGifLoadingStart,
    electionGifSuccess,
    electionGifFailure,
    publicCompetetionAmaLoadingStart,
    publicCompetetionAmaSuccess,
    publicCompetetionAmaFail,
} from './competetion_redux';



export function* GetPublicCompetetionSaga(api, { payload }) {

    yield put(publicCompetetionLoadingStart())
  


    try {
        const result = yield call(
            Api.callServer,
            api.GetPublicCompetetion,
            payload,
            true,
        )

        yield put(publicCompetetionSuccess(result.result));
        




    } catch (e) {
        yield put(publicCompetetionFail(e.error))
    }

}
export function* GetPublicCompetetionSagaForAma(api, { payload }) {

    yield put(publicCompetetionAmaLoadingStart())
  


    try {
        const result = yield call(
            Api.callServer,
            api.GetPublicCompetetion,
            payload,
            true,
        )

        yield put(publicCompetetionAmaSuccess(result.result));
        




    } catch (e) {
        yield put(publicCompetetionAmaFail(e.error))
    }

}
export function* GetPublicCompetetionSagaPlus1(api, { payload }) {

    yield put(publicCompetetionLoadingStart())
  
    delete payload["plus1"];


    try {
        const result = yield call(
            Api.callServer,
            api.GetPublicCompetetion,
            payload,
            true,
        )


        yield put(publicCompetetionSuccessPlus1(result.result))




    } catch (e) {
        yield put(publicCompetetionFail(e.error))
    }

}

export function* ElectionGifSaga(api, { payload }) {
    yield put(electionGifLoadingStart())
    const { currentMonth, currentYear, type } = payload;
    delete payload["currentMonth"];
    delete payload["currentYear"];
    delete payload["type"];
    try {
        const result = yield call(
            Api.callServer,
            api.GetElectionGif,
            payload,
            true,
        )

        yield put(electionGifSuccess());
        yield call(GetPublicCompetetionSaga, api, {
            payload: {
                currentMonth, currentYear, type
            }
        })


    } catch (e) {
        yield put(electionGifFailure(e.error));
    }
}