import { createSlice } from "@reduxjs/toolkit";

const userslice = createSlice({
  name: "User",
  initialState: {
    signInLoading: false,
    signInError: "",
    userInfo: undefined,
    signInSuccessWallet: false,
    createUserLoading: false,
    createUserError: "",
    editProfileInputLoading: undefined,
    editProfileInputError: undefined,
    editProfileImageLoading: undefined,
    editProfileImageError: undefined,
    myTopFundedProjectLoading: undefined,
    myTopFundedProject: [],
    myTopFundedProjectError: undefined,
    myParticipatedElectionLoading: undefined,
    myParticipatedElections: [],
    myParticipatedElectionsError: undefined,
    reportCreationLoading: undefined,
    reportCreationError: undefined,
    searchLoading: false,
    searchResult: [],
    serachError: undefined,
    singleUserDetailLoading: undefined,
    singleUserDetail: {},
    singleUserDetailError: undefined,
    disableConnectButton: false,

    //onboarding phase 2 // singInLoading and singInError from phase 1

    signedIn: undefined,
    signInStatus: false,
    userDetailsLoading: false,
    userDetails: undefined,
    userDetailsError: undefined,
    signUpLoading: undefined,
    signedUp: undefined,
    signUpError: undefined,
    forgotPasswordLoading: false,
    forgotPassword: undefined,
    forgotPasswordError: undefined,
    resendEmailVerificationLoading: false,
    resendEmailVerificationSuc: false,
    resendEmailVerificationError: undefined,

    setNewPasswordLoading: false,
    setNewPassword: undefined,
    setNewPasswordError: undefined,
    verifyEmailLoading: false,
    verifyEmail: undefined,
    verifyEmailError: undefined,
    passGoogleAccessTokenLoading: false,
    passGoogleAccessToken: undefined,
    passGoogleAccessTokenError: undefined,
    LoginGoogleAccessTokenLoading: false,
    LoginGoogleAccessToken: undefined,
    LoginGoogleAccessTokenError: undefined,
    myWalletAddressReduxState: "",
  },

  reducers: {
    // start of user actions
    disableConnectButtonAction: (state, action) => {
      return {
        ...state,
        disableConnectButton: action.payload,
      };
    },
    saveMyWalletAddress: (state, action) => {
      // console.log("action", action.payload);
      return {
        ...state,
        myWalletAddressReduxState: action.payload,
      };
    },
    clearMyWalletAddress: (state, action) => {
      return {
        ...state,
        myWalletAddressReduxState: "",
      };
    },

    creatingUserTrigger: (state, action) => {
      return {
        ...state,
        createUserLoading: true,
        createUserError: "",
      };
    },

    creatingUserFail: (state, action) => {
      return {
        ...state,
        createUserLoading: false,
        createUserError: action.payload,
      };
    },

    loginTrigger: (state, action) => {
      return {
        ...state,
        signInLoading: action.payload,
      };
    },

    loginSuccess: (state, action) => {
      return {
        ...state,
        signInLoading: false,
        userInfo: action.payload.userDetails,
        accessToken: action.payload.accessToken,
        signInSuccessWallet: true,
      };
    },
    createUserSuccess: (state, action) => {
      return {
        ...state,
        createUserLoading: false,
        createUserError: "",
      };
    },

    navigateToForm: (state, action) => {
      return {
        ...state,
        showFormForUser: true,
      };
    },
    saveTempWalletAdd: (state, action) => {
      return {
        ...state,
        tempWalletAdd: action.payload,
      };
    },
    clearTempWalletAdd: (state, action) => {
      return {
        ...state,
        tempWalletAdd: "",
      };
    },
    closeNavigateToForm: (state, action) => {
      return {
        ...state,
        showFormForUser: false,
      };
    },
    loginFail: (state, action) => {
      return {
        ...state,
        signInLoading: false,
        signInError: action.payload,
        signInSuccessWallet: false,
      };
    },
    editProfileInputStart: (state, action) => {
      return {
        ...state,
        editProfileInputLoading: true,
        editProfileInputError: undefined,
      };
    },
    editProfileInputSuccess: (state, action) => {
      return {
        ...state,
        editProfileInputLoading: false,
        userInfo: action.payload,
        editProfileInputError: undefined,
      };
    },
    editProfileInputFail: (state, action) => {
      return {
        ...state,
        editProfileInputLoading: false,
        editProfileInputError: action.payload,
      };
    },
    editProfileImageLoading: (state, action) => {
      return {
        ...state,
        editProfileImageLoading: true,

        editProfileImageError: undefined,
      };
    },
    editProfileImageSuccess: (state, action) => {
      return {
        ...state,
        editProfileImageLoading: false,
        userInfo: action.payload,
        editProfileImageError: undefined,
      };
    },
    editProfileImageFail: (state, action) => {
      return {
        ...state,
        editProfileImageLoading: false,
        editProfileImageError: action.payload,
      };
    },
    clearError: (state, action) => {
      return {
        ...state,
        signInError: undefined,
        createUserError: undefined,
        editProfileInputError: undefined,
        editProfileImageError: undefined,
        myTopFundedProjectError: undefined,
        myParticipatedElectionsError: undefined,
        reportCreationError: undefined,
        serachError: undefined,
        singleUserDetailError: undefined,
      };
    },

    myTopFundedProjectLoadingStart: (state, action) => {
      return {
        ...state,
        myTopFundedProjectLoading: true,
        myTopFundedProject: [],
        myTopFundedProjectError: undefined,
      };
    },
    myTopFundeProjectSuccess: (state, action) => {
      return {
        ...state,
        myTopFundedProjectLoading: false,
        myTopFundedProject: action.payload,
        myTopFundedProjectError: undefined,
      };
    },
    myTopFundedProjectFailure: (state, action) => {
      return {
        ...state,
        myTopFundedProjectLoading: false,
        myTopFundedProject: [],
        myTopFundedProjectError: action.payload,
      };
    },

    myParticipatedElectionLoadingStart: (state, action) => {
      return {
        ...state,
        myParticipatedElectionLoading: true,
        myParticipatedElections: [],
        myParticipatedElectionsError: undefined,
      };
    },
    myParticipatedElectionSuccess: (state, action) => {
      return {
        ...state,
        myParticipatedElectionLoading: false,
        myParticipatedElections: action.payload,
        myParticipatedElectionsError: undefined,
      };
    },
    myParticipatedElectionFailure: (state, action) => {
      return {
        ...state,
        myParticipatedElectionLoading: false,
        myParticipatedElections: [],
        myParticipatedElectionsError: action.payload,
      };
    },

    reportCreationLoadingStart: (state, action) => {
      return {
        ...state,
        reportCreationLoading: true,
        reportCreationError: undefined,
      };
    },
    reportCreationSuccess: (state, action) => {
      return {
        ...state,
        reportCreationLoading: false,
        reportCreationError: undefined,
      };
    },
    reportCreationFailure: (state, action) => {
      return {
        ...state,
        reportCreationLoading: false,
        reportCreationError: action.payload,
      };
    },
    clearUserRedux: (state, action) => {
      return {};
    },

    searchLoadingStart: (state, action) => {
      return {
        ...state,
        searchLoading: true,
        searchResult: [],
        serachError: undefined,
      };
    },
    searchResultSuccess: (state, action) => {
      return {
        ...state,
        searchLoading: false,
        searchResult: action.payload,
        serachError: undefined,
      };
    },
    searchResultFailure: (state, action) => {
      return {
        ...state,
        searchLoading: false,
        searchResult: [],
        serachError: action.payload,
      };
    },
    signleUserDetailLoadingStart: (state, action) => {
      return {
        ...state,
        singleUserDetailLoading: true,
        singleUserDetail: {},
        singleUserDetailError: undefined,
      };
    },
    singleUserDetailSuccess: (state, action) => {
      return {
        ...state,
        singleUserDetailLoading: false,
        singleUserDetail: action.payload,
        singleUserDetailError: undefined,
      };
    },
    singleUserDetailFailure: (state, action) => {
      return {
        ...state,
        singleUserDetailLoading: false,
        singleUserDetail: [],
        singleUserDetailError: action.payload,
      };
    },
    updateVOtes: (state, action) => {
      return {
        ...state,
        singleUserDetail: {
          ...state.singleUserDetail,
          gainedVoteCount: state.singleUserDetail.gainedVoteCount + 1,
        },
      };
    },
    //onboarding phase 2 reducers started

    loginInLoadingStart: (state, action) => {
      return {
        ...state,
        signInLoading: true,
        userInfo: undefined,
        signInStatus: false,
        signInError: undefined,
      };
    },
    loginSuccessPhase2: (state, action) => {
      return {
        ...state,

        signInLoading: false,
        userInfo: action.payload.userDetails,
        accessToken: action.payload.accessToken,
        signInStatus: true,
        signInError: undefined,
      };
    },
    loginFailure: (state, action) => {
      return {
        ...state,
        signInLoading: false,
        signedIn: undefined,
        userInfo: undefined,
        signInStatus: false,
        signInError: action.payload,
      };
    },
    signUpStart: (state, action) => {
      return {
        ...state,
        signUpLoading: true,
        signedUp: undefined,
        signUpError: undefined,
      };
    },
    signUpSuccess: (state, action) => {
      return {
        ...state,
        signUpLoading: false,
        signedUp: action.payload,
        signUpError: undefined,
      };
    },
    signUpFailure: (state, action) => {
      return {
        ...state,
        signUpLoading: false,
        signedUp: undefined,
        signUpError: action.payload,
      };
    },
    setUserDetailsStart: (state, action) => {
      return {
        ...state,
        userDetailsLoading: true,
        userDetails: undefined,
        userDetailsError: undefined,
      };
    },

    setUserDetailsSuccess: (state, action) => {
      return {
        ...state,
        userDetailsLoading: false,
        userDetails: action.payload,
        userDetailsError: undefined,
      };
    },

    setUserDetailsFailure: (state, action) => {
      return {
        ...state,
        userDetailsLoading: false,
        userDetails: undefined,
        userDetailsError: action.payload,
      };
    },
    forgotPasswordStart: (state, action) => {
      return {
        ...state,
        forgotPasswordLoading: true,
        forgotPassword: undefined,
        forgotPasswordError: undefined,
      };
    },
    forgotPasswordSuccess: (state, action) => {
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPassword: action.payload,
        forgotPasswordError: undefined,
      };
    },
    forgotPasswordFailure: (state, action) => {
      return {
        ...state,
        forgotPasswordLoading: false,
        forgotPassword: undefined,
        forgotPasswordError: action.payload,
      };
    },
    resendVerificationStart: (state, action) => {
      return {
        ...state,
        resendEmailVerificationLoading: true,
        resendEmailVerificationSuc: false,
        resendEmailVerificationError: undefined,
      };
    },
    resendVerificationSuccess: (state, action) => {
      return {
        ...state,
        resendEmailVerificationLoading: false,
        resendEmailVerificationSuc: true,
        resendEmailVerificationError: undefined,
      };
    },
    resendVerificationFailure: (state, action) => {
      return {
        ...state,
        resendEmailVerificationLoading: false,
        resendEmailVerificationSuc: false,
        resendEmailVerificationError: action.payload,
      };
    },

    setNewPassWordLoadingStart: (state, action) => {
      return {
        ...state,
        setNewPasswordLoading: true,
        setNewPassword: undefined,
        setNewPasswordError: undefined,
      };
    },
    setNewPasswordSuccess: (state, action) => {
      return {
        ...state,
        setNewPasswordLoading: false,
        setNewPassword: action.payload,
        setNewPasswordError: undefined,
      };
    },
    setNewPasswordFailure: (state, action) => {
      return {
        ...state,
        setNewPasswordLoading: false,
        setNewPassword: undefined,
        setNewPasswordError: action.payload,
      };
    },
    verifyEmailLoadingStart: (state, action) => {
      return {
        ...state,
        verifyEmailLoading: true,
        verifyEmail: undefined,
        verifyEmailError: undefined,
      };
    },
    verifyEmailSuccess: (state, action) => {
      return {
        ...state,
        verifyEmailLoading: false,
        verifyEmail: action.payload,
        verifyEmailError: undefined,
      };
    },
    verifyEmailFailure: (state, action) => {
      return {
        ...state,
        verifyEmailLoading: false,
        verifyEmail: undefined,
        verifyEmailError: action.payload,
      };
    },
    clearOnBoardingPhase2Error: (state, action) => {
      return {
        ...state,

        signInStatus: false,

        userDetailsError: undefined,
        signInError: undefined,

        signUpError: undefined,

        forgotPasswordError: undefined,

        resendEmailVerificationError: undefined,

        setNewPasswordError: undefined,

        verifyEmailError: undefined,
        passGoogleAccessTokenError: undefined,
        LoginGoogleAccessTokenError: undefined,
      };
    },
    resendEmailVerificationStateReset: (state, acion) => {
      return {
        ...state,
        resendEmailVerificationLoading: false,
        resendEmailVerificationSuc: false,
        resendEmailVerificationError: undefined,
      };
    },
    passGoogleAccessTokenLoadingStart: (state, action) => {
      return {
        ...state,
        passGoogleAccessTokenLoading: true,
        userInfo: undefined,
        passGoogleAccessTokenError: undefined,
      };
    },
    passGoogleAccessTokenSuccess: (state, action) => {
      return {
        ...state,
        passGoogleAccessTokenLoading: false,
        accessToken: action.payload.accessToken,
        userInfo: action.payload.userDetails,
        passGoogleAccessTokenError: undefined,
      };
    },
    passGoogleAccessTokenFailure: (state, action) => {
      return {
        ...state,
        passGoogleAccessTokenLoading: false,

        userInfo: undefined,
        passGoogleAccessTokenError: action.payload,
      };
    },

    LoginGoogleAccessTokenLoadingStart: (state, action) => {
      return {
        ...state,
        LoginGoogleAccessTokenLoading: true,
        userInfo: undefined,
        LoginGoogleAccessTokenError: undefined,
      };
    },
    LoginGoogleAccessTokenSuccess: (state, action) => {
      return {
        ...state,
        LoginGoogleAccessTokenLoading: false,
        accessToken: action.payload.accessToken,
        userInfo: action.payload.userDetails,
        LoginGoogleAccessTokenError: undefined,
      };
    },
    LoginGoogleAccessTokenFailure: (state, action) => {
      return {
        ...state,
        LoginGoogleAccessTokenLoading: false,
        userInfo: undefined,
        LoginGoogleAccessTokenError: action.payload,
      };
    },
    clearUserDetails: (state, action) => {
      return {
        ...state,
        userDetails: "",
      };
    },
    // end of user actions
  },
});

export default userslice.reducer;
export const {
  loginTrigger,
  loginSuccess,
  navigateToForm,
  closeNavigateToForm,
  saveTempWalletAdd,
  loginFail,
  creatingUserTrigger,
  creatingUserFail,
  clearTempWalletAdd,

  clearUserRedux,
  clearError,
  editProfileInputStart,
  editProfileInputSuccess,
  editProfileInputFail,
  editProfileImageLoading,
  editProfileImageSuccess,
  editProfileImageFail,
  createUserSuccess,
  myTopFundedProjectLoadingStart,
  myTopFundeProjectSuccess,
  myTopFundedProjectFailure,
  myParticipatedElectionLoadingStart,
  myParticipatedElectionSuccess,
  myParticipatedElectionFailure,
  reportCreationLoadingStart,
  reportCreationSuccess,
  reportCreationFailure,
  searchLoadingStart,
  searchResultSuccess,
  searchResultFailure,
  signleUserDetailLoadingStart,
  singleUserDetailSuccess,
  singleUserDetailFailure,
  updateVOtes,
  // onboarding phase 2 states ////////////////////////////////
  loginInLoadingStart,
  loginSuccessPhase2,
  loginFailure,
  signUpStart,
  signUpSuccess,
  signUpFailure,
  setUserDetailsStart,
  setUserDetailsSuccess,
  setUserDetailsFailure,
  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  resendVerificationStart,
  resendVerificationSuccess,
  resendVerificationFailure,
  setNewPassWordLoadingStart,
  setNewPasswordSuccess,
  setNewPasswordFailure,
  verifyEmailLoadingStart,
  verifyEmailSuccess,
  verifyEmailFailure,
  clearOnBoardingPhase2Error,
  resendEmailVerificationStateReset,
  passGoogleAccessTokenLoadingStart,
  passGoogleAccessTokenSuccess,
  passGoogleAccessTokenFailure,
  LoginGoogleAccessTokenLoadingStart,
  LoginGoogleAccessTokenSuccess,
  LoginGoogleAccessTokenFailure,
  saveMyWalletAddress,
  clearMyWalletAddress,
  disableConnectButtonAction,
  clearUserDetails,
} = userslice.actions;
