import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Dropdown,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import NavBar from "@components/navbar/NavBar";
import CustomButton from "@components/custom-button/CustomButton";
import ConfirmVotePopup from "@components/confirm-vote-popup/ConfirmVotePopup";
import VoteSuccessfulPopup from "@components/vote-successful-popup/VoteSuccessfulPopup";
import DonatePopup from "@components/donate-popup/DonatePopup";
import ConfirmPowerVotePopup from "@components/confirm-powervote-popup/ConfirmPowerVotePopup";
import BuyPowerVotePopup from "@components/buy-power-vote-popup/BuyPowerVotePopup";
import PowerVotePopup from "@components/power-vote-popup/PowerVotePopup";
import SelectPowerVotePopup from "@components/select-power-vote-popup/SelectPowerVotePopup";
import { ReactComponent as File } from "@images/file.svg";
import { ReactComponent as Back } from "@images/back.svg";
import { ReactComponent as VerticleIcon } from "@images/vertical-icon.svg";
import ReportPopup from "@components/report-popup/ReportPopup";
import Loader from "@components/loader/Loader";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import * as _ from "lodash";
import TemplatePopup from "@components/template-popup/TemplatePopup";
import { clearProjectErrorFromBackend } from "@store/project_redux";
import "@components/project-details-screen/ProjectDetailsScreenStyle.scss";
import BTCPopup from "@components/btcpopup/btcPopup";
import DonateBTCPopup from "@components/donateBTCPopup/DonateBTCPopup";
import moment from "moment";

const ProjectDetailsScreen = ({
  page,
  singleProjectLoading,
  singleProjectDetail,
  singleProjectBackendError,
  getSingleProjectListAsync,
  userInformation,
  clearBackendError,
  buyPowerVoteAchieved,
  buyPowerVoteUsibgTokenAchieved,
  checkMinimalBalLoading,
  getAllPlatformPowerVotes,
  checkMinimalBalError,
  getPowerVotesOwnedByUser,
  singleElectionDetail,
}) => {
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showDonate, setShowDonate] = useState(false);
  const [showDonateSuccess, setShowDonateSuccess] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [singleProject, setSingleProject] = useState({});
  const [details, setDetails] = useState();
  const [showReportPopup, setShowReportPopup] = useState(false);
  const [showReportSuccess, setShowReportSuccess] = useState(false);
  const [projId, setProjId] = useState();
  const [showBuyPowerVote, setShowBuyPowerVote] = useState(false);
  const [showPowerVote, setShowPowerVote] = useState(false);
  const [showConfirmPower, setShowConfirmPower] = useState(false);
  const [showBuySuccess, setShowBuySuccess] = useState(false);
  const [showSelectPopup, setShowSelectPopup] = useState(false);
  const [showTemplate, setShowTemplate] = useState(false);
  const [showFailPopup, setShowFailPopup] = useState(false);
  const [infoData, setInfoData] = useState();
  const [showPausedPopup, setShowPausedPopup] = useState(false);
  const [metaError, setMetaError] = useState("");
  const [btcOpen, setBtcOpen] = useState(false);
  const [donateBTC, setDonateBTC] = useState(false);
  const [showDraftTemplate, setShowDraftTemplate] = useState(false);
  const [
    idOfPowerVoteChosenToVoteProject,
    setIdOfPowerVoteChosenToVoteProject,
  ] = useState();
  const [numberOfPowerVotesBrought, setNumberOfPowerVotesBrought] = useState(0);
  const [itemToDonate, setItemToDonate] = useState();
  const [transactionCancel, setShowTransactionCancel] = useState(false);
  const [myTrueState, setMyTrueState] = useState(false);
  const [showDraftTemplate1, setShowDraftTemplate1] = useState(false);
  const [myNetwork, setMyNetwork] = useState("");
  const [bscError, setBscError] = useState("");
  const [showBitPage, setShowBitPage] = useState(false);
  const [showBitPage2, setShowBitPage2] = useState(false);
  const [failurePopup, setShowFailurePopup] = useState(false);
  useEffect(() => {
    if (myTrueState === true) {
      setShowTransactionCancel(true);
    }
  }, [myTrueState]);

  const { id } = useParams();

  useEffect(() => {
    getSingleProjectListAsync({ id });
    getAllPlatformPowerVotes();
    if (userInformation !== undefined) {
      getPowerVotesOwnedByUser();
    }
    clearBackendError();
  }, [id]);
  useEffect(() => {
    if (
      buyPowerVoteAchieved !== undefined &&
      buyPowerVoteAchieved.buySuccessful !== undefined &&
      buyPowerVoteAchieved.buySuccessful === true
    ) {
      // console.log("buy power vote achieved", buyPowerVoteAchieved);
      setNumberOfPowerVotesBrought(buyPowerVoteAchieved.totalCount);
      setMetaError(buyPowerVoteAchieved.transaction.transactionHash);
      setShowPausedPopup(false);
    }
  }, [buyPowerVoteAchieved]);
  useEffect(() => {
    if (
      buyPowerVoteUsibgTokenAchieved !== undefined &&
      buyPowerVoteUsibgTokenAchieved.buySuccessful !== undefined &&
      buyPowerVoteUsibgTokenAchieved.buySuccessful === true
    ) {
      setNumberOfPowerVotesBrought(buyPowerVoteUsibgTokenAchieved.totalCount);
      if (buyPowerVoteUsibgTokenAchieved.transaction.tokenName === "USDC") {
        setMetaError(
          buyPowerVoteUsibgTokenAchieved.transaction.transactionHash
        );
      } else {
        setBscError(buyPowerVoteUsibgTokenAchieved.transaction.transactionHash);
      }
    }
  }, [buyPowerVoteUsibgTokenAchieved]);

  useEffect(() => {
    if (singleProjectDetail !== undefined) {
      setSingleProject(singleProjectDetail);
      setDetails(singleProjectDetail);
      // console.log("sinlge project", singleProjectDetail);
      if (singleProjectDetail !== null) {
        setProjId(singleProjectDetail.id);
      }
    }
    clearBackendError();
  }, [singleProjectDetail]);

  const youtubeReplacer = (url) => {
    if (!url) {
      // console.log("url in if", url);
      return "";
    } else {
      url = url.replace(
        "watch?v=",
        // "v/"
        "embed/"
      );
      // url = url + "&output=embed";
      // window.location.replace(url);
      // console.log("url in else", url);
      return url;
    }
  };
  const makeButtonDisabled = () => {
    let makeMeTrueORFalse;
    let value = [];
    // console.log("details in comp-details age", details);
    if (
      singleProjectDetail !== undefined &&
      singleProjectDetail.votes !== undefined &&
      singleProjectDetail.votes.length > 0 &&
      userInformation !== undefined
    ) {
      value = singleProjectDetail.votes.filter((item) => {
        if (
          item.votedProjectId === singleProjectDetail.id &&
          item.electionId === id &&
          userInformation.id === item.userId
        ) {
          return true;
        } else {
          return false;
        }
      });
      return value;
    }

    if (value.length > 0) {
      makeMeTrueORFalse = true;
      return makeMeTrueORFalse;
    } else {
      return (makeMeTrueORFalse = false);
    }
  };

  const disablePowerVote = () => {
    let value;
    if (
      singleElectionDetail !== undefined &&
      singleElectionDetail.election !== undefined &&
      singleElectionDetail.election.votes !== undefined &&
      singleElectionDetail.election.votes.length > 0 &&
      singleProjectDetail !== undefined &&
      singleProjectDetail.electionParticipation !== undefined &&
      singleProjectDetail.electionParticipation?.length > 0
    ) {
      value = singleElectionDetail.election.votes.find(
        (item) =>
          Number(item.electionId) ===
            Number(singleProjectDetail.electionParticipation[0].electionId) &&
          Number(item.userId) === Number(userInformation.id)
      );

      if (value !== undefined) {
        return true;
      } else {
        return false;
      }
    }
  };

  return (
    <>
      <NavBar pageName="Competitions" />
      <div className="ProjectDetailsScreen">
        <Container>
          {singleProjectLoading && <Loader />}
          <div className="details-screen">
            <div className="back">
              <Back
                className="back-icon"
                onClick={() => {
                  navigate(-1);
                }}
              />
            </div>
            <div className="contents">
              <div className="top-section">
                <Row>
                  <Col lg={6}>
                    <div className="left">
                      <div className="detail-div">
                        <div className="back1 d-lg-none">
                          <Back
                            className="back-icon"
                            onClick={() => {
                              navigate(-1);
                            }}
                          />
                        </div>
                        <div className="details-section">
                          <div className="details">
                            <div className="title-text">Project Name</div>
                            <div className="name">
                              {singleProject !== undefined &&
                                singleProject.name !== undefined &&
                                singleProject.name}
                            </div>
                          </div>
                          <div className="details">
                            <div className="title-text">Creator Name </div>
                            <div className="name">
                              {singleProject !== undefined &&
                                singleProject.user !== undefined &&
                                singleProject.user.name}
                            </div>
                          </div>
                          <Row>
                            {singleProject !== undefined &&
                            singleProject.category !== undefined &&
                            singleProject.category.name !== null ? (
                              <Col sm={4} className="sub-detail">
                                <div className="d-title">Category Name</div>
                                <div className="d-text">
                                  {singleProject.category.name}
                                </div>
                              </Col>
                            ) : null}
                            {singleProject !== undefined &&
                            singleProject.documnentLink !== null ? (
                              <Col sm={4} className="sub-detail">
                                <div className="d-title">File</div>
                                <div className="d-text">
                                  <div className="file">
                                    <File className="file-icon" />
                                    <OverlayTrigger
                                      placement={"right"}
                                      overlay={
                                        <Tooltip id="tooltip-btn">
                                          {/* Download to get insights about the project */}
                                          Click to download
                                        </Tooltip>
                                      }
                                    >
                                      <a
                                        href={`${process.env.REACT_APP_IMGLINK}${singleProject.documnentLink}`}
                                      >
                                        {/* {`${process.env.REACT_APP_IMGLINK}${singleProject.documnentLink}`} */}
                                        Download
                                      </a>
                                    </OverlayTrigger>
                                  </div>
                                </div>
                              </Col>
                            ) : null}
                            {singleProject !== undefined &&
                            singleProject.totalDonatedAmount !== null &&
                            singleProject.totalDonatedAmount > 0 ? (
                              <Col sm={4} className="sub-detail">
                                <div className="d-title">Total Fund Raised</div>
                                <div className="d-text">
                                  ${singleProject.totalDonatedAmount}
                                </div>
                              </Col>
                            ) : null}
                            {singleProject !== undefined &&
                            singleProject.campaignDateTime !== null ? (
                              <Col sm={4} className="sub-detail">
                                <div className="d-title">Campaign Deadline</div>
                                <div className="d-text">
                                  {moment(singleProject.campaignDateTime)
                                    .local()
                                    .format("MM/DD/YYYY")}
                                </div>
                              </Col>
                            ) : null}
                            {singleProject !== undefined &&
                            singleProject.fundsAmount !== null &&
                            singleProject.fundsAmount !== 0 ? (
                              <Col sm={4} className="sub-detail">
                                <div className="d-title">Fund Goal</div>
                                <div className="d-text">
                                  ${singleProject.fundsAmount}
                                </div>
                              </Col>
                            ) : null}
                            {singleProject !== undefined &&
                              singleProject.youtubeLink !== null && (
                                <Col sm={4} className="sub-detail">
                                  <div className="d-title">Content Link</div>
                                  <div
                                    className="d-text-youtube"
                                    onClick={() =>
                                      window.open(
                                        `${singleProject.youtubeLink}`
                                      )
                                    }
                                  >
                                    Watch Video
                                  </div>
                                </Col>
                              )}
                          </Row>
                        </div>
                      </div>
                      <div className="dropdownsection">
                        <OverlayTrigger
                          placement={window.innerWidth < 991 ? "left" : "right"}
                          overlay={
                            <Tooltip id="tooltip-btn">
                              {/* Download to get insights about the project */}
                              Click to report project
                            </Tooltip>
                          }
                        >
                          <Dropdown>
                            <Dropdown.Toggle
                              className="threedot"
                              id="dropdown-basic"
                            >
                              <VerticleIcon className="vertical " />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="menu">
                              <Dropdown.Item
                                onClick={() => {
                                  setShowReportPopup(true);
                                  // setIdToReport(singleProject !== undefined && singleProject.id)
                                }}
                              >
                                Report
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Dropdown>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </Col>

                  <Col lg={6}>
                    <div className="iframe-div">
                      {singleProject !== undefined &&
                        singleProject.bannerImage !== null && (
                          // (
                          //   <iframe
                          //     className="videoIframe"
                          //     src={youtubeReplacer(singleProject.youtubeLink)}
                          //     title="YouTube video player"
                          //     allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          //     allowfullscreen
                          //   ></iframe>
                          // ) : (
                          <img
                            className="videoIframe"
                            src={`${process.env.REACT_APP_IMGLINK}${singleProject.bannerImage}`}
                          />
                        )}
                      {singleProject !== undefined &&
                      singleProject.joinedCompetition === true ? (
                        <div className="below-video">
                          <div className="comp-tag">
                            <span>In competition</span>
                          </div>
                          <div className="go-to">
                            <a href={`/competition-details/${id}`}>
                              Go to competition{" "}
                            </a>
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </Col>
                </Row>
              </div>
              <Row>
                <Col lg={12}>
                  <div className="bottom-section">
                    <div className="desc-div">
                      {singleProject !== undefined &&
                        singleProject.description && (
                          <div className="desc-title">Description</div>
                        )}
                      {singleProject !== undefined &&
                        singleProject.description && (
                          <div className="desc">
                            {singleProject.description}
                          </div>
                        )}
                    </div>

                    <div className="btn-div">
                      {userInformation !== undefined &&
                        singleProjectDetail !== undefined &&
                        singleProjectDetail.electionParticipation !==
                          undefined &&
                        singleProjectDetail.electionParticipation.length >
                          0 && (
                          // makeButtonDisabled() === false &&
                          <div className="cancel">
                            <CustomButton
                              classname="cancelbtn"
                              type="submit"
                              name="Power Vote"
                              filled={true}
                              primary={true}
                              // disable={disablePowerVote() === true}
                              onClick={() => {
                                setInfoData(singleProject);
                                setShowBuyPowerVote(true);
                              }}
                            />
                          </div>
                        )}
                      {userInformation !== undefined &&
                        singleProjectDetail !== undefined &&
                        singleProjectDetail.electionParticipation !==
                          undefined &&
                        singleProjectDetail.electionParticipation.length >
                          0 && (
                          // makeButtonDisabled() === false &&
                          <div className="confirm">
                            <CustomButton
                              type="submit"
                              name="Vote"
                              filled={true}
                              primary={true}
                              disable={disablePowerVote() === true}
                              onClick={() => {
                                setInfoData(singleProject);
                                setShowConfirm(true);
                              }}
                            />
                          </div>
                        )}

                      <div className="confirm">
                        <CustomButton
                          type="submit"
                          name="Donate"
                          filled={true}
                          primary={true}
                          onClick={() => {
                            setItemToDonate(singleProject);
                            setShowDonate(true);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="error-text">
                    {singleProjectBackendError !== undefined &&
                      singleProjectBackendError}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </div>
      <ConfirmVotePopup
        show={showConfirm}
        setShowModal={(e) => setShowConfirm(e)}
        setShowSuccess={(e) => setShowSuccess(e)}
        detailsToShow={details !== undefined && details}
        setShowMinBal={(e) => setShowTemplate(e)}
      />
      <VoteSuccessfulPopup
        show={showSuccess}
        setShowModal={(e) => setShowSuccess(e)}
        message="Vote Successfully Recorded!"
      />
      <DonatePopup
        show={showDonate}
        setShowModal={(e) => setShowDonate(e)}
        data={itemToDonate !== undefined && itemToDonate}
        setShowSuccess={(e) => setShowDonateSuccess(e)}
        transactionCancelPopup={(e) => setShowTransactionCancel(e)}
        setTrue={(e) => setMyTrueState(e)}
        network={(e) => setMyNetwork(e)}
        bitOpen={(e) => setDonateBTC(e)}
        openCheckMinBal={(e) => setShowDraftTemplate(e)}
      />

      <VoteSuccessfulPopup
        show={transactionCancel}
        setShowModal={(e) => setShowTransactionCancel(e)}
        message="Your transaction has been cancelled !"
        popupType={"transactionCancel"}
        popupSuccess={false}
        setTrue={(e) => setMyTrueState(e)}
      />
      <VoteSuccessfulPopup
        show={showDonateSuccess}
        setShowModal={(e) => setShowDonateSuccess(e)}
        message="Your Amount Donated Successfully!"
      />
      <ReportPopup
        show={showReportPopup}
        setShowModal={(e) => setShowReportPopup(e)}
        setShowSuccess={(e) => setShowReportSuccess(e)}
        passProjectId={projId !== undefined && projId}
        type={"Project"}
      />
      <VoteSuccessfulPopup
        show={showReportSuccess}
        setShowModal={(e) => setShowReportSuccess(e)}
        message="Your report has been successfully submitted!"
      />
      <BuyPowerVotePopup
        show={showBuyPowerVote}
        setShowPowerVote={(e) => setShowPowerVote(e)}
        setShowConfirmPower={(e) => setShowConfirmPower(e)}
        setShowModal={(e) => setShowBuyPowerVote(e)}
        powerVoteChosen={(e) => setIdOfPowerVoteChosenToVoteProject(e)}
      />
      <VoteSuccessfulPopup
        show={failurePopup}
        setShowModal={(e) => setShowFailurePopup(e)}
        message="Network might be busy.Please try again in sometime"
        popupSuccess={false}
      />

      <PowerVotePopup
        show={showPowerVote}
        setShowBuySuccess={(e) => setShowBuySuccess(e)}
        setShowModal={(e) => setShowPowerVote(e)}
        pauseStatePopup={(e) => setShowPausedPopup(e)}
        buySuccessPopopState={(e) => setShowBuySuccess(e)}
        buyFailPopup={(e) => setShowFailPopup(e)}
        metamaskError={(e) => setMetaError(e)}
        transactionCancelPopup={(e) => setShowTransactionCancel(e)}
        setTrue={(e) => setMyTrueState(e)}
        network={(e) => setMyNetwork(e)}
        openCheckMinBal={(e) => setShowDraftTemplate(e)}
        bitOpen={(e) => setBtcOpen(e)}
      />
      <BTCPopup
        show={btcOpen}
        setShowModal={(e) => setBtcOpen(e)}
        openBitPage={(e) => setShowBitPage(e)}
      />
      <TemplatePopup
        show={showBitPage}
        setShowModal={setShowBitPage}
        message={
          "Bitcoin transaction takes 5 minutes to an hour. You can check power vote status on MyPowerVote page"
        }
        bitcoin={true}

        // minBalCheck={true}
      />
      <DonateBTCPopup
        show={donateBTC}
        setShowModal={(e) => setDonateBTC(e)}
        openBitPage={(e) => setShowBitPage2(e)}
      />
      <TemplatePopup
        show={showBitPage2}
        setShowModal={setShowBitPage2}
        message={
          "Bitcoin transaction takes 5 minutes to an hour. You can check donation status on My Donations page"
        }
        bitcoin={true}
        bitcoinDonate={true}
        // minBalCheck={true}
      />
      <TemplatePopup
        show={showDraftTemplate}
        setShowModal={setShowDraftTemplate}
        message={
          myNetwork !== ""
            ? `Connect your wallet with ${myNetwork}  testnet to continue`
            : `Connect your wallet with goerli testnet to continue`
        }
        minBalCheck={true}
      />
      <VoteSuccessfulPopup
        show={showBuySuccess}
        setShowModal={(e) => setShowBuySuccess(e)}
        setShowSelectPopup={(e) => setShowBuyPowerVote(e)}
        message={`${numberOfPowerVotesBrought} Power Vote has bought Successfully!`}
        navigateToEtherScan={metaError}
        navigateToBSC={bscError}
        popupType="buySucess"
        setEtherError={(e) => setMetaError(e)}
        setBSCError={(e) => setBscError(e)}
        showRedirection={true}
      />
      <VoteSuccessfulPopup
        show={showFailPopup}
        setShowModal={(e) => setShowFailPopup(e)}
        // setShowSelectPopup={(e) => setShowSelectPopup(e)}
        message={
          metaError === ""
            ? "Please login to your metamask account"
            : "Some error occured. Please try again later"
        }
        popupType="redirectToEtherScan"
        popupSuccess={false}
        navigateToEtherScan={metaError}
        setMetaErrorStateToPrevious={(e) => setMetaError(e)}
      />
      <SelectPowerVotePopup
        show={showSelectPopup}
        setShowModal={(e) => setShowSelectPopup(e)}
        setShowConfirmPower={(e) => setShowConfirmPower(e)}
      />
      <ConfirmPowerVotePopup
        show={showConfirmPower}
        setShowModal={(e) => setShowConfirmPower(e)}
        setShowSuccess={(e) => setShowSuccess(e)}
        powerVoteChosen={(e) => setIdOfPowerVoteChosenToVoteProject(e)}
        detailsToShow={infoData !== undefined && infoData}
        votePackeageId={
          idOfPowerVoteChosenToVoteProject !== undefined &&
          idOfPowerVoteChosenToVoteProject
        }
      />
      <TemplatePopup
        show={showTemplate}
        setShowModal={setShowTemplate}
        message={"You don't have sufficient balance"}
        minBalCheck={true}
      />
    </>
  );
};

const mapStateToProps = ({
  project = {},
  user = {},
  powerVote = {},
  app = {},
  admin = {},
}) => {
  const singleProjectLoading = _.get(
    project,
    "singleProjectDetailLoading",
    false
  );
  const singleElectionDetail = _.get(admin, "singleElectionDetail", {});
  const singleProjectDetail = _.get(project, "singleProjectDetail", {});
  const singleProjectBackendError = _.get(
    project,
    "singleProjectDetailError",
    undefined
  );
  const userInformation = _.get(user, "userInfo", undefined);
  const buyPowerVoteAchieved = _.get(
    powerVote,
    "buyPowerVotesUsingEthSuccess",
    undefined
  );
  const buyPowerVoteUsibgTokenAchieved = _.get(
    powerVote,
    "buyPowerVoteUsingToken",
    undefined
  );
  const checkMinimalBalLoading = _.get(
    app,
    "checkMinimalBalanceLoading",
    false
  );
  const checkMinimalBalError = _.get(
    app,
    "checkMinimalBalanceError",
    undefined
  );
  return {
    singleProjectLoading,
    singleProjectDetail,
    singleProjectBackendError,
    userInformation,
    buyPowerVoteAchieved,
    buyPowerVoteUsibgTokenAchieved,
    checkMinimalBalLoading,
    checkMinimalBalError,
    singleElectionDetail,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getSingleProjectListAsync: (data) =>
    dispatch({ type: "singleProjectDetailSagaCalled", payload: data }),
  getAllPlatformPowerVotes: () =>
    dispatch({ type: "showAllPlatformPowerVotesSagaCalled" }),
  getPowerVotesOwnedByUser: () =>
    dispatch({ type: "allPowerVotesOwnedByUserSagaCalled" }),
  clearBackendError: () => dispatch(clearProjectErrorFromBackend()),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectDetailsScreen);
