import React, { useDebugValue, useEffect, useState } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import CustomButton from "@components/custom-button/CustomButton";
import InputField from "@components/input-field/InputField";
import CurrencyDropdown from "@components/currency-dropdown/CurrencyDropdown";
import { ReactComponent as CloseIcon } from "@images/close-icon.svg";
import { Dropdown, FormControl } from "react-bootstrap";
import Select, { components } from "react-select";
import { ReactComponent as CaretDownIcon } from "@images/dropdown.svg";
import { ReactComponent as DropdownIcon } from "@images/dropdown.svg";
import "@components/power-vote-popup/PowerVotePopupStyle.scss";
import { connect } from "react-redux";
import { web3 } from "src/WalletProvider/Web3";
import { clearConversionFromUTS } from "@store/powerVote_redux";
import Web3 from "web3";
import provider from "@utilities/web3Contract";
import { CONTRACT_ABI, BNB_CONTRACT } from "src/utilities/contractDetails";
import { clearReduxOnMount } from "@store/powerVote_redux";
import Loader from "@components/loader/Loader";

const PowerVotePopup = ({
  show,
  setShowModal,
  setShowBuySuccess,
  getAllPlatformPowerVotes,
  showPlatformPowerVotesLoading,
  showPlatformPowerVotesCount,
  showPlatformPowerVotesError,
  pltVotes,
  clearUSDToEth,
  buyPowerVoteUsingEthAsync,
  usdValueEth,
  userDetails,
  buyPowerVotesUsingEthSuccess,
  // pauseStatePopup,
  buySuccessPopopState,
  buyFailPopup,
  metamaskError,
  buyPowerVoteUsingTokenAsync,
  buyPowerVoteEthError,
  buyPowerVoteTokenError,
  usdEthConversionError,
  clearErrorState,
  setTrue,
  openCheckMinBal,
  network,
  bscError,
  bitOpen,
  bitCoinCreateAddressAsync,
  powerVoteBTLoading,
  powerVoteBTError,
  failedPopup,
}) => {
  //related to contract methods call
  let BN = web3.utils.BN;
  var Contract = require("web3-eth-contract");
  // related to local state
  const [amount, setAmount] = useState("");
  const [quantityOfVotes, setQuantityOfVotes] = useState(1);
  const [totalPriceOfVotesInEth, setTotalPriceOfVotesInEth] = useState("");
  const [currencyUSD, setCurrencyUSD] = useState("");
  const [hideMeFromuser, setHideFromUser] = useState(false);
  const [showFrontendError, setShowFrontendError] = useState("");
  const [userAddress, setUserAddress] = useState("");

  const targetNetworkIdEth = "0x5";
  const targetNetworkIdBnb = "0x61";

  const options = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
  ];

  const onClose = () => {
    setCurrencyUSD("");
    setHideFromUser(false);
    setShowModal(false);
  };

  // useEffect(() => {
  //   handleAllTypeOfDonation();
  // }, [userAddress]);

  useEffect(() => {
    clearUSDToEth();
    clearErrorState();
  }, []);

  const customStyles = {
    option: (base, state) => ({
      ...base,
      color: "#261B1B",
      background: state.isFocused
        ? "#F3F3F3"
        : state.isSelected
        ? "#e3e3e3"
        : "white",
      padding: "10px 12px",
      fontSize: "14px",
      fontWeight: "400px",
      cursor: "pointer",
      textAlign: "left",
    }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: "all .2s ease",
      transform: state.isFocused ? "rotate(180deg)" : null,
    }),
  };
  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon className="dropdown-icon" />
      </components.DropdownIndicator>
    );
  };
  const handleBuyButton = async (userAdd) => {
    if (userAdd !== "") {
      if (
        showPlatformPowerVotesCount !== undefined &&
        usdValueEth !== undefined
      ) {
        // pauseStatePopup(true);
        console.log("step 1");
        setHideFromUser(true);
        {
          try {
            if ((await checkNetwork(targetNetworkIdEth)) === true) {
              let contract = await new provider.web3ForContract.eth.Contract(
                CONTRACT_ABI,
                `${process.env.REACT_APP_ADDRESS}`
              );
              // console.log("METHOD CALL .....", contract);
              // console.log("contract address", process.env.REACT_APP_ADDRESS);
              // console.log(
              //   "vote id",
              //   showPlatformPowerVotesCount !== undefined &&
              //     showPlatformPowerVotesCount[0].id
              // );
              // console.log("user id", userDetails !== undefined && userDetails.id);

              // console.log("totla valie of the", totalPriceOfVotesInEth);
              let deployingContract = await contract.methods
                .buypowervoteETH(
                  totalPriceOfVotesInEth,
                  showPlatformPowerVotesCount !== undefined &&
                    showPlatformPowerVotesCount[0].id, // pwer vote id
                  userDetails !== undefined && userDetails.id // user id
                  //"0xD87Ba7A50B2E7E660f678A895E4B72E7CB4CCd9C"
                )
                .send({
                  from: `${userAdd}`, //user address
                  value: totalPriceOfVotesInEth, //amount of eth
                  // value: "1000000000000",
                });
              // console.log("Result -> ", deployingContract);
              if (deployingContract.status === true) {
                buyPowerVoteUsingEthAsync({
                  packageId:
                    showPlatformPowerVotesCount !== undefined &&
                    showPlatformPowerVotesCount[0].id,

                  amount: totalPriceOfVotesInEth,
                  // amount: "1000000000000",

                  count: quantityOfVotes,

                  fromAddress: userAdd,

                  timeStamp:
                    deployingContract.events.BuyPowerVoteETH.returnValues
                      .eventid,
                  transactionHash: deployingContract.transactionHash,

                  toAddress: deployingContract.to,
                  openSucessPopup: () => {
                    setHideFromUser(false);
                    buySuccessPopopState(true);
                    onClose();
                  },
                  openFail: () => {
                    setHideFromUser(false);
                    onClose();
                    failedPopup(true);
                  },
                  openMinimalPopup: () => {
                    openCheckMinBal(true);
                    onClose();
                  },
                });
              } else {
                // console.log("failed");
                buyPowerVoteUsingEthAsync({
                  packageId:
                    showPlatformPowerVotesCount !== undefined &&
                    showPlatformPowerVotesCount[0].id,

                  amount: totalPriceOfVotesInEth,
                  // amount: "1000000000000",

                  count: quantityOfVotes,

                  fromAddress: userAdd,

                  transactionHash: deployingContract.transactionHash,

                  toAddress: deployingContract.to,
                  openSucessPopup: () => {
                    setHideFromUser(false);
                    // buySuccessPopopState(true);

                    onClose();
                    buyFailPopup(true);
                  },
                  openFail: () => {
                    setHideFromUser(false);
                    onClose();
                    failedPopup(true);
                  },
                });
              }
            } else {
              network("goerli");
              openCheckMinBal(true);

              onClose();
            }
          } catch (e) {
            // console.log(e);
            setHideFromUser(false);
            if (
              e.receipt !== undefined &&
              e.receipt.transactionHash !== undefined
            ) {
              metamaskError(e.receipt.transactionHash);
              buyFailPopup(true);
            } else if (e.code === 4100) {
              metamaskError(e.message);
              buyFailPopup(true);
            } else if (e.code === 4001) {
              // setShowFrontendError("Transaction has been cancelled"
              setTrue(true);
              // setShowModal(false);
            } else {
              setTrue(true);
            }

            // console.log("error when failed transaction", e);

            onClose();
          }
        }
      } else if (
        currencyUSD !== "" &&
        (currencyUSD === "USD" || currencyUSD === "BUSD")
      ) {
        console.log("step 2");
        callFunctionToBuyVoteUsingToken(
          showPlatformPowerVotesCount[0].tokenAddress,
          showPlatformPowerVotesCount[0].id,
          userDetails.id,
          new BN(showPlatformPowerVotesCount[0].votePriceInUSD)
            .mul(new BN(quantityOfVotes))
            .toString(),
          userAdd,
          currencyUSD
        );
      } else {
        setShowFrontendError("Please select currency to continue");
      }
    } else {
      openCheckMinBal(true);
      onClose();
    }
  };

  useEffect(() => {
    if (userDetails !== undefined && userDetails.walletAddress !== null) {
      setUserAddress(userDetails.walletAddress);
    }
  }, [userDetails]);

  useEffect(() => {
    clearErrorState();
  }, [currencyUSD, quantityOfVotes]);
  useEffect(() => {
    // console.log("currency usd", currencyUSD);
    if (usdValueEth !== undefined || currencyUSD !== "") {
      setShowFrontendError("");
    }
  }, [currencyUSD, usdValueEth]);

  const handleQuantityOfVotes = (e) => {
    // console.log("eeee", e.value);
    if (e !== undefined) {
      setQuantityOfVotes(e.value);
    }
  };

  const handlePay = async () => {
    // if ((await checkNetwork(targetNetworkIdEth)) === true) {
    let user = await handleAllTypeOfDonation();
    handleBuyButton(user);
    // } else {
    //   openCheckMinBal(true);
    //   setShowModal(false);
    // }
  };
  const handleAllTypeOfDonation = async () => {
    try {
      if (window.ethereum) {
        // window.ethereum.request({ method: "eth_requestAccounts" }).then((res) => {
        //   // Return the address of the wallet
        //   // console.log(res[0]);
        //   setUserAddress(res[0]);
        // });
        let data = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        return data[0];
      } else {
        alert("Please install metamask to continue");
      }
    } catch (e) {
      if (e.code === 4100) {
        metamaskError(e.message);
        buyFailPopup(true);
      } else if (e.code === 4001) {
        // setShowFrontendError("Transaction has been cancelled"
        setTrue(true);
        // setShowModal(false);
      } else {
        setTrue(true);
      }
    }
  };

  const handleBitCoin = () => {
    if (currencyUSD !== "" && currencyUSD === "BTC") {
      bitCoinCreateAddressAsync({
        powerVotePackageId:
          showPlatformPowerVotesCount !== undefined &&
          showPlatformPowerVotesCount[0].id,
        count: quantityOfVotes,
        openPopup: () => {
          bitOpen(true);
          clearErrorState();
          onClose();
        },
      });
    }
  };
  //accpets contract address of token used for buying power vote, rest fields are self explainatory. change the return data according to needs
  const buypowervotefromtoken = async (
    tokenaddress,
    powervoteid,
    userid,
    amount,
    userWallAdd
  ) => {
    let tokencontract = await new provider.web3ForContract.eth.Contract(
      CONTRACT_ABI,
      tokenaddress
    );
    // console.log("tokencontract", tokencontract);
    let allowance = await tokencontract.methods
      .allowance(userWallAdd, `${process.env.REACT_APP_ADDRESS}`)
      .call();
    // console.log(allowance);
    if (Number(allowance) < Number(Web3.utils.toWei(amount, "mwei"))) {
      let approval = await tokencontract.methods
        .approve(
          `${process.env.REACT_APP_ADDRESS}`,
          Web3.utils.toWei(amount, "mwei")
        )
        .send({
          from: `${userWallAdd}`, //user address
        });
      //   console.log(approval);
    }
    let createcontract = await new provider.web3ForContract.eth.Contract(
      CONTRACT_ABI,
      `${process.env.REACT_APP_ADDRESS}`
    );
    let deployingContract = await createcontract.methods
      .buypowervoteERC20(
        Web3.utils.toWei(amount, "mwei"),
        powervoteid,
        tokenaddress,
        userid
      )
      .send({
        from: `${userWallAdd}`, //user address
      });
    // console.log(deployingContract);
    return deployingContract;
  };

  const buypowervotefromtokenBUSD = async (
    tokenaddress,
    powervoteid,
    userid,
    amount,
    userWallAdd
  ) => {
    // function buypowervoteBEP20(uint256 value,uint256 voteId, address _tokenContract,uint256 userId)
    let tokencontract = await new provider.web3ForContract.eth.Contract(
      BNB_CONTRACT,
      // tokenaddress
      `${process.env.REACT_APP_BEP20}`
    );
    // console.log("tokencontract", tokencontract);
    let allowance = await tokencontract.methods
      .allowance(userWallAdd, `${process.env.REACT_APP_BNB_CONTRACT}`)
      .call();
    // console.log(allowance);
    if (Number(allowance) < Number(Web3.utils.toWei(amount))) {
      let approval = await tokencontract.methods
        .approve(
          `${process.env.REACT_APP_BNB_CONTRACT}`,
          Web3.utils.toWei(amount)
        )
        .send({
          from: `${userWallAdd}`, //user address
        });
      //   console.log(approval);
    }
    let createcontract = await new provider.web3ForContract.eth.Contract(
      BNB_CONTRACT,
      `${process.env.REACT_APP_BNB_CONTRACT}`
    );
    let deployingContract = await createcontract.methods
      .buypowervoteBEP20(
        Web3.utils.toWei(amount),
        powervoteid,
        `${process.env.REACT_APP_BEP20}`,
        userid
      )
      .send({
        from: `${userWallAdd}`, //user address
      });
    // console.log(deployingContract);
    return deployingContract;
  };
  const checkNetwork = async (targetNetworkId) => {
    if (window.ethereum || localStorage.getItem("walletConnect")) {
      const currentChainId = await window.ethereum.request({
        method: "eth_chainId",
      });
      // console.log("currenchain id", currentChainId);
      // console.log("target network id", targetNetworkId);
      // return true if network id is the same
      if (currentChainId === targetNetworkId) {
        // console.log("inside true");
        return true;
      }
      // return false is network id is different
      else {
        // console.log("inside false");
        return false;
      }
    }
  };
  useEffect(() => {
    if (usdValueEth !== undefined) {
      setTotalPriceOfVotesInEth(
        new BN(usdValueEth).mul(new BN(quantityOfVotes)).toString()
      );
      // console.log("quantityof votes", quantityOfVotes);
      // console.log(
      //   "eth price in quantity",
      //   "USD",
      //   new BN(usdValueEth).mul(new BN(quantityOfVotes)).toString(),
      //   usdValueEth
      // );
    }
  }, [usdValueEth, quantityOfVotes]);

  const callFunctionToBuyVoteUsingToken = async (
    tokenAdd,
    powerVoteId,
    userId,
    votePrice,
    userWalletAd,
    currency
  ) => {
    setHideFromUser(true);
    try {
      if (
        currency === "USD" &&
        (await checkNetwork(targetNetworkIdEth)) === true
      ) {
        let deployingContract = await buypowervotefromtoken(
          tokenAdd,
          powerVoteId,
          userId,
          votePrice,
          userWalletAd
        );

        if (deployingContract.status === true) {
          //  pauseStatePopup(true);
          buyPowerVoteUsingTokenAsync({
            packageId:
              showPlatformPowerVotesCount !== undefined &&
              showPlatformPowerVotesCount[0].id,

            amount: new BN(
              Web3.utils.toWei(
                showPlatformPowerVotesCount[0].votePriceInUSD,
                "mwei"
              )
            )
              .mul(new BN(quantityOfVotes))
              .toString(),
            // amount: "1000000000000",

            count: quantityOfVotes,

            fromAddress: userWalletAd,

            timeStamp:
              deployingContract.events.BuyPowerVoteERC20.returnValues.eventid,
            transactionHash: deployingContract.transactionHash,
            tokenName: "USDC",
            toAddress: deployingContract.to,
            openSucessPopup: () => {
              setHideFromUser(false);
              buySuccessPopopState(true);
              onClose();
            },
            openFail: () => {
              setHideFromUser(false);
              onClose();
              failedPopup(true);
            },
          });
        }
      } else if (
        currency === "BUSD" &&
        (await checkNetwork(targetNetworkIdBnb)) === true
      ) {
        console.log("inside busd");
        let deployingContract = await buypowervotefromtokenBUSD(
          tokenAdd,
          powerVoteId,
          userId,
          votePrice,
          userWalletAd
        );

        console.log("Result -> ", deployingContract);

        if (deployingContract.status === true) {
          //  pauseStatePopup(true);
          buyPowerVoteUsingTokenAsync({
            packageId:
              showPlatformPowerVotesCount !== undefined &&
              showPlatformPowerVotesCount[0].id,

            amount: new BN(
              Web3.utils.toWei(showPlatformPowerVotesCount[0].votePriceInUSD)
            )
              .mul(new BN(quantityOfVotes))
              .toString(),
            // amount: "1000000000000",

            count: quantityOfVotes,

            fromAddress: userWalletAd,

            timeStamp:
              deployingContract.events.BuyPowerVoteBEP20.returnValues.eventid,
            transactionHash: deployingContract.transactionHash,
            tokenName: "BUSD",
            toAddress: deployingContract.to,
            binanceChain: true,
            openSucessPopup: () => {
              setHideFromUser(false);
              buySuccessPopopState(true);
              onClose();
            },
            openFail: () => {
              setHideFromUser(false);
              onClose();
              failedPopup(true);
            },
          });
        }
      } else {
        console.log("insssss");
        if (currencyUSD === "BUSD") {
          network("binance");
        } else {
          network("goerli");
        }
        openCheckMinBal(true);
        onClose();
      }
    } catch (e) {
      setHideFromUser(false);
      console.log(e);
      if (e.receipt !== undefined && e.receipt.transactionHash !== undefined) {
        metamaskError(e.receipt.transactionHash);
        buyFailPopup(true);
      } else if (e.code === 4100) {
        buyFailPopup(true);
      } else if (e.code === 4001) {
        // setShowFrontendError("Transaction has been cancelled"
        setTrue(true);
        // setShowModal(false);
      } else {
        setTrue(true);
      }
      // console.log("error when failed transaction", e);

      onClose();
    }
  };
  window.ethereum !== undefined &&
    window.ethereum.on("accountsChanged", (accounts) => {
      // Handle the new accounts, or lack thereof.
      // "accounts" will always be an array, but it can be empty.

      setUserAddress(accounts[0]);
    });

  window.ethereum &&
    ethereum.on("chainChanged", (chainId) => {
      // if (
      //   chooseCurrency === "busd" &&
      //   checkNetwork(chainId) === true &&
      //   Amount !== ""
      // ) {
      //   setShowFrontendError("");
      //   clearClose();
      // } else if (
      //   (chooseCurrency === "usdc" || chooseCurrency === "eth") &&
      //   Amount !== "" &&
      //   checkNetwork(chainId) === true
      // ) {
      //   setShowFrontendError("");
      //   clearClose();
      // }
      window.location.reload();
    });

  useEffect(() => {
    console.log("currency usd", currencyUSD);
  }, [currencyUSD]);
  return (
    <Modal
      className="PowerVotePopup"
      show={show}
      onHide={() => {
        clearErrorState();
        onClose();
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon
          className="closebtn"
          onClick={() => {
            clearErrorState();
            onClose();
          }}
        />
        {hideMeFromuser && (
          <Loader
            message={
              "Transaction is in progress. It takes  2-10 minutes to complete."
            }
          />
        )}
        {powerVoteBTLoading && <Loader />}
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading-text">Power Vote</div>
              <div className="detail-list">
                {showPlatformPowerVotesCount !== undefined &&
                  showPlatformPowerVotesCount.map((item) => (
                    <div className="vote-detail">
                      <pre className="detail-text">
                        {/* ${console.log("VOTE PRICE -> ", item.votePriceInUSD)} */}
                        ${item.votePriceInUSD} = +{item.voteCount} Votes
                      </pre>
                      <div className="select-dp">
                        <Select
                          placeholder="Select Quantity"
                          options={options}
                          styles={customStyles}
                          components={{ DropdownIndicator }}
                          defaultValue={{ label: "1", value: quantityOfVotes }}
                          onChange={(e) => handleQuantityOfVotes(e)}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </Col>
          </Row>
          <Row>
            <div className="inputs">
              <CurrencyDropdown
                usdValue={
                  showPlatformPowerVotesCount !== undefined &&showPlatformPowerVotesCount[0] !== undefined && showPlatformPowerVotesCount[0].votePriceInUSD !== undefined &&
                  Web3.utils.toWei(
                    showPlatformPowerVotesCount[0].votePriceInUSD,
                    "mwei"
                  )
                }
                chosenCurrencyUSD={(e) => setCurrencyUSD(e)}
              />

              <div className="vote-count">+{quantityOfVotes * 10} Votes</div>

              <div className="count">
                {usdValueEth === undefined && "$"}
                {usdValueEth !== undefined
                  ? (
                      web3.utils.fromWei(new BN(usdValueEth), "ether") *
                      quantityOfVotes
                    ).toFixed(8)
                  : showPlatformPowerVotesCount !== undefined &&showPlatformPowerVotesCount[0] !== undefined &&
                    (
                      Number(showPlatformPowerVotesCount[0].votePriceInUSD) *
                      quantityOfVotes
                    ).toString()}{" "}
                {usdValueEth !== undefined && "ETH"}
              </div>
            </div>
          </Row>
          {/* <Row>
            <div className="available-amount">
              <span>
                {" "}
                Available <span>$996.504</span>
              </span>
            </div>
          </Row> */}
          <Row>
            <Col xs={12} className="btn-div">
              <div className="confirm">
                <CustomButton
                  type="submit"
                  name="Buy Vote"
                  filled={true}
                  primary={true}
                  onClick={() => {
                    if (currencyUSD !== "" && currencyUSD === "BTC") {
                      handleBitCoin();
                    } else {
                      handlePay();
                    }
                  }}
                />
              </div>
              <div className="cancel">
                <CustomButton
                  classname="cancelbtn"
                  type="button"
                  name="Cancel"
                  filled={true}
                  primary={true}
                  onClick={() => {
                    clearErrorState();
                    onClose();
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="error-text">
                {buyPowerVoteEthError !== undefined && buyPowerVoteEthError}
                {buyPowerVoteTokenError !== undefined && buyPowerVoteTokenError}
                {usdEthConversionError !== undefined && usdEthConversionError}
                {powerVoteBTError !== undefined && powerVoteBTError}
                {showFrontendError !== "" && showFrontendError}
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};
const mapStateToProps = ({ powerVote = {}, user = {} }) => {
  const showPlatformPowerVotesLoading = _.get(
    powerVote,
    "showAllPlatformPowerVotesLoading",
    false
  );
  const showPlatformPowerVotesCount = _.get(
    powerVote,
    "showAllPlatformPowerVotes",
    undefined
  );
  const showPlatformPowerVotesError = _.get(
    powerVote,
    "showAllPlatformPowerVotesError",
    undefined
  );
  const usdValueEth = _.get(powerVote, "conversionFromUSDtoEth", undefined);
  const userDetails = _.get(user, "userInfo", undefined);
  const buyPowerVoteAchieved = _.get(
    powerVote,
    "buyPowerVotesUsingEthSuccess",
    undefined
  );
  const buyPowerVoteUsingTokenAchieved = _.get(
    powerVote,
    "buyPowerVoteUsingToken",
    undefined
  );
  const buyPowerVoteEthError = _.get(
    powerVote,
    "buyPowerVotesUsingError",
    undefined
  );
  const buyPowerVoteTokenError = _.get(
    powerVote,
    "buyPowerVoteUsingError",
    undefined
  );
  const usdEthConversionError = _.get(
    powerVote,
    "conversionFromUSDtoEthError",
    undefined
  );
  const powerVoteBTLoading = _.get(powerVote, "powerVoteBTCLoading", false);
  const powerVoteBTError = _.get(powerVote, "powerVoteBTCError", undefined);

  return {
    powerVoteBTLoading,
    powerVoteBTError,
    showPlatformPowerVotesLoading,
    showPlatformPowerVotesCount,
    showPlatformPowerVotesError,
    usdValueEth,
    userDetails,
    buyPowerVoteAchieved,
    buyPowerVoteUsingTokenAchieved,
    buyPowerVoteEthError,
    buyPowerVoteTokenError,
    usdEthConversionError,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getAllPlatformPowerVotes: () =>
    dispatch({ type: "showAllPlatformPowerVotesSagaCalled" }),
  convertUSDToEth: (data) =>
    dispatch({ type: "conversionFromUTESagaCalled", payload: data }),
  clearUSDToEth: () => dispatch(clearConversionFromUTS()),
  buyPowerVoteUsingEthAsync: (data) =>
    dispatch({ type: "buyPowerVotesUsingEthSagaCalled", payload: data }),
  buyPowerVoteUsingTokenAsync: (data) =>
    dispatch({ type: "buyPowerVoteUsingTokenSagaCalled", payload: data }),
  bitCoinCreateAddressAsync: (data) =>
    dispatch({
      type: "powerVoteBTCSagaCalled",
      payload: data,
    }),
  clearErrorState: () => dispatch(clearReduxOnMount()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(PowerVotePopup));
