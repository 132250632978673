import { createSlice } from "@reduxjs/toolkit";

const powerVoteSlice = createSlice({
  name: "PowerVote",
  initialState: {
    powerVoteOfUserLoading: false,
    powerVotesOfUser: [],
    powerVotesOfUserError: undefined,
    showAllPlatformPowerVotesLoading: false,
    showAllPlatformPowerVotes: undefined,
    showAllPlatformPowerVotesError: undefined,
    conversionFromUSDtoEthLoading: false,
    conversionFromUSDtoEth: undefined,
    conversionFromUSDtoEthError: undefined,

    conversionFromEthToUsdLoading: false,
    conversionFromEthtoUsd: undefined,
    conversionFromEthtoUsdError: undefined,

    conversionFromBNBtoUSDLoading: false,
    conversionFromBNBUSD: undefined,
    conversionFromBNBtoUSDError: undefined,

    buyPowerVotesUsingEthLoading: false,
    buyPowerVotesUsingEthSuccess: undefined,
    buyPowerVotesUsingError: undefined,
    voteUsingPowerVoteLoading: false,
    voteUsingPowerVote: undefined,
    voteUsingPowerVoteError: undefined,
    buyPowerVoteUsingTokenLoading: false,
    buyPowerVoteUsingToken: undefined,
    buyPowerVoteUsingError: undefined,
    donateProjectUsingEthLoading: false,
    donateProjectUsingEth: undefined,
    donateProjectUsingEthError: undefined,
    donateProjectUsingTokenLoading: false,
    donateProjectUsingToken: undefined,
    donateProjectUsingTokenError: undefined,
    donationTableLoading: false,
    donationTable: undefined,
    donationTableError: undefined,
    donationTableUserLoading: false,
    donationTableUser: undefined,
    donationTableUserError: undefined,
    donationTablePowerVoteLoading: false,
    donationTablePowerVote: undefined,
    donationTablePowerVoteError: undefined,

    donationOfUserLoggedInLoading: false,
    donationOfUserLoggedIn: undefined,
    donationOfUserError: undefined,

    powerVoteBTCLoading: false,
    powerVoteBTC: undefined,
    powerVoteBTCError: undefined,

    rewardLoading: false,
    reward: undefined,
    rewardError: undefined,

    votingHistoryLoading: false,
    votingHistory: undefined,
    votingHistoryError: undefined,

    donateBitcoinLoading: false,
    donateBitcoin: undefined,
    donateBitcoinError: undefined,


    stripeCheckoutLoading : false,
    stripeCheckout:undefined,
    stripeCheckoutError : undefined,
  },
  reducers: {
    clearStripeCheckout : (state,action)=>{
      return{
        ...state,
        
    stripeCheckoutLoading : false,
    stripeCheckout:undefined,
    stripeCheckoutError : undefined,
      }
    },
    stripeCheckoutLoadingStart : (state,action)=>{
      return{
        ...state,
        
    stripeCheckoutLoading :true,
    stripeCheckout:undefined,
    stripeCheckoutError : undefined,
      }
    },
    stripeCheckoutSuccess : (state,action)=>{
      return{
        ...state,
        
    stripeCheckoutLoading : false,
    stripeCheckout:action.payload,
    stripeCheckoutError : undefined,
      }
    },
    stripeCheckoutFailure : (state,action)=>{
      return{
        ...state,
        
    stripeCheckoutLoading : false,
    stripeCheckout:undefined,
    stripeCheckoutError :action.payload,
      }
    },
    donateBitcoingLoadingStart: (state, action) => {
      return {
        ...state,
        donateBitcoinLoading: true,
        donateBitcoin: undefined,
        donateBitcoinError: undefined,
      };
    },
    donateBitcoinSuccess: (state, action) => {
      return {
        ...state,
        donateBitcoinLoading: false,
        donateBitcoin: action.payload,
        donateBitcoinError: undefined,
      };
    },
    donateBitcoinFailure: (state, action) => {
      return {
        ...state,
        donateBitcoinLoading: false,
        donateBitcoin: undefined,
        donateBitcoinError: action.payload,
      };
    },
    votingHistoryLoadingStart: (state, action) => {
      return {
        ...state,
        votingHistoryLoading: true,
        votingHistory: undefined,
        votingHistoryError: undefined,
      };
    },
    votingHistorySuccess: (state, action) => {
      return {
        ...state,
        votingHistoryLoading: false,
        votingHistory: action.payload,
        votingHistoryError: undefined,
      };
    },
    votingHistoryFailure: (state, action) => {
      return {
        ...state,
        votingHistoryLoading: false,
        votingHistory: undefined,
        votingHistoryError: action.payload,
      };
    },
    rewardLoadingStart: (state, action) => {
      return {
        ...state,
        rewardLoading: true,
        reward: undefined,
        rewardError: undefined,
      };
    },
    rewardSuccess: (state, action) => {
      return {
        ...state,
        rewardLoading: false,
        reward: action.payload,
        rewardError: undefined,
      };
    },
    rewardFailure: (state, action) => {
      return {
        ...state,
        rewardLoading: false,
        reward: undefined,
        rewardError: action.payload,
      };
    },
    clearPowerVoteBTCBuyState: (state, action) => {
      console.log("clearing btc buy");
      return {
        ...state,
        powerVoteBTCLoading: false,
        powerVoteBTC: undefined,
        powerVoteBTCError: undefined,
      };
    },
    clearDonateBTCPopup: (state, action) => {
      return {
        ...state,

        donateBitcoinLoading: false,
        donateBitcoin: undefined,
        donateBitcoinError: undefined,
      };
    },
    powerVoteBTCLoadingStart: (state, action) => {
      return {
        ...state,
        powerVoteBTCLoading: true,
        powerVoteBTC: undefined,
        powerVoteBTCError: undefined,
      };
    },
    powerVoteBTCSuccess: (state, action) => {
      return {
        ...state,
        powerVoteBTCLoading: false,
        powerVoteBTC: action.payload,
        powerVoteBTCError: undefined,
      };
    },
    powerVoteBTCFailure: (state, action) => {
      return {
        ...state,
        powerVoteBTCLoading: false,
        powerVoteBTC: undefined,
        powerVoteBTCError: action.payload,
      };
    },
    donationOfUserLoaggedInLoadingStart: (state, action) => {
      return {
        ...state,
        donationOfUserLoggedInLoading: true,
        donationOfUserLoggedIn: undefined,
        donationOfUserError: undefined,
      };
    },
    donationOfUserLoggedInSuccess: (state, action) => {
      return {
        ...state,
        donationOfUserLoggedInLoading: false,
        donationOfUserLoggedIn: action.payload,
        donationOfUserError: undefined,
      };
    },
    donationOfUserLoggedInFailure: (state, action) => {
      return {
        ...state,
        donationOfUserLoggedInLoading: false,
        donationOfUserLoggedIn: undefined,
        donationOfUserError: action.payload,
      };
    },
    conversionFromEthToUsdLoadingStart: (state, action) => {
      return {
        ...state,
        conversionFromEthToUsdLoading: true,
        conversionFromEthtoUsd: undefined,
        conversionFromEthtoUsdError: undefined,
      };
    },
    conversionFromEthToUsdSucces: (state, action) => {
      return {
        ...state,
        conversionFromEthToUsdLoading: false,
        conversionFromEthtoUsd: action.payload,
        conversionFromEthtoUsdError: undefined,
      };
    },
    conversionFromEthToUsdFailure: (state, action) => {
      return {
        ...state,
        conversionFromEthToUsdLoading: false,
        conversionFromEthtoUsd: undefined,
        conversionFromEthtoUsdError: action.payload,
      };
    },
    conversionFromBNBToUsdLoadingStart: (state, action) => {
      return {
        ...state,

        conversionFromBNBtoUSDLoading: true,
        conversionFromBNBUSD: undefined,
        conversionFromBNBtoUSDError: undefined,
      };
    },
    conversionFromBNBToUsdSuccss: (state, action) => {
      return {
        ...state,

        conversionFromBNBtoUSDLoading: false,
        conversionFromBNBUSD: action.payload,
        conversionFromBNBtoUSDError: undefined,
      };
    },
    conversionFromBNBToUsdFailure: (state, action) => {
      return {
        ...state,

        conversionFromBNBtoUSDLoading: false,
        conversionFromBNBUSD: undefined,
        conversionFromBNBtoUSDError: action.payload,
      };
    },
    donateTablePVLoadingStart: (state, action) => {
      return {
        ...state,
        donationTablePowerVoteLoading: true,
        donationTablePowerVote: undefined,
        donationTablePowerVoteError: undefined,
      };
    },
    donateTablePVSuccess: (state, action) => {
      return {
        ...state,
        donationTablePowerVoteLoading: false,
        donationTablePowerVote: action.payload,
        donationTablePowerVoteError: undefined,
      };
    },
    donateTablePVFailure: (state, action) => {
      return {
        ...state,
        donationTablePowerVoteLoading: false,
        donationTablePowerVote: undefined,
        donationTablePowerVoteError: action.payload,
      };
    },
    powerVoteOfuserLoadingStart: (state, action) => {
      return {
        ...state,
        powerVoteOfUserLoading: true,
        powerVotesOfUser: [],
        powerVotesOfUserError: undefined,
      };
    },
    powerVoteOfUserSuccess: (state, action) => {
      return {
        ...state,
        powerVoteOfUserLoading: false,
        powerVotesOfUser: action.payload,
        powerVotesOfUserError: undefined,
      };
    },
    powerVoteOfUserFailure: (state, action) => {
      return {
        ...state,
        powerVoteOfUserLoading: false,
        powerVotesOfUser: [],
        powerVotesOfUserError: action.payload,
      };
    },
    showAllPlatformPowerVotesLoadingStart: (state, action) => {
      return {
        ...state,
        showAllPlatformPowerVotesLoading: true,
        showAllPlatformPowerVotes: undefined,
        showAllPlatformPowerVotesError: undefined,
      };
    },
    showAllPlatformVotesSuccess: (state, action) => {
      return {
        ...state,
        showAllPlatformPowerVotesLoading: false,
        showAllPlatformPowerVotes: action.payload,
        showAllPlatformPowerVotesError: undefined,
      };
    },
    showAllPlatformVotesFailure: (state, action) => {
      return {
        ...state,
        showAllPlatformPowerVotesLoading: false,
        showAllPlatformPowerVotes: undefined,
        showAllPlatformPowerVotesError: action.payload,
      };
    },
    conversionFromUTELoadingStart: (state, action) => {
      return {
        ...state,
        conversionFromUSDtoEthLoading: true,
        conversionFromUSDtoEth: undefined,
        conversionFromUSDtoEthError: undefined,
      };
    },
    conversionFromUTESucess: (state, action) => {
      return {
        ...state,
        conversionFromUSDtoEthLoading: false,
        conversionFromUSDtoEth: action.payload,
        conversionFromUSDtoEthError: undefined,
      };
    },
    conversionFromUTEFailure: (state, action) => {
      return {
        ...state,
        conversionFromUSDtoEthLoading: false,
        conversionFromUSDtoEth: undefined,
        conversionFromUSDtoEthError: action.payload,
      };
    },
    buyPowerVotesUsingEthLoadingStart: (state, action) => {
      return {
        ...state,
        buyPowerVotesUsingEthLoading: true,
        buyPowerVotesUsingEthSuccess: undefined,
        buyPowerVotesUsingError: undefined,
      };
    },
    buyPowerVoteEthSucess: (state, action) => {
      return {
        ...state,
        buyPowerVotesUsingEthLoading: false,
        buyPowerVotesUsingEthSuccess: action.payload,
        buyPowerVotesUsingError: undefined,
      };
    },
    clearPowerVoteEthState: (state, action) => {
      return {
        ...state,
        buyPowerVotesUsingEthLoading: false,
        buyPowerVotesUsingEthSuccess: undefined,
        buyPowerVotesUsingError: undefined,
      };
    },
    buyPowerVotesUsingEthFailure: (state, action) => {
      return {
        ...state,
        buyPowerVotesUsingEthLoading: false,
        buyPowerVotesUsingEthSuccess: undefined,
        buyPowerVotesUsingError: action.payload,
      };
    },
    voteUsingPowerVoteLoadingStart: (state, action) => {
      return {
        ...state,
        voteUsingPowerVoteLoading: true,
        voteUsingPowerVote: undefined,
        voteUsingPowerVoteError: undefined,
      };
    },
    voteUsingPowerVoteSuccess: (state, action) => {
      return {
        ...state,
        voteUsingPowerVoteLoading: false,
        voteUsingPowerVote: action.payload,
        voteUsingPowerVoteError: undefined,
      };
    },
    voteUsingPowerVoteFailure: (state, action) => {
      return {
        ...state,
        voteUsingPowerVoteLoading: false,
        voteUsingPowerVote: undefined,
        voteUsingPowerVoteError: action.payload,
      };
    },
    clearConversionFromUTS: (state, action) => {
      return {
        ...state,
        conversionFromUSDtoEthLoading: false,
        conversionFromUSDtoEth: undefined,
        conversionFromUSDtoEthError: undefined,
      };
    },
    buyVoteUsingTokenLoadingStart: (state, action) => {
      return {
        ...state,
        buyPowerVoteUsingTokenLoading: true,
        buyPowerVoteUsingToken: undefined,
        buyPowerVoteUsingError: undefined,
      };
    },
    buyVoteUsingTokenSuccess: (state, action) => {
      return {
        ...state,
        buyPowerVoteUsingTokenLoading: false,
        buyPowerVoteUsingToken: action.payload,
        buyPowerVoteUsingError: undefined,
      };
    },
    buyPowerVoteUsingTokenFailure: (state, action) => {
      return {
        ...state,
        buyPowerVoteUsingTokenLoading: false,
        buyPowerVoteUsingToken: undefined,
        buyPowerVoteUsingError: action.payload,
      };
    },

    clearBuyPowerVoteUsingToken: (state, action) => {
      return {
        ...state,
        buyPowerVoteUsingTokenLoading: false,
        buyPowerVoteUsingToken: undefined,
        buyPowerVoteUsingError: undefined,
      };
    },
    donateProjectUsingEthLoadingStart: (state, action) => {
      return {
        ...state,
        donateProjectUsingEthLoading: true,
        donateProjectUsingEth: undefined,
        donateProjectUsingEthError: undefined,
      };
    },
    donateProjectUsingEthSuccess: (state, action) => {
      return {
        ...state,
        donateProjectUsingEthLoading: false,
        donateProjectUsingEth: action.payload,
        donateProjectUsingEthError: undefined,
      };
    },
    donateProjectUsingEthFailure: (state, action) => {
      return {
        ...state,
        donateProjectUsingEthLoading: false,
        donateProjectUsingEth: undefined,
        donateProjectUsingEthError: action.payload,
      };
    },
    walletAddressReward: (state, action) => {
      return {
        ...state,
        walletAddReward: action.payload,
      };
    },
    clearWalletAdReward: (state, action) => {
      return {
        ...state,
        walletAddReward: "",
      };
    },
    clearReduxOnMount: (state, action) => {
      return {
        ...state,
        powerVoteOfUserLoading: false,
        powerVoteBTCError: undefined,

        powerVotesOfUserError: undefined,
        showAllPlatformPowerVotesLoading: false,
        rewardError: undefined,

        showAllPlatformPowerVotesError: undefined,
        conversionFromUSDtoEthLoading: false,

        conversionFromUSDtoEthError: undefined,
        buyPowerVotesUsingEthLoading: false,

        buyPowerVotesUsingError: undefined,
        voteUsingPowerVoteLoading: false,

        voteUsingPowerVoteError: undefined,
        buyPowerVoteUsingTokenLoading: false,

        buyPowerVoteUsingError: undefined,
        donateProjectUsingEthLoading: false,

        donateProjectUsingEthError: undefined,
        donateProjectUsingTokenError: undefined,
        donationTableError: undefined,
        donationTableUserError: undefined,
        donationTablePowerVoteError: undefined,
        donateBitcoinError: undefined,
      };
    },
    donateProjectUsingTokenLoadingStart: (state, action) => {
      return {
        ...state,
        donateProjectUsingTokenLoading: true,
        donateProjectUsingToken: undefined,
        donateProjectUsingTokenError: undefined,
      };
    },
    donateProjectUsingTokenSucess: (state, action) => {
      return {
        ...state,
        donateProjectUsingTokenLoading: false,
        donateProjectUsingToken: action.payload,
        donateProjectUsingTokenError: undefined,
      };
    },
    donateProjectUsingTokenFailure: (state, action) => {
      return {
        ...state,
        donateProjectUsingTokenLoading: false,
        donateProjectUsingToken: undefined,
        donateProjectUsingTokenError: action.payload,
      };
    },
    donationTableLoadingStart: (state, action) => {
      return {
        ...state,
        donationTableLoading: true,
        donationTable: undefined,
        donationTableError: undefined,
      };
    },
    donationTableSuccess: (state, action) => {
      return {
        ...state,
        donationTableLoading: false,
        donationTable: action.payload,
        donationTableError: undefined,
      };
    },
    donationTableFailure: (state, action) => {
      return {
        ...state,
        donationTableLoading: false,
        donationTable: undefined,
        donationTableError: action.payload,
      };
    },

    donationTableUserLoadingStart: (state, action) => {
      return {
        ...state,
        donationTableUserLoading: true,
        donationTableUser: undefined,
        donationTableUserError: undefined,
      };
    },
    donationTableUserSuccess: (state, action) => {
      return {
        ...state,
        donationTableUserLoading: false,
        donationTableUser: action.payload,
        donationTableUserError: undefined,
      };
    },
    donationTableUserFailure: (state, action) => {
      return {
        ...state,
        donationTableUserLoading: false,
        donationTableUser: undefined,
        donationTableUserError: action.payload,
      };
    },
    clearDonationUsingEth: (state, action) => {
      return {
        ...state,
        donateProjectUsingEthLoading: false,
        donateProjectUsingEth: undefined,
        donateProjectUsingEthError: undefined,
      };
    },
    clearDonationUsingERC20: (state, action) => {
      return {
        ...state,
        donateProjectUsingTokenLoading: false,
        donateProjectUsingToken: undefined,
        donateProjectUsingTokenError: undefined,
      };
    },
    clearPowerVoteRedux: (state, action) => {
      return {};
    },
    clearDonationRedux: (state, action) => {
      return {
        ...state,
        donationTableUserLoading: false,
        donationTableUser: undefined,
        donationTableUserError: undefined,
        donationTableLoading: false,
        donationTable: undefined,
        donationTableError: undefined,
        donationTablePowerVoteLoading: false,
        donationTablePowerVote: undefined,
        donationTablePowerVoteError: undefined,
      };
    },
  },
});
export default powerVoteSlice.reducer;
export const {
  stripeCheckoutLoadingStart,
  stripeCheckoutSuccess,
  stripeCheckoutFailure,
  clearDonateBTCPopup,
  clearPowerVoteBTCBuyState,
  powerVoteBTCLoadingStart,
  powerVoteBTCSuccess,
  powerVoteBTCFailure,
  donationOfUserLoaggedInLoadingStart,
  donationOfUserLoggedInSuccess,
  donationOfUserLoggedInFailure,
  powerVoteOfuserLoadingStart,
  powerVoteOfUserSuccess,
  powerVoteOfUserFailure,
  showAllPlatformPowerVotesLoadingStart,
  showAllPlatformVotesSuccess,
  showAllPlatformVotesFailure,
  conversionFromUTELoadingStart,
  conversionFromUTESucess,
  conversionFromUTEFailure,
  clearConversionFromUTS,
  buyPowerVotesUsingEthLoadingStart,
  buyPowerVoteEthSucess,
  buyPowerVotesUsingEthFailure,
  clearPowerVoteEthState,
  clearReduxOnMount,
  clearPowerVoteRedux,
  voteUsingPowerVoteLoadingStart,
  voteUsingPowerVoteSuccess,
  voteUsingPowerVoteFailure,
  buyVoteUsingTokenLoadingStart,
  buyVoteUsingTokenSuccess,
  buyPowerVoteUsingTokenFailure,
  donateProjectUsingEthLoadingStart,
  donateProjectUsingEthSuccess,
  donateProjectUsingEthFailure,
  clearBuyPowerVoteUsingToken,
  donateProjectUsingTokenLoadingStart,
  donateProjectUsingTokenSucess,
  donateProjectUsingTokenFailure,
  donationTableLoadingStart,
  donationTableSuccess,
  donationTableFailure,
  clearDonationUsingEth,
  clearDonationUsingERC20,
  donationTableUserLoadingStart,
  donationTableUserSuccess,
  donationTableUserFailure,
  clearDonationRedux,
  donateTablePVLoadingStart,
  donateTablePVSuccess,
  donateTablePVFailure,
  conversionFromBNBToUsdLoadingStart,
  conversionFromBNBToUsdSuccss,
  conversionFromBNBToUsdFailure,
  conversionFromEthToUsdLoadingStart,
  conversionFromEthToUsdSucces,
  conversionFromEthToUsdFailure,
  rewardLoadingStart,
  rewardSuccess,
  rewardFailure,
  walletAddressReward,
  clearWalletAdReward,
  votingHistoryLoadingStart,
  votingHistorySuccess,
  votingHistoryFailure,
  donateBitcoingLoadingStart,
  donateBitcoinSuccess,
  donateBitcoinFailure,
  clearStripeCheckout,
} = powerVoteSlice.actions;
