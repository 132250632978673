import { call, put } from "redux-saga/effects";
import Api from "./../services/ApiCaller";
import {
  getAccessToken,
  getMinWalletBalanceCheck,
  getuserDetails,
} from "src/utilities/utility";

import {
  createProjectStart,
  createProjectSuccess,
  createProjectFailure,
  listAllProjectStart,
  listAllProjectSuccess,
  listAllProjectFailure,
  deleteProjectStart,
  deleteProjectSuccess,
  deleteProjectFailure,
  editProjectStart,
  editProjectSuccess,
  editProjectFailure,
  liveProjectLoading,
  liveProjectSuccess,
  liveProjectFailure,
  publishProjectLoadingStart,
  publishProjectSuccess,
  publicProjectFailure,
  joinElectionLoadingStart,
  joinElectionSuccess,
  joinElectionFailure,
  voteProjectLoadingStart,
  voteProjectSuccess,
  voteProjectFailure,
  top10ProjectLoadingStart,
  top10ProjectSuccess,
  top10ProjectFailure,
  donateProjectLoadingStart,
  donateProjectSuccess,
  donateProjectFaliure,
  publishToDraftLoadingStart,
  publishToDraftSuccess,
  publishToDraftFailure,
  searchProjectLoadingStart,
  searchProjectSuccess,
  searchProjectError,
  singleProjectDetailLoadingStart,
  singleProjectDetailSuccess,
  singleProjectDetailFailure,
  projectSpotlightLoadingStart,
  projectSpotlightSuccess,
  projectSpotlightError,
  top50ProjectLoadingStart,
  top50ProjectSuccess,
  top50ProjectFailure,
  getDReceivedLoadingStart,
  getDReceivedSuccess,
  getDReceivedFailure,
  getTotalDonationLoadingStart,
  getTotalDonationSuccess,
  getTotalDonationFailure,
  searchResultLoadingStart,
  searchResultSuccess,
  searchResultFailure,
} from "./project_redux";
import { GetPublicCompetetionSaga } from "./competetion_saga";

import { AllPlatformProjectSaga } from "./admin_saga";
import { MyParticipatedElections } from "./user_saga";
import { CheckMinimalBalanceOfUser } from "./app_saga";
export function* createProjectSaga(api, { payload }) {
  let token = yield getAccessToken();
  const { openMinimalPopup } = payload;
  delete payload["openMinimalPopup"];
  let result = yield call(CheckMinimalBalanceOfUser, api, {});
  let address = yield getuserDetails();
  // if (address.walletAddress !== null) {
  yield put(createProjectStart());

  const { callBack } = payload;
  delete payload["callBack"];

  try {
    const result = yield call(
      Api.callServer,
      api.CreateProjectApi,
      {
        details: payload,
        token,
      },
      true
    );
    yield put(createProjectSuccess());
    callBack(result.result.id);

    yield call(listAllProjectsSaga, api, { payload: { token } });
  } catch (e) {
    yield put(createProjectFailure(e.error));
  }
  // } else {
  //   openMinimalPopup();
  // }
}

export function* listAllProjectsSaga(api, { payload }) {
  yield put(listAllProjectStart());
  let token = yield getAccessToken();

  try {
    const result = yield call(
      Api.callServer,
      api.GetProjectsApi,
      {
        details: payload,
        token,
      },
      true
    );

    yield put(listAllProjectSuccess(result.result));
  } catch (e) {
    yield put(listAllProjectFailure(e.error));
  }
}

export function* EditProjectSaga(api, { payload }) {
  const { showMinBal } = payload;
  delete payload["showMinBal"];
  let result = yield call(CheckMinimalBalanceOfUser, api, {});
  let address = yield getuserDetails();
  // if (address.walletAddress !== null) {
  yield put(editProjectStart());

  let token = yield getAccessToken();
  const { callBack } = payload;
  delete payload["callBack"];
  try {
    const result = yield call(
      Api.callServer,
      api.EditProjectApi,
      {
        details: payload,
        token,
      },
      true
    );

    yield put(editProjectSuccess());
    yield call(listAllProjectsSaga, api, { payload: { token } });
    callBack();
  } catch (e) {
    yield put(editProjectFailure(e.error));
  }
  // } else {
  //   showMinBal();
  // }
}

export function* DeleteProjectSaga(api, { payload }) {
  const { openMinBalPopup } = payload;
  delete payload["openMinBalPopup"];
  let result = yield call(CheckMinimalBalanceOfUser, api, {});
  let address = yield getuserDetails();
  // if (address.walletAddress !== null) {
  yield put(deleteProjectStart());
  let token = yield getAccessToken();
  const { callBack, template } = payload;
  delete payload["callBack"];
  delete payload["template"];
  try {
    const result = yield call(
      Api.callServer,
      api.DeleteProjectApi,
      {
        details: payload,
        token,
      },
      true
    );
    yield put(deleteProjectSuccess());

    yield call(listAllProjectsSaga, api, { payload: { token } });
    callBack();
  } catch (e) {
    template(true);
    yield put(deleteProjectFailure(e.error));
  }
  // } else {
  //   openMinBalPopup();
  // }
}

export function* EditBannerProjectSaga(api, { payload }) {
  yield put(editProjectStart());

  let token = yield getAccessToken();
  const { callBack } = payload;
  delete payload["callBack"];
  try {
    const result = yield call(
      Api.callServer,
      api.UpdateProjectBannerImage,
      {
        details: payload,
        token,
      },
      true
    );
    yield put(editProjectSuccess());
    yield call(listAllProjectsSaga, api, { payload: { token } });

    //  callBack();
  } catch (e) {
    yield put(editProjectFailure(e.error));
  }
}

export function* EditProjectDocumentSaga(api, { payload }) {
  yield put(editProjectStart());

  let token = yield getAccessToken();
  const { callBack } = payload;
  delete payload["callBack"];
  try {
    const result = yield call(
      Api.callServer,
      api.UpdateProjectDocument,
      {
        details: payload,
        token,
      },
      true
    );
    yield put(editProjectSuccess());
    yield call(listAllProjectsSaga, api, { payload: { token } });
    // callBack();
  } catch (e) {
    yield put(editProjectFailure(e.error));
  }
}

export function* GetLiveProject(api, { payload }) {
  yield put(liveProjectLoading());

  try {
    const result = yield call(Api.callServer, api.GetAllLiveProjects, {}, true);
    yield put(liveProjectSuccess(result.result));
  } catch (e) {
    yield put(liveProjectFailure(e.error));
  }
}

export function* PublishProjectSaga(api, { payload }) {
  const { openMinBalPopup } = payload;
  delete payload["openMinBalPopup"];
  let result = yield call(CheckMinimalBalanceOfUser, api, {});

  let address = yield getuserDetails();
  if (address.walletAddress !== null) {
    yield put(publishProjectLoadingStart());

    let token = yield getAccessToken();
    const { dataForBackend, callBackSuccess } = payload;
    const { callBack } = dataForBackend;

    delete dataForBackend["callBack"];
    delete payload["callBackSuccess"];
    // console.log("datafor back saga", dataForBackend);
    try {
      const result = yield call(
        Api.callServer,
        api.PublishProject,
        {
          details: dataForBackend,
          token,
        },
        true
      );
      callBack();
      yield put(publishProjectSuccess());
      callBackSuccess();
      yield call(listAllProjectsSaga, api, { payload: { token } });
    } catch (e) {
      yield put(publicProjectFailure(e.error));
    }
  } else {
    openMinBalPopup();
  }
}

export function* PublishToDraftSaga(api, { payload }) {
  const { openMinBalPopup } = payload;
  delete payload["openMinBalPopup"];
  let result = yield call(CheckMinimalBalanceOfUser, api, {});

  let address = yield getuserDetails();
  // if (address.walletAddress !== null) {
  yield put(publishToDraftLoadingStart());

  let token = yield getAccessToken();
  const { callBack } = payload;

  delete payload["callBack"];
  try {
    const result = yield call(
      Api.callServer,
      api.PublishToDraft,
      {
        details: payload,
        token,
      },
      true
    );
    yield put(publishToDraftSuccess());
    yield call(listAllProjectsSaga, api, { payload: { token } });

    callBack();
  } catch (e) {
    yield put(publishToDraftFailure(e.error));
  }
  // } else {
  //   openMinBalPopup();
  // }
}

export function* JoinElectionSaga(api, { payload }) {
  const { dataForBackend } = payload;
  const { openMinBalPopup } = dataForBackend;
  // console.log("openMin", openMinBalPopup);
  delete dataForBackend["openMinBalPopup"];

  let result = yield call(CheckMinimalBalanceOfUser, api, {});
  let address = yield getuserDetails();
  // if (address.walletAddress !== null) {
  yield put(joinElectionLoadingStart());
  // console.log("payload for join elction", payload);
  const { callBackSuccess } = payload;
  const { callBack, currentMonth, currentYear, type } = dataForBackend;
  let token = yield getAccessToken();
  delete dataForBackend["callBack"];
  delete dataForBackend["currentMonth"];
  delete dataForBackend["currentYear"];
  delete dataForBackend["type"];
  delete payload["callBackSuccess"];

  try {
    const result = yield call(
      Api.callServer,
      api.JoinElection,
      {
        details: dataForBackend,
        token,
      },
      true
    );

    yield put(joinElectionSuccess());
    callBack();
    // yield call(GetPublicCompetetionSaga , api , {payload : {currentMonth , currentYear , type}})
    callBackSuccess();
    yield call(MyParticipatedElections, api, { payload: { token } });
  } catch (e) {
    yield put(joinElectionFailure(e.error));
  }
  // } else {
  //   openMinBalPopup();
  // }
}

export function* VoteProjectSaga(api, { payload }) {
  const { openMinBalPopup } = payload;
  delete payload["openMinBalPopup"];
  let result = yield call(CheckMinimalBalanceOfUser, api, {});

  let address = yield getuserDetails();
  // if (address.walletAddress !== null) {
  yield put(voteProjectLoadingStart());
  let token = yield getAccessToken();
  const { callBack } = payload;
  delete payload["callBack"];
  try {
    const result = yield call(
      Api.callServer,
      api.VoteProject,
      {
        details: payload,
        token,
      },
      true
    );
    yield put(voteProjectSuccess());

    callBack();
  } catch (e) {
    yield put(voteProjectFailure(e.error));
  }
  // } else {
  //   openMinBalPopup();
  // }
}

export function* Top10ProjectsSags(api, { payload }) {
  yield put(top10ProjectLoadingStart());
  try {
    const result = yield call(Api.callServer, api.GetTop10Projects, {}, true);
    yield put(top10ProjectSuccess(result.result));
  } catch (e) {
    yield put(top10ProjectFailure(e.error));
  }
}
export function* Top50ProjectsSaga(api, { payload }) {
  yield put(top50ProjectLoadingStart());
  try {
    const result = yield call(
      Api.callServer,
      api.GetTop50Projects,
      payload,
      true
    );
    // console.log("result", result);
    yield put(top50ProjectSuccess(result.result));
  } catch (e) {
    yield put(top50ProjectFailure(e.error));
  }
}
export function* DonateProjectSaga(api, { payload }) {
  yield put(donateProjectLoadingStart());
  try {
    const result = yield call(Api.callServer, api, payload, true);
    yield put(donateProjectSuccess(result.result));
  } catch (e) {
    yield put(donateProjectFaliure());
  }
}

export function* SearchPageProjectsSagaCalled(api, { payload }) {
  yield put(searchProjectLoadingStart());
  try {
    const result = yield call(
      Api.callServer,
      api.GetProjectsForSearchPage,
      {},
      true
    );

    yield put(searchProjectSuccess(result.result));
  } catch (e) {
    yield put(searchProjectError(e.error));
  }
}

export function* SingleProjectDetailPageSaga(api, { payload }) {
  yield put(singleProjectDetailLoadingStart());
  try {
    const result = yield call(
      Api.callServer,
      api.GetSingleProjectDetails,
      payload,
      true
    );
    yield put(singleProjectDetailSuccess(result.result));
  } catch (e) {
    yield put(singleProjectDetailFailure(e.error));
  }
}

export function* ProjectSpotlightSaga(api, { payload }) {
  yield put(projectSpotlightLoadingStart());
  const { callBack, skip, limit, projectType } = payload;
  delete payload["callBack"];
  delete payload["skip"];
  delete payload["limit"];
  delete payload["projectType"];
  const token = yield getAccessToken();
  try {
    const result = yield call(
      Api.callServer,
      api.ProjectToSpotlight,
      {
        details: payload,
        token,
      },
      true
    );
    yield put(projectSpotlightSuccess());

    yield call(listAllProjectsSaga, api, { payload: { token } });
    yield call(AllPlatformProjectSaga, api, {
      payload: { projectType, skip, limit },
    });
    callBack();
  } catch (e) {
    yield put(projectSpotlightError(e.error));
  }
}

export function* GetDonationReceivedSaga(api, { payload }) {
  let token = yield getAccessToken();
  yield put(getDReceivedLoadingStart());
  try {
    const result = yield call(
      Api.callServer,
      api.GetDonationReceived,
      token,
      true
    );
    yield put(getDReceivedSuccess(result.result));
  } catch (e) {
    yield put(getDReceivedFailure(e.error));
  }
}

export function* GetTotalDonationSaga(api, { payload }) {
  yield put(getTotalDonationLoadingStart());
  let token = yield getAccessToken();
  try {
    const result = yield call(
      Api.callServer,
      api.TotalDonationReceived,
      token,
      true
    );
    yield put(getTotalDonationSuccess(result.result));
  } catch (e) {
    yield put(getTotalDonationFailure(e.error));
  }
}

export function* SearchResultSaga(api, { payload }) {
  yield put(searchResultLoadingStart());
  try {
    const result = yield call(
      Api.callServer,
      // api.GetSearchResult,
      api.GetSearchVersion2,
      payload,
      true
    );

    yield put(searchResultSuccess(result.result));
  } catch (e) {
    yield put(searchResultFailure(e.error));
  }
}
