import React, { useState, useEffect } from "react";
import { Modal, Container, Row, Col } from "react-bootstrap";
import { ReactComponent as Wallet } from "@images/walletconnect.svg";
import { ReactComponent as Metamask } from "@images/metamask-icon.svg";
import { connect } from "react-redux";
import Web3 from "web3";
import provider from "src/WalletProvider/walletProvider";
import * as _ from "lodash";
import { disconnect } from "src/WalletProvider/Web3";
import TemplatePopup from "@components/template-popup/TemplatePopup";
import { ethers } from "ethers";
import "@components/add-wallet-popup/AddWalletPopupStyle.scss";
import { saveMyWalletAddress, clearMyWalletAddress } from "@store/user_redux";

const AddWalletPopup = ({
  show,
  setShowModal,
  dispatchLogin,
  loginLoading,
  signInErrorFromBackEnd,
  dispatchLogout,
  setIsMetaMaskConnect,
  metaMaskConnected,
  userAddress,
  setWalletForMyProfile,
  fromMyProfile,
  saveMyWalletAddressInRedux,
}) => {
  const [showTemplate, setShowTemplate] = useState(false);
  const [message, setMessage] = useState("");
  const [chosenMetaMaskForLogin, setChosenMetaMaskForLogin] = useState(false);

  // useEffect(() => {
  //  window && window.ethereum && window.ethereum.on("accountsChanged", (accounts) => {

  //           if (accounts.length > 0) {
  //              console.log('Accounts -> ',accounts)
  //           }
  //         });
  // },[])

  // let history = useHistory()
  // code to be shifted
  let account;
  let connectWC = async () => {
    let pr = await provider();
    //  Create Web3 instance
    if (pr !== true) {
      if (pr.chainId === 0x5) {
        const web3 = new Web3(pr);

        let accounts = await web3.eth.getAccounts();
        account = accounts[0];
        const balance = await web3.eth.getBalance(account);

        if (account !== undefined) {
          forMyProfileSetAddress(account);
          // dispatchLogin({ userWalletAdd: account, tobeChecked: false });
          setShowModal(false);
        }
      } else {
        setMessage(
          "Please connect with Goerli Test Network as other networks are not supported"
        );
        setShowTemplate(true);
        disconnect();
        setShowModal(false);
      }
    } else if (pr === true) {
      disconnect();
    }
  };

  const getWalletAddress = async (accounts) => {
    if (window.ethereum) {
      const provider = await new ethers.providers.Web3Provider(window.ethereum);
      const trialChainId = await ethereum.request({ method: "eth_chainId" });
      const address = await ethereum.request({ method: "eth_accounts" });
      // console.log("trial chain id", trialChainId);
      if (
        ethereum.isConnected() === true
        //  && accounts[0] == userAddress
      ) {
        if (trialChainId == "0x5" || trialChainId == "0x61") {
          // setIsMetaMaskConnect(true);
          forMyProfileSetAddress(accounts[0]);
          // dispatchLogin({ userWalletAdd: accounts[0], tobeChecked: false });
          setShowModal(false);
        } else {
          setMessage(
            "Please connect with either Goerli Test Network or Binance Test Network. Other networks are not supported or check your address"
          );
          setShowTemplate(true);
          setShowModal(false);
        }
      } else {
        window.ethereum
          .request({
            method: "eth_requestAccounts",
          })
          .then((result) => {
            if (result.length > 0) {
              if (provider && provider.network !== undefined) {
                const chain = provider.network.chainId;
                if (chain === 0x5) {
                  // dispatchLogin({
                  //   userWalletAdd: result[0],
                  //   tobeChecked: false,
                  // });
                  forMyProfileSetAddress(result[0]);
                  setIsMetaMaskConnect(true);
                  setShowModal(false);
                } else {
                  setMessage(
                    "Please connect with Goerli Test Network as other networks are not supported"
                  );
                  setShowTemplate(true);
                  setShowModal(false);
                }
              }
            }
          })
          .catch((err) => {
            setMessage("Some error occured");
            setShowTemplate(true);
            setShowModal(false);
          });
      }
    } else {
      setMessage("Please install metamask");
      setShowTemplate(true);
      setShowModal(false);
    }
  };

  const everyAccountChangeHandle = async () => {
    if (window && window.ethereum) {
      let accounts = await ethereum.request({ method: "eth_requestAccounts" });
      getWalletAddress(accounts);
    }
    //  } &&
    //     window.ethereum.on("accountsChanged", (accounts) => {
    //       console.log('inside window')
    //       if (accounts.length > 0) {
    //         getWalletAddress();
    //       }
    //       // else {
    //       //   dispatchLogout({callFromMetaMask : true , disconnectMetaMask :(val)=> setIsMetaMaskConnected(val)});
    //       // }
    //     });
  };

  const forMyProfileSetAddress = (address) => {
    // setWalletForMyProfile(address);
    // console.log("address", address);
    saveMyWalletAddressInRedux(address);
  };
  return (
    <>
      <Modal
        className="AddWalletPopup"
        show={show}
        onHide={() => {
          setShowModal(false);
        }}
        dialogClassName="modal-90w"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <Container>
            <Row>
              <Col lg={12}>
                <div className="headingtext">
                  How would you like to access your assets?
                </div>
              </Col>
            </Row>
            <Row>
              <Col xs={12}>
                {/* <div className="wallet-div"> */}
                {/* <div className="wallet" onClick={() => connectWC()}>
                    <Wallet className="logo" />
                    <span className="wallet-name">WalletConnect </span>
                  </div> */}
                {/* </div> */}
                <div className="wallet-div">
                  <div
                    className="wallet"
                    onClick={() => {
                      everyAccountChangeHandle();
                    }}
                  >
                    <Metamask className="logo" />
                    <span className="wallet-name">Metamask </span>
                  </div>
                </div>
                {signInErrorFromBackEnd !== "" && (
                  <div className="error-text">{signInErrorFromBackEnd}</div>
                )}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
      <TemplatePopup
        show={showTemplate}
        setShowModal={setShowTemplate}
        message={message}
      />
    </>
  );
};

const mapStateToProps = ({ user = {} }) => {
  const loginLoading = _.get(user, "signInLoading", false);
  const signInErrorFromBackEnd = _.get(user, "signInError", "");
  return {
    loginLoading,
    signInErrorFromBackEnd,
  };
};
const mapDispatchToProps = (dispatch) => ({
  dispatchLogin: (data) =>
    dispatch({ type: "signInSagaCalled", payload: data }),
  dispatchLogout: (data) =>
    dispatch({ type: "logoutSagaCalled", payload: data }),
  saveMyWalletAddressInRedux: (data) => dispatch(saveMyWalletAddress(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(AddWalletPopup));
