import { createSlice } from '@reduxjs/toolkit'


const competetionSlice = createSlice({
    name: "Competetion",
    initialState: {
        getPublicCompetetionLoading: undefined,
        getPublicCompetetion: [],
        getPublicCompetitionPlus1: [],
        getPublicCompetetionError: undefined,
        amaElectionLoading: undefined,
        amaElections: [],
        amaElectionsError: undefined,
        electionGifLoading: undefined,
        electionGifError: undefined,

    },
    reducers: {
        publicCompetetionLoadingStart: (state, action) => {
            return {
                ...state,
                getPublicCompetetionLoading: true,
                getPublicCompetetion: [],
                getPublicCompetetionError: undefined,
            }
        },
        publicCompetetionSuccess: (state, action) => {
            return {
                ...state,
                getPublicCompetetionLoading: false,
                getPublicCompetetion: action.payload,
                getPublicCompetetionError: undefined,
            }
        },

        publicCompetetionSuccessPlus1: (state, action) => {
            return {
                ...state,
                getPublicCompetetionLoading: false,
                getPublicCompetitionPlus1: action.payload,
                getPublicCompetetionError: undefined,
            }
        },
        publicCompetetionFail: (state, action) => {
            return {
                ...state,
                getPublicCompetetionLoading: false,
                getPublicCompetetion: [],
                getPublicCompetetionError: action.payload,
            }
        },
        electionGifLoadingStart: (state, action) => {
            return {
                ...state,
                electionGifLoading: true,
                electionGifError: undefined,
            }
        },
        electionGifSuccess: (state, action) => {
            return {
                ...state,
                electionGifLoading: false,
                electionGifError: undefined,
            }
        },
        electionGifFailure: (state, action) => {
            return {
                ...state,
                electionGifLoading: false,
                electionGifError: action.payload
            }
        },
        publicCompetetionAmaLoadingStart: (state, action) => {
            return {
                ...state,
                amaElectionLoading: true,
                amaElections: [],
                amaElectionsError: undefined,
            }
        },
        publicCompetetionAmaSuccess: (state, action) => {
            return {
                ...state,
                amaElectionLoading: false,
                amaElections: action.payload,
                amaElectionsError: undefined,
            }
        },
        publicCompetetionAmaFail: (state, action) => {
            return {
                ...state,
                amaElectionLoading: false,
                amaElections: [],
                amaElectionsError: action.payload,
            }
        },
        clearCompetitionRedux: (state, action) => {
            return {}
        }
    }
})



export default competetionSlice.reducer;
export const {
    publicCompetetionLoadingStart,
    publicCompetetionSuccess,
    publicCompetetionSuccessPlus1,
    publicCompetetionFail,
    electionGifLoadingStart,
    electionGifSuccess,
    electionGifFailure,
    publicCompetetionAmaLoadingStart,
    publicCompetetionAmaSuccess,
    publicCompetetionAmaFail,
    clearCompetitionRedux,

} = competetionSlice.actions