import { call, put, takeLatest } from "redux-saga/effects";
import Api from "./../services/ApiCaller";
import { getAccessToken } from "src/utilities/utility";
import { CheckMinimalBalanceOfUser } from "./app_saga";
import {
  powerVoteOfuserLoadingStart,
  powerVoteOfUserSuccess,
  powerVoteOfUserFailure,
  showAllPlatformPowerVotesLoadingStart,
  showAllPlatformVotesSuccess,
  showAllPlatformVotesFailure,
  conversionFromUTELoadingStart,
  conversionFromUTESucess,
  conversionFromUTEFailure,
  conversionFromBNBToUsdLoadingStart,
  conversionFromBNBToUsdSuccss,
  conversionFromBNBToUsdFailure,
  conversionFromEthToUsdLoadingStart,
  conversionFromEthToUsdSucces,
  conversionFromEthToUsdFailure,
  buyPowerVotesUsingEthLoadingStart,
  buyPowerVoteEthSucess,
  buyPowerVotesUsingEthFailure,
  voteUsingPowerVoteLoadingStart,
  voteUsingPowerVoteSuccess,
  voteUsingPowerVoteFailure,
  buyVoteUsingTokenLoadingStart,
  buyVoteUsingTokenSuccess,
  buyPowerVoteUsingTokenFailure,
  donateProjectUsingEthLoadingStart,
  donateProjectUsingEthSuccess,
  donateProjectUsingEthFailure,
  donateProjectUsingTokenLoadingStart,
  donateProjectUsingTokenSucess,
  donateProjectUsingTokenFailure,
  donationTableLoadingStart,
  donationTableSuccess,
  donationTableFailure,
  donationTableUserLoadingStart,
  donationTableUserSuccess,
  donationTableUserFailure,
  donateTablePVLoadingStart,
  donateTablePVSuccess,
  donateTablePVFailure,
  donationOfUserLoaggedInLoadingStart,
  donationOfUserLoggedInSuccess,
  donationOfUserLoggedInFailure,
  powerVoteBTCLoadingStart,
  powerVoteBTCSuccess,
  powerVoteBTCFailure,
  rewardLoadingStart,
  rewardSuccess,
  rewardFailure,
  votingHistoryLoadingStart,
  votingHistorySuccess,
  votingHistoryFailure,
  donateBitcoingLoadingStart,
  donateBitcoinSuccess,
  donateBitcoinFailure,

  stripeCheckoutLoadingStart,
  stripeCheckoutSuccess,
  stripeCheckoutFailure,
} from "./powerVote_redux";

export function* StripeCheckoutSaga(api , {payload}){
  yield put(stripeCheckoutLoadingStart())
  try{
    const result = yield call(Api.callServer , api.StripeCheckout , payload , true)
    yield put(stripeCheckoutSuccess(result.result));
    window.location.replace(result.result)
  }catch(e){
    yield put(stripeCheckoutFailure(e.error))
  }
}

export function* VotingHistoryUserSaga(api, { payload }) {
  try {
    yield put(votingHistoryLoadingStart());
    let token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.VotingHistoryApplication,
      {
        dataForBackend: payload,
        token,
      },
      true
    );
    yield put(votingHistorySuccess(result.result));
  } catch (e) {
    yield put(votingHistoryFailure(e.error));
  }
}

export function* RewardSaga(api, { payload }) {
  yield put(rewardLoadingStart());
  try {
    const { loggedIn } = payload;
    delete payload["loggedIn"];
    if (loggedIn) {
      let token = yield getAccessToken();
      const result = yield call(
        Api.callServer,
        api.GetRewardForLoggedInUser,
        { dataForBackend: payload, token },

        true
      );
      yield put(rewardSuccess(result.result));
    } else {
      const result = yield call(
        Api.callServer,
        api.GetRewardWithAddress,
        payload,
        true
      );
      yield put(rewardSuccess(result.result));
    }
  } catch (e) {
    yield put(rewardFailure(e.error));
  }
}

export function* PowerVotesOwnedByUserSaga(api, { payload }) {
  yield put(powerVoteOfuserLoadingStart());
  let token = yield getAccessToken();
  try {
    const result = yield call(
      Api.callServer,
      api.AllPowerVotesOwnedByUser,
      { dataForBackend: payload !== undefined ? payload : "", token },
      true
    );
    // console.log("power votes owned by user", result.result);
    yield put(powerVoteOfUserSuccess(result.result));
  } catch (e) {
    yield put(powerVoteOfUserFailure(e.error));
  }
}
export function* ShowAllPlatformVotes(api, { payload }) {
  yield put(showAllPlatformPowerVotesLoadingStart());
  const token = yield getAccessToken();
  try {
    const result = yield call(
      Api.callServer,
      api.PowerVotesByPlatform,
      token,
      true
    );
    // console.log("platform all power votes", result);
    yield put(showAllPlatformVotesSuccess(result.result));
  } catch (e) {
    yield put(showAllPlatformVotesFailure(e.error));
  }
}

export function* ConversionFromUTESaga(api, { payload }) {
  yield put(conversionFromUTELoadingStart());
  try {
    const result = yield call(
      Api.callServer,
      api.getConvertedOfEthInWei,
      payload,
      true
    );
    // console.log("conversion", result);
    yield put(conversionFromUTESucess(result.result));
  } catch (e) {
    yield put(conversionFromUTEFailure(e.error));
  }
}
export function* ConversionFromETUSaga(api, { payload }) {
  yield put(conversionFromEthToUsdLoadingStart());
  try {
    const result = yield call(
      Api.callServer,
      api.getConvertedUsdFromEth,
      payload,
      true
    );
    // console.log("conversion", result);
    yield put(conversionFromEthToUsdSucces(result.result));
  } catch (e) {
    yield put(conversionFromEthToUsdFailure(e.error));
  }
}

export function* ConversionFromBTUSaga(api, { payload }) {
  yield put(conversionFromBNBToUsdLoadingStart());
  try {
    const result = yield call(
      Api.callServer,
      api.getConvertedUSDForBnb,
      payload,
      true
    );
    // console.log("conversion", result);
    yield put(conversionFromBNBToUsdSuccss(result.result));
  } catch (e) {
    yield put(conversionFromBNBToUsdFailure(e.error));
  }
}

export function* BuyPowerVoteUsingEth(api, { payload }) {
  const { openMinimalPopup } = payload;
  delete payload["openMinimalPopup"];
  // let result = yield call(CheckMinimalBalanceOfUser, api, {});
  // if (result) {
  yield put(buyPowerVotesUsingEthLoadingStart());
  let token = yield getAccessToken();
  const { openSucessPopup, openFail } = payload;
  delete payload["openSucessPopup"];
  delete payload["openFail"];
  try {
    const result = yield call(
      Api.callServer,
      api.BuyPowerVotes,
      {
        data: payload,
        token,
      },
      true
    );
    // console.log("result from power vote buy", result);
    yield put(buyPowerVoteEthSucess(result.result));

    yield call(PowerVotesOwnedByUserSaga, api, payload);
    if (result.result.buySuccessful === true) {
      openSucessPopup();
    } else {
      openFail();
    }
  } catch (e) {
    yield put(buyPowerVotesUsingEthFailure(e.error));
  }
}

export function* VoteUsingPowerVoteSaga(api, { payload }) {
  yield put(voteUsingPowerVoteLoadingStart());
  const { openSucess } = payload;
  try {
    const token = yield getAccessToken();
    const result = yield call(
      Api.callServer,
      api.VoteUsingPowerVote,
      {
        dataForBackend: payload,
        token,
      },
      true
    );
    // console.log("vote using power vote ", result);
    yield put(voteUsingPowerVoteSuccess(result));
    yield call(PowerVotesOwnedByUserSaga, api, payload), openSucess();
  } catch (e) {
    yield put(voteUsingPowerVoteFailure(e.error));
  }
}

export function* BuyPowerVoteUsingTokenSaga(api, { payload }) {
  yield put(buyVoteUsingTokenLoadingStart());
  const token = yield getAccessToken();
  // console.log("payload for erc20", payload);
  const { openSucessPopup, openFail } = payload;
  delete payload["openSucessPopup"];
  delete payload["openFail"];
  try {
    const result = yield call(
      Api.callServer,
      api.BuyVoteUsingToken,
      {
        dataForBackend: payload,
        token,
      },
      true
    );
    console.log("result for token buy vote", result.result);
    yield put(buyVoteUsingTokenSuccess(result.result));
    yield call(PowerVotesOwnedByUserSaga, api, payload);
    if (result.result.buySuccessful === true) {
      openSucessPopup();
    } else {
      openFail();
    }
  } catch (e) {
    yield put(buyPowerVoteUsingTokenFailure(e.error));
  }
}
export function* DonateProjectUsingEth(api, { payload }) {
  yield put(donateProjectUsingEthLoadingStart());
  let token = yield getAccessToken();
  let { openSuccess } = payload;
  delete payload["openSuccess"];
  try {
    const result = yield call(
      Api.callServer,
      api.DonateProjectApiUsingEth,
      {
        dataForBackend: payload,
        token,
      },
      true
    );
    // console.log("donate proejct using eth succes", result.result);
    yield put(donateProjectUsingEthSuccess(result.result));
    openSuccess();
  } catch (e) {
    yield put(donateProjectUsingEthFailure(e.error));
  }
}

export function* DonateProjectUsingToken(api, { payload }) {
  yield put(donateProjectUsingTokenLoadingStart());
  // console.log("called", payload);
  let token = yield getAccessToken();
  let { openSuccess } = payload;
  delete payload["openSuccess"];
  try {
    const result = yield call(
      Api.callServer,
      api.DonateProjectUsingToken,
      {
        dataForBackend: payload,
        token,
      },
      true
    );
    // console.log("donate proejct using eth succes", result.result);
    yield put(donateProjectUsingTokenSucess(result.result));
    openSuccess();
  } catch (e) {
    yield put(donateProjectUsingTokenFailure(e.error));
  }
}

export function* DonationTableSaga(api, { payload }) {
  // console.log("admin called donation");
  yield put(donationTableLoadingStart());
  let token = yield getAccessToken();
  try {
    const result = yield call(
      Api.callServer,
      api.GetDonationTableForAdmin,
      {
        dataForBackend: payload,
        token,
      },
      true
    );
    yield put(donationTableSuccess(result.result));
  } catch (e) {
    yield put(donationTableFailure(e.error));
  }
}

export function* DonationOfUserLoggedInSaga(api, { payload }) {
  yield put(donationOfUserLoaggedInLoadingStart());
  const { loggedIn } = payload;
  delete payload["loggedIn"];
  try {
    if (loggedIn === true) {
      let token = yield getAccessToken();
      const result = yield call(
        Api.callServer,
        api.DonationUserLoggedIn,
        {
          dataForBackend: payload,
          token,
        },
        true
      );
      yield put(donationOfUserLoggedInSuccess(result.result));
    } else {
      const result = yield call(
        Api.callServer,
        api.GetmyDonationWallet,
        payload,

        true
      );
      yield put(donationOfUserLoggedInSuccess(result.result));
    }
  } catch (e) {
    yield put(donationOfUserLoggedInFailure(e.error));
  }
}

export function* DonationTableUserSaga(api, { payload }) {
  // console.log("user called donatiin");
  yield put(donationTableUserLoadingStart());

  try {
    let token = yield getAccessToken();

    const result = yield call(
      Api.callServer,
      // api.GetDonationTableForCreator,
      api.GetDonationReceived,
      {
        dataForBackend: payload,
        token,
      },
      true
    );
    yield put(donationTableUserSuccess(result.result));
  } catch (e) {
    yield put(donationTableUserFailure(e.error));
  }
}

export function* DonationTablePowerVoteSaga(api, { payload }) {
  yield put(donateTablePVLoadingStart());
  let token = yield getAccessToken();
  try {
    const result = yield call(
      Api.callServer,
      api.GetPowerVoteTableAdmin,
      {
        dataForBackend: payload,
        token,
      },
      true
    );
    yield put(donateTablePVSuccess(result.result));
  } catch (e) {
    yield put(donateTablePVFailure(e.error));
  }
}

export function* PowerVoteBTCSagaCalled(api, { payload }) {
  yield put(powerVoteBTCLoadingStart());
  try {
    const { openPopup } = payload;
    delete payload["openPopup"];
    const token = yield getAccessToken();
    const result = yield call(Api.callServer, api.CreatePaymentForBitcoin, {
      dataForBackend: payload,
      token,
    });
    yield put(powerVoteBTCSuccess(result.result));
    openPopup();
  } catch (e) {
    yield put(powerVoteBTCFailure(e.error));
  }
}

export function* DonateBitcoinSaga(api, { payload }) {
  yield put(donateBitcoingLoadingStart());
  try {
    let result;
    const { loggedIn, openPopup } = payload;
    delete payload["loggedIn"];
    delete payload["openPopup"];
    if (loggedIn) {
      const token = yield getAccessToken();
      result = yield call(
        Api.callServer,
        api.DonateBitCoinForLoggedInUser,
        { dataForBackend: payload, token },
        true
      );
    } else {
      result = yield call(
        Api.callServer,
        api.DonateBitCoinForNotLoggedInUser,
        payload,
        true
      );
    }
    yield put(donateBitcoinSuccess(result.result));
    openPopup();
  } catch (e) {
    yield put(donateBitcoinFailure(e.error));
  }
}
