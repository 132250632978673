import React, { useState, useEffect } from "react";
import {
  Nav,
  Navbar,
  Modal,
  NavItem,
  NavLink,
  Dropdown,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { closeNavigateToForm } from "@store/user_redux";
import * as _ from "lodash";
import Web3 from "web3";
import { Turn as Hamburger } from "hamburger-react";
import { connect } from "react-redux";
import CustomButton from "src/components/custom-button/CustomButton";
import AddWalletPopup from "@components/add-wallet-popup/AddWalletPopup";
import FormPopup from "@components/form-popup/FormPopup";
import provider from "src/WalletProvider/walletProvider";
import SearchBox from "@components/search-box/SearchBox";
import SearchListItem from "@components/search-listitems/SearchListItem";
import SearchResult from "@components/search-result/SearchResult";
import BrandLogo from "@images/create_logo.png";
import { ReactComponent as Wallet } from "@images/create_wallet.svg";
import { ReactComponent as AdminWallet } from "@images/admin_wallet.svg";
import { ReactComponent as Notification } from "@images/notification-icon.svg";
import CreateLogo from "@images/create_logo.png";
import WalletLogo from "@images/create_wallet.svg";
import AdminWalletLogo from "@images/admin_wallet.svg";
import { useNavigate } from "react-router-dom";
import { ethers } from "ethers";
import debounce from "lodash.debounce";
import { result } from "lodash";
import WalletMenu from "@components/wallet-menu/WalletMenu";
import Drawer from "react-motion-drawer";
import "@components/navbar/NavBar.scss";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "@components/Spinner/Spinner";

function NavBar({
  userInformation,
  showUserEntryForm,
  closeFormPopup,
  dispatchLogout,
  pageName,
  role,
  getSearchData,
  searchResultLoading,
  searchedResultsData,
  searchResultError,
  screenType,
  getNotificationCount,
  notifications,
}) {
  const [show, setShow] = useState(false);
  const [walletAddress, setWalletAddress] = useState("");
  const [showWallet, setShowWallet] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [show1, setShow1] = useState(false);
  const navigate = useNavigate();
  const [isMetaMaskConnected, setIsMetaMaskConnected] = useState(false);
  const [query, setQuery] = useState("");
  const [results, setResults] = useState();
  let [limit, setlimit] = useState(10);
  let [skip, setskip] = useState(0);
  let [dataCount, setDataCount] = useState(0);
  const [open, setOpen] = useState(false);
  const onSwitch = () => {
    setOpen(!open);
  };

  const fetchSearchData = async (query, cb) => {
    const result = await getSearchData({
      skip,
      limit,
      searchPhase: query,
      callBack: (data) => cb(data),
    });
  };

  const debouncedSearch = debounce((query, cb) => {
    fetchSearchData(query, cb);
  }, 500);
  useEffect(() => {
    if (query !== "") {
      debouncedSearch(query, async (res) => {
        setDataCount(res.count);
        let finalResult = mergedArrayResultsForSearch(res);

        setResults(finalResult);
      });
    }
  }, [query]);

  const mergedArrayResultsForSearch = (data) => {
    // console.log("data inside filter", data);
    let mergedArray = [];
    let arrayOfProjects = data.projects;
    let arrayOfElections = data.elections;
    return (mergedArray = [...arrayOfProjects, ...arrayOfElections]);
  };
  const notify = () =>
    toast("Address copied successfully!", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: true,
      progress: undefined,
    });
  const ProfileData = [
    {
      title: "My Profile",
      url: "/myprofile",
    },
    {
      title: "My Power Votes",
      url: "/mypowervote",
    },
    {
      title: "My Projects",
      url: "/myprojects",
    },
    {
      title: "My Competitions",
      url: "/funding_competition",
    },
    {
      title: "My Voting History",
      url: "/myvotinghistory",
    },
    {
      title: "Donations Received",
      url: "/received-donation",
    },

    {
      title: "Log Out",
      logout: true,
    },
  ];

  const AdminProfileData = [
    {
      title: "My Profile",
      url: "/myprofile",
    },
    {
      title: "My Power Votes",
      url: "/mypowervote",
    },
    {
      title: "Manage Banner",
      url: "/admin/manage-banner",
    },
    {
      title: "Competitions",
      url: "/admin/competition",
    },
    // {
    //   title: "Rankings",
    //   url: "/admin/ranking",
    // },
    {
      title: "Balance setup",
      url: "/admin/balance-setup",
    },
    {
      title: "Projects",
      url: "/admin/projects",
    },
    {
      title: "Users",
      url: "/admin/users",
    },
    {
      title: "Donations",
      url: "/admin/donations",
    },
    {
      title: "Votes",
      url: "/admin/votes",
    },
    {
      title: "Reports",
      url: "/admin/reports",
    },
    {
      title: "Log Out",
      logout: true,
    },
  ];

  const MenuData = [
    {
      title: "Home",
      url: "/",
    },
    {
      title: "Competitions",
      url: "/competitions",
    },
    {
      title: "Project Rankings",
      url: "/live_ranking",
    },
    {
      title: "Creator Rankings",
      url: "/top-creators",
    },
    {
      title: "My Rewards",
      url: "/my-reward",
    },
    {
      title: "My Donations",
      url: "/mydonations",
    },
    {
      title: "Search",
      url: "/search-page",
    },
    {
      title: "Buy Crypto",
      url: "/buy-crypto",
    },
  ];
  const disconnect = async () => {
    // Close provider session

    if (localStorage.getItem("walletconnect") === null) {
      dispatchLogout({
        callFromMetaMask: true,
        disconnectMetaMask: (val) => setIsMetaMaskConnected(val),
      });
      navigate("/");
    } else {
      let pr = await provider();
      if (pr !== true) {
        await pr.disconnect();

        // await this.web3Modal.clearCachedProvider()

        dispatchLogout({
          callFromMetaMask: false,
          disconnectMetaMask: (val) => setIsMetaMaskConnected(val),
        });
        navigate("/");
      } else if (pr === true) {
      }
    }
  };

  const walletSlider = {
    overlayColor: "none",
    width: "410",
    maxWidth: "410",
    paddingLeft: "0",
  };

  useEffect(() => {
    if (userInformation !== undefined) {
      getNotificationCount();
    }
  }, []);

  return (
    <div className="NavBar">
      <Navbar expand="lg">
        <div className="left-nav">
          <Navbar.Brand onClick={() => navigate("/")}>
            <img src={BrandLogo} className="b-logo " />
          </Navbar.Brand>
          <div className="search-box hide-for-mobile">
            {/* {screenType === "onboarding" ? null : (
              <SearchBox
                value={query}
                // onChangeText={e => {
                //   setQuery(e.target.value);
                // }}
                loading={searchResultLoading}
                items={results}
                setvalues={(e) => setQuery(e)}
                noOfItems={dataCount}
                setItems={(e) => setResults(e)}
              />
            )} */}

            {/* { results !== undefined && results.length > 0 && results.map((result, index) => (
              <div key={index}>
                <SearchListItem
                  title={result.name}
                  // imageUrl={result.image_url}
                  // caption={result.tagline}
                />
              </div>
            ))} */}
          </div>
        </div>
        {screenType === "onboarding" ? null : (
          <Nav.Item className="p-name">
            <div className="nav-text">{pageName}</div>
            <hr />
          </Nav.Item>
        )}

        <div id="basic-navbar-nav" className="">
          <Nav className="ms-auto align-items-center ">
            <Nav.Item className="right-nav">
              {userInformation !== undefined &&
              userInformation?.emailVerified !== false ? (
                <>
                  {
                    <div
                      className="AccountAdress hide-for-mobile"
                      // onClick={() => {
                      //   // setcopied(userDetails !== undefined && userDetails?.walletAddress);
                      //   navigator.clipboard.writeText(
                      //     userInformation !== undefined &&
                      //       userInformation?.walletAddress !== null &&
                      //       userInformation.walletAddress
                      //   );
                      //   notify();
                      // }}
                    >
                      {userInformation !== undefined && userInformation.name}
                      {/* <ToastContainer
                      position="top-right"
                      autoClose={3000}
                      hideProgressBar={false}
                      newestOnTop={false}
                      closeOnClick
                      rtl={false}
                      pauseOnFocusLoss
                      draggable
                      pauseOnHover
                      theme="light"
                    /> */}
                    </div>
                  }
                  {/* button */}
                  {/* <button onClick={() => Send()}>Send</button> */}
                  {/* button */}
                  {
                    <div className="nav-logo wallet-section">
                      <div
                        className="ellipse"
                        onClick={() => navigate("/notifications")}
                      >
                        <Notification className="wallet-logo" />
                        {notifications !== undefined && notifications > 0 && (
                          <span className="notify-count">{notifications}</span>
                        )}
                      </div>
                    </div>
                  }

                  {userInformation !== undefined && (
                    <div
                      className="nav-logo"
                      onClick={() => {
                        setOpen(false);
                      }}
                    >
                      <Dropdown as={NavItem} alignRight={false} align={"end"}>
                        <Dropdown.Toggle
                          as={NavLink}
                          className="dropdown-toggle::after"
                        >
                          {userInformation.profilePictureImage !== null ? (
                            <div className="profile ellipse">
                              <div className="profile-circle">
                                <img
                                  src={`${process.env.REACT_APP_IMGLINK}${userInformation.profilePictureImage}`}
                                  className="profile-img"
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="profile ellipse">
                              <div className="profile-circle">
                                <img src={CreateLogo} className="profile-img" />
                              </div>
                            </div>
                          )}
                        </Dropdown.Toggle>
                        <div className="profilemenu">
                          {userInformation !== undefined &&
                          userInformation.role === "admin" ? (
                            <Dropdown.Menu className="dropdown-nav ul-class  ">
                              {AdminProfileData.map((data) => (
                                <li>
                                  <a href={data?.url} className="a-class">
                                    {data?.logout === true ? (
                                      <div
                                        className="dp-text"
                                        onClick={() => disconnect()}
                                      >
                                        <span>{data?.title}</span>
                                      </div>
                                    ) : (
                                      <div className="dp-text">
                                        <span>{data?.title}</span>
                                      </div>
                                    )}
                                  </a>
                                </li>
                              ))}
                            </Dropdown.Menu>
                          ) : (
                            <Dropdown.Menu className="dropdown-nav ul-class  ">
                              {ProfileData.map((data) => (
                                <li>
                                  <a href={data?.url} className="a-class">
                                    {data?.logout === true ? (
                                      <div
                                        className="dp-text"
                                        onClick={() => disconnect()}
                                      >
                                        <span>{data?.title}</span>
                                      </div>
                                    ) : (
                                      <div className="dp-text">
                                        <span>{data?.title}</span>
                                      </div>
                                    )}
                                  </a>
                                </li>
                              ))}
                            </Dropdown.Menu>
                          )}
                        </div>
                      </Dropdown>
                    </div>
                  )}
                </>
              ) : (
                <>
                  {screenType === "onboarding" ? null : (
                    <div className="nav-logo">
                      {/* <CustomButton
                        filled
                        name="Connect"
                        type="submit"
                        primary
                        classname={"walletcustombutton"}
                        onClick={() => {
                          setShowWallet(true);
                        }}
                      /> */}
                      <CustomButton
                        filled
                        name="Login"
                        type="submit"
                        primary
                        classname={"walletnewcustombutton"}
                        onClick={() => navigate("/signin")}
                      />
                    </div>
                  )}
                </>
              )}

              <Nav.Item
                className=""
                onClick={() => {
                  setOpen(false);
                }}
              >
                <Dropdown as={NavItem} alignRight={false} align={"end"}>
                  <Dropdown.Toggle
                    as={NavLink}
                    className="dropdown-toggle::after"
                  >
                    <Hamburger rounded direction="right" color="#261B1B" />
                  </Dropdown.Toggle>
                  <div className="profilemenu">
                    <Dropdown.Menu className="dropdown-nav ul-class  ">
                      {MenuData.map((data) => (
                        <li>
                          <a href={data?.url} className="a-class">
                            <div className="dp-text">
                              <span>{data?.title}</span>
                            </div>
                          </a>
                        </li>
                      ))}
                    </Dropdown.Menu>
                  </div>
                </Dropdown>
              </Nav.Item>
            </Nav.Item>
          </Nav>
        </div>
      </Navbar>
      <AddWalletPopup
        show={showWallet}
        setShowModal={(e) => setShowWallet(e)}
        setShowForm={(e) => setShowForm(e)}
        setIsMetaMaskConnect={(e) => setIsMetaMaskConnected(e)}
        metaMaskConnected={isMetaMaskConnected}
        userAddress={
          (userInformation !== undefined || userInformation !== null) &&
          userInformation?.walletAddress !== undefined &&
          userInformation.walletAddress
        }
      />
      <FormPopup
        show={showUserEntryForm}
        setShowModal={() => closeFormPopup()}
      />
    </div>
  );
}

const mapStateToProps = ({ user = {}, notificationRedux }) => {
  const userInformation = _.get(user, "userInfo", undefined);
  const showUserEntryForm = _.get(user, "showFormForUser", false);
  const searchedResultsData = _.get(user, "searchResult", []);
  const searchResultLoading = _.get(user, "searchLoading", false);
  const searchResultError = _.get(user, "serachError", undefined);
  const notifications = _.get(notificationRedux, "notficationCount", undefined);

  return {
    userInformation,
    showUserEntryForm,
    searchResultLoading,
    searchedResultsData,
    searchResultError,
    notifications,
  };
};
const mapDispatchToProps = (dispatch) => ({
  closeFormPopup: () => dispatch(closeNavigateToForm()),
  dispatchLogout: (data) =>
    dispatch({ type: "logoutSagaCalled", payload: data }),
  getSearchData: (data) =>
    dispatch({ type: "getGlobalSearchSagaCalled", payload: data }),
  getNotificationCount: () =>
    dispatch({ type: "getNotificationCountSagaCalled" }),
});
export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
