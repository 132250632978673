import { createSlice } from "@reduxjs/toolkit";

const creatorSlice = createSlice({
  name: "Creator",
  initialState: {
    creatorDetailsLoading: undefined,
    creatorDetails: [],
    creatorDetailsError: undefined,
    top50CreatorLoading: false,
    top50Creators: [],
    top50CreatorError: undefined,
  },

  reducers: {
    top50CreatorLoadingStart: (state, action) => {
      return {
        ...state,
        top50CreatorLoading: true,
        top50Creators: [],
        top50CreatorError: undefined,
      };
    },
    top50CreatorSuccess: (state, action) => {
      return {
        ...state,
        top50CreatorLoading: false,
        top50Creators: action.payload,
        top50CreatorError: undefined,
      };
    },
    top50CreatorFailure: (state, action) => {
      return {
        ...state,
        top50CreatorLoading: false,
        top50Creators: [],
        top50CreatorError: action.payload,
      };
    },
    creatorsDetailsLoadingStart: (state, action) => {
      return {
        ...state,
        creatorDetailsLoading: true,
        creatorDetails: [],
        creatorDetailsError: undefined,
      };
    },
    creatorDetailsSuccess: (state, action) => {
      return {
        ...state,
        creatorDetailsLoading: false,
        creatorDetails: action.payload,
        creatorDetailsError: undefined,
      };
    },
    creatorDetailsFailure: (state, action) => {
      return {
        ...state,
        creatorDetailsLoading: false,
        creatorDetails: [],
        creatorDetailsError: action.payload,
      };
    },
    clearCreatorRedux: (state, action) => {
      return {};
    },

    clearCreatorBackendError: (state, action) => {
      return {
        creatorDetailsError: undefined,
      };
    },
    votecreatorLoadingStart: (state, action) => {
      return {
        ...state,
        votecreatorLoading: true,
        votecreator: [],
        votecreatorError: undefined,
      };
    },
    votecreatorSuccess: (state, action) => {
      return {
        ...state,
        votecreatorLoading: false,
        votecreator: [],
        votecreatorError: undefined,
      };
    },
    votecreatorFailure: (state, action) => {
      return {
        ...state,
        votecreatorLoading: false,
        votecreator: [],
        votecreatorError: action.payload,
      };
    },
    clearCreatorRedux: (state, action) => {
      return {};
    },
    checkvoteLoading: (state, action) => {
      return {
        ...state,
        checkvotegivenLoading: action.payload,
      };
    },
    clearCreatorBackendError: (state, action) => {
      return {
        creatorDetailsError: undefined,
      };
    },
  },
});

export default creatorSlice.reducer;

export const {
  creatorsDetailsLoadingStart,
  creatorDetailsSuccess,
  creatorDetailsFailure,
  top50CreatorLoadingStart,
  top50CreatorSuccess,
  top50CreatorFailure,
  votecreatorFailure,
  votecreatorLoadingStart,
  votecreatorSuccess,
  checkvoteLoading,
} = creatorSlice.actions;
