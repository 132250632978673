import React, { Fragment, Profiler, useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import BigNumber from "bignumber.js";
import { ReactComponent as CloseIcon } from "@images/close2.svg";
import { ReactComponent as Copy } from "@images/copy.svg";
import { ReactComponent as BrandLogo } from "@images/create_logo.svg";
import { connect } from "react-redux";
import Spinner from "@components/Spinner/Spinner";
import "@components/wallet-menu/WalletMenuStyle.scss";

function WalletMenu({
  setShowWallett,
  userDetails,
  getWalletBalAsync,
  getUserWalletBalanceLoading,
  getUserWalletBal,
  getUserWalletError,
}) {
  const [copied, setcopied] = useState(false);
  const [myCreateBal, setMyCreateBal] = useState({});
  useEffect(() => {
    getWalletBalAsync();
  }, []);
  useEffect(() => {
    if (getUserWalletBal !== undefined) {
      setMyCreateBal(getUserWalletBal);
    }
  }, [getUserWalletBal]);
  return (
    <div className="walletmenu">
      <Fragment>
        <div className="wallet-box ">
          <CloseIcon
            className="close-icon"
            onClick={() => {
              setShowWallett(false);
            }}
          />

          <div className="wallet-heading">
            <span>Wallet</span>
          </div>
          <div className="user-detail">
            {userDetails !== undefined &&
            userDetails?.walletAddress !== undefined ? (
              <div className="user-id">
                <span>
                  {userDetails !== undefined &&
                    userDetails?.walletAddress &&
                    `${userDetails?.walletAddress?.substring(
                      0,
                      10
                    )}....${userDetails?.walletAddress?.substring(
                      userDetails?.walletAddress.length - 10,
                      userDetails?.walletAddress.length
                    )}`}
                </span>
                <Copy
                  onClick={() => {
                    setcopied(
                      userDetails !== undefined && userDetails?.walletAddress
                    );
                    navigator.clipboard.writeText(
                      userDetails !== undefined && userDetails?.walletAddress
                    );
                    toast("Address copied successfully!", {
                      position: "top-right",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeOnClick: true,
                      pauseOnHover: false,
                      draggable: true,
                      progress: undefined,
                    });
                  }}
                  className="copy"
                />
                <ToastContainer
                  position="top-right"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                />
              </div>
            ) : (
              <div className="user-id">
                <span>Not connected</span>
              </div>
            )}
          </div>
          <div className="wallet-details">
            <div className="inner-box">
              {getUserWalletBalanceLoading && <Spinner />}
              <div className="wallet-info">
                {myCreateBal !== undefined &&
                  myCreateBal.createBalance !== undefined && (
                    <span className="darktext">
                      {BigNumber(myCreateBal.createBalance).toFixed(
                        3,
                        BigNumber.ROUND_UP
                      )}{" "}
                      CROWDX
                    </span>
                  )}
                {myCreateBal !== undefined &&
                  myCreateBal.usdEquivalant !== undefined && (
                    <span className="lighttext">
                      $
                      {BigNumber(myCreateBal.usdEquivalant).toFixed(
                        3,
                        BigNumber.ROUND_UP
                      )}{" "}
                      USD
                    </span>
                  )}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    </div>
  );
}
const mapStateToProps = ({ app = {} }) => {
  const getUserWalletBalanceLoading = _.get(
    app,
    "getUserWalletBalLoading",
    false
  );
  const getUserWalletBal = _.get(app, "getUserBal", undefined);
  const getUserWalletError = _.get(app, "getUserBalError", undefined);
  return {
    getUserWalletBalanceLoading,
    getUserWalletBal,
    getUserWalletError,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getWalletBalAsync: () => dispatch({ type: "getUserWalletBalanceSagaCalled" }),
});

export default connect(mapStateToProps, mapDispatchToProps)(WalletMenu);
