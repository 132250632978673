import WalletConnectProvider from "@walletconnect/web3-provider";

//  Create WalletConnect Provider

// https://docs.walletconnect.com/quick-start/dapps/web3-provider
async function webConnection() {
  try {
    var provider = new WalletConnectProvider({
      rpc: {
        // 97: "https://data-seed-prebsc-1-s1.binance.org:8545/",
        0x5: "https://goerli.infura.io/v3/f07a27ee5029409493ef92be46060763",
      },
    });
    // console.log("provider", provider);
    await provider.enable();
    return provider;
  } catch (e) {
    // console.log(e);
    return true;
  }
}
export default webConnection;
