import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Dropdown,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import NavBar from "@components/navbar/NavBar";
import ReportPopup from "@components/report-popup/ReportPopup";
import VoteSuccessfulPopup from "@components/vote-successful-popup/VoteSuccessfulPopup";
import CreatorDetailPageCard from "@components/creator-detail-page-card/CreatorDetailPageCard";
import { ReactComponent as Back } from "@images/back.svg";
import { ReactComponent as VerticleIcon } from "@images/vertical-icon.svg";
import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";
import { connect } from "react-redux";
import * as _ from "lodash";
import Loader from "@components/loader/Loader";
import CustomButton from "@components/custom-button/CustomButton";
import ConfirmVotePopup from "@components/confirm-vote-popup/ConfirmVotePopup";
import "@components/creator-detail-page/CreatorDetailsPageStyle.scss";

const UserDetailsPage = ({
  getSingleUserDetLoading,
  singleUserError,
  checkvote,
  singleUserDetails,
  getSingleUserDetailAsync,
  userinfo,
}) => {
  const [showReportPopup, setShowReportPopup] = useState(false);
  const [disableBtn, setdisableBtn] = useState(true);
  const [showReportSuccess, setShowReportSuccess] = useState(false);
  const [creatorId, setcreatorId] = useState();
  const [profileImage, setProfileImage] = useState();
  const [name, setName] = useState();
  const [projectsNumber, setProjectsNumber] = useState();
  const [projects, setProjects] = useState([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [mailId, setMailId] = useState();
  const [userStatus, setUserStatus] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    getSingleUserDetailAsync({
      id,
    });
    checkvote({
      id,
      callback: (r) => {
        setdisableBtn(r.result);
      },
    });
  }, [id]);

  useEffect(() => {
    if (singleUserDetails !== undefined) {
      setProfileImage(singleUserDetails.profilePictureImage);
      setName(singleUserDetails.name);
      setMailId(singleUserDetails.email);
      setcreatorId(singleUserDetails.id);
      setProjectsNumber(
        singleUserDetails.projects !== undefined &&
          singleUserDetails.projects.length
      );
      setProjects(singleUserDetails.projects);
      setUserStatus(singleUserDetails.userPlatformStatus);
      // console.log('hello create')
    }
  }, [singleUserDetails]);

  return (
    <>
      <NavBar pageName={"Creator Details"} />
      <div className="CreatorDetailsPage">
        <Container>
          <ConfirmVotePopup
            show={showConfirm}
            creator
            setShowModal={(e) => setShowConfirm(e)}
            setShowSuccess={(e) => {
              setShowSuccess(e);
              setdisableBtn(true);
            }}
            detailsToShow={singleUserDetails !== undefined && singleUserDetails}
            setShowMinBal={(e) => setShowTemplate(e)}
          />
          <VoteSuccessfulPopup
            show={showSuccess}
            setShowModal={(e) => setShowSuccess(e)}
            message="Vote Successfully Recorded!"
          />
          {getSingleUserDetLoading && <Loader />}
          <div className="top-section">
            <Row>
              <Col lg={7}>
                <div className="creators-detail">
                  <div className="detailsection">
                    <div className="back">
                      <Back
                        className="back-icon"
                        onClick={() => {
                          navigate(-1);
                        }}
                      />
                    </div>
                    <div className="details">
                      <div className="left">
                        {profileImage !== undefined && (
                          <div className="profile-div">
                            <img
                              src={`${process.env.REACT_APP_IMGLINK}${profileImage}`}
                              className="profile-img"
                            />
                          </div>
                        )}
                      </div>
                      <div className="right">
                        {name !== undefined && (
                          <div className="creators-name">{name}</div>
                        )}
                        {projectsNumber !== undefined && (
                          <div className="light-text">
                            {projectsNumber} Projects
                          </div>
                        )}
                        {mailId !== undefined && (
                          <div className="light-text">{mailId}</div>
                        )}
                        {singleUserDetails !== undefined &&
                          singleUserDetails.twitterUserName !== undefined && (
                            <div
                              className="light-text twitter"
                              onClick={() =>
                                (window.location.href = `https://twitter.com/${singleUserDetails.twitterUserName}`)
                              }
                            >
                              <p>
                                {" "}
                                {`https://twitter.com/${singleUserDetails.twitterUserName}`}
                              </p>
                            </div>
                          )}

                        {
                          <div className="confirm row">
                            <div className="col-4 ">
                              <p className="votecount">
                                {singleUserDetails?.gainedVoteCount || "0"}{" "}
                                {singleUserDetails?.gainedVoteCount > 1
                                  ? "votes"
                                  : "vote"}
                              </p>
                            </div>
                            <div className="col-6">
                              {userinfo?.id != id && (
                                <CustomButton
                                  type="submit"
                                  name={disableBtn ? "Voted" : "Vote"}
                                  filled={true}
                                  primary={true}
                                  disable={disableBtn}
                                  onClick={() => {
                                    setShowConfirm(true);
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                  {userStatus !== undefined && userStatus === "activated" && (
                    <div className="report">
                      <OverlayTrigger
                        placement={window.innerWidth < 991 ? "left" : "right"}
                        overlay={
                          <Tooltip id="tooltip-btn">
                            {/* Download to get insights about the project */}
                            Click to report creator
                          </Tooltip>
                        }
                      >
                        <Dropdown>
                          <Dropdown.Toggle
                            className="threedot"
                            id="dropdown-basic"
                          >
                            <VerticleIcon className="vertical " />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="menu">
                            <Dropdown.Item
                              onClick={() => {
                                setShowReportPopup(true);
                              }}
                            >
                              Report
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </OverlayTrigger>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
          <div className="creator-cards">
            <CreatorDetailPageCard
              cardType={"Creator's Projects"}
              data={projects !== undefined && projects}
            />
          </div>
        </Container>
      </div>
      <ReportPopup
        show={showReportPopup}
        setShowModal={(e) => setShowReportPopup(e)}
        setShowSuccess={(e) => setShowReportSuccess(e)}
        passProjectId={creatorId !== undefined && creatorId}
        type={"User"}
      />
      <VoteSuccessfulPopup
        show={showReportSuccess}
        setShowModal={(e) => setShowReportSuccess(e)}
        message="Your report has been successfully submitted!"
      />
    </>
  );
};

const mapStateToProps = ({ user = {} }) => {
  const getSingleUserDetLoading = _.get(user, "singleUserDetailLoading", false);
  const singleUserDetails = _.get(user, "singleUserDetail", {});
  const singleUserError = _.get(user, "singleUserDetailError", undefined);
  const userinfo = _.get(user, "userInfo", undefined);

  return {
    getSingleUserDetLoading,
    singleUserError,
    singleUserDetails,
    userinfo,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getSingleUserDetailAsync: (data) =>
    dispatch({ type: "singleUserDetailFetchSagaCalled", payload: data }),

  checkvote: (data) => dispatch({ type: "checkvoteSagaCalled", payload: data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsPage);
