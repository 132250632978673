import React, { useEffect, useState } from "react";
import { Modal, Container, Row, Col, Form } from "react-bootstrap";
import CustomButton from "@components/custom-button/CustomButton";
import { ReactComponent as CloseIcon } from "@images/close-icon.svg";
import "@components/select-power-vote-popup/SelectPowerVotePopupStyle.scss";

const SelectPowerVotePopup = ({
  show,
  setShowModal,
  setShowConfirmPower
}) => {
  const [option, setOption] = useState("");
  const VoteData = [
    {
      detail: "11 Power Vote",
    },
  ];

  const handleRadioButton = (e) => {
    setOption(e.target.value);
  };
  return (
    <Modal
      className="SelectPowerVotePopup"
      show={show}
      onHide={() => {
        setShowModal(false);
      }}
      dialogClassName="modal-90w"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Body>
        <CloseIcon
          className="closebtn"
          onClick={() => {
            setShowModal(false);
          }}
        />
        <Container>
          <Row>
            <Col lg={12}>
              <div className="heading-text">Power Vote</div>
              <div className="detail-list">
                {VoteData.map((data) => (
                    <div className="vote-detail">
                      <div className="checkboxholder">
                        <Form.Check
                          type="radio"
                          id={`${data.id}`}
                          label={`${data.detail}`}
                          name="category"
                          value={data.id}
                          onClick={(e) => handleRadioButton(e)}
                        />
                      </div>
                    </div>
                  ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="btn-div">
              <div className="confirm">
                <CustomButton
                  type="submit"
                  name="Vote"
                  filled={true}
                  primary={true}
                  onClick={() => {
                    setShowConfirmPower(true);
                    setShowModal(false);
                  }}
                />
              </div>
              <div className="cancel">
                <CustomButton
                  classname="cancelbtn"
                  type="submit"
                  name="Cancel"
                  filled={true}
                  primary={true}
                  onClick={() => {
                    setShowModal(false);
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default React.memo(SelectPowerVotePopup);
